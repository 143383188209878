import { API, graphqlOperation } from "aws-amplify";
import { listPositions } from "../../graphql/queries";

export default async function getAllPositions() {
    const apiData = await API.graphql(graphqlOperation(listPositions, {
        filter: {
            verified: {
                eq: true
            }
        }
    }));

    return apiData.data.listPositions.items.sort((a, b) => b.createdAt - a.createdAt);
}