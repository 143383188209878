import React from "react";
import Videojs from "../video/video_player";
import { options } from '../video/options.js';
import { API } from "aws-amplify";
import { createLike, createSave, deleteLike, deleteSave } from "../../graphql/mutations";
import * as Icon from "react-bootstrap-icons";
import findDateDifference from "../misc/find_date_difference";
import hide_audio from "../settings/hide_audio";
require("datejs");

class VIDEO extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            likes: this.props.likes,
            liked: false,
            likeID: null,
            saves: this.props.saves,
            saved: false,
            saveID: null
        };
    }

    async componentDidMount() {
        let liked = this.state.likes.find(e => e.user === this.props.currentUser);
        let saved = this.state.saves.find(e => e.user === this.props.currentUser);
        if (liked) {
            this.setState({
                liked: true,
                likeID: liked.id
            });
        }

        if (saved) {
            this.setState({
                saved: true,
                saveID: saved.id
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.likes !== state.likes || props.saves !== state.saves) {
            return {
                likes: props.likes,
                saves: props.saves
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.likes !== this.props.likes || props.saves !== this.props.saves) {
            this.setState({
                likes: this.props.likes,
                saves: this.props.saves
            });
            this.componentDidMount();
        }
    }

    async addLike() {
        let param = {
            likePostId: this.props.id,
            user: this.props.currentUser
        }

        let src = this.props.src;

        let res = await API.graphql({ query: createLike, variables: { input: param } });
        this.setState({
            liked: true,
            likeID: res.data.createLike.id
        }, () => {
            res.data.createLike.post.src = src;
            res.data.createLike.post.linked = true;
            this.props.refreshPosts(res.data.createLike.post);
        });
    }

    async removeLike() {
        let param = {
            id: this.state.likeID
        }

        let src = this.props.src;

        let res = await API.graphql({ query: deleteLike, variables: { input: param } });
        this.setState({
            liked: false
        }, () => {
            res.data.deleteLike.post.src = src;
            res.data.deleteLike.post.linked = true;
            this.props.refreshPosts(res.data.deleteLike.post);
        });
    }

    viewComments() {
        let comments = document.getElementById(this.props.id);
        if(comments) {
            if(comments.style.maxHeight === "0px"){
                comments.style.maxHeight = "none";
            } else {
                comments.style.maxHeight = "0px"
                // document.getElementById(this.props.id + "_input").focus();
            }
        }
    }

    async addSave() {
        let param = {
            savePostId: this.props.id,
            user: this.props.currentUser
        }

        let src = this.props.src;

        let res = await API.graphql({ query: createSave, variables: { input: param } });
        this.setState({
            saved: true,
            saveID: res.data.createSave.id
        }, () => {
            res.data.createSave.post.src = src;
            res.data.createSave.post.linked = true;
            this.props.refreshPosts(res.data.createSave.post);
        });
    }

    async removeSave() {
        let param = {
            id: this.state.saveID
        }

        let src = this.props.src;

        let res = await API.graphql({ query: deleteSave, variables: { input: param } });
        this.setState({
            saved: false
        }, () => {
            res.data.deleteSave.post.src = src;
            res.data.deleteSave.post.linked = true;
            this.props.refreshPosts(res.data.deleteSave.post);
        });
    }

    render() {
        hide_audio();
        return (
            <div className="video-box">
                <Videojs {...options} sources={[{ src: this.props.src, type: 'video/mp4' }]} />
                <div className="description">
                    <div onClick={() => this.props.visitUser(this.props.user)} className="user">
                        {this.props.user}
                    </div>
                    &nbsp; • &nbsp;{findDateDifference(this.props.date)}
                </div>
                <div className="description">
                    {this.props.description}
                </div>
                <div className="interaction">
                    <div className="button" onClick={!this.state.liked ? () => this.addLike() : () => this.removeLike()}>
                        <div className="icon" style={{ color: this.state.liked ? "#cba42d" : "" }}><Icon.HandThumbsUp /></div> {this.props.likes.length}
                    </div>
                    <div className="button" onClick={!this.state.saved ? () => this.addSave() : () => this.removeSave()}>
                        <div className="icon" style={{ color: this.state.saved ? "#cba42d" : "" }}><Icon.Star /></div> {this.props.saves.length}
                    </div>
                    <div className="button" onClick={() => this.viewComments()}>
                        <div className="icon"><Icon.ChatLeftText /></div> {this.props.comments.length}
                    </div>
                </div>
            </div>
        );
    }
}

export default VIDEO;