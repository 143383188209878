import React from "react";
import ukicon from "../../img/uk-icon.png";
import rohingyaicon from "../../img/rohingyalish-icon.png";
import malaysiaicon from "../../img/malaysia-icon.png";
import burmaicon from "../../img/burma-icon.png";
import hanifiicon from "../../img/hanifi-icon.png";

class LANGUAGE_SELECT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: this.props.language
        }
    }

    selectLanguage(language) {
        localStorage.setItem("preferred_written_language", language);
        this.setState({
            language: language
        }, () => {
            this.accordion();
            this.props.function(this.state.language);
        });
    }

    accordion() {
        let acc = document.getElementById("language_dropdown");
        if(acc) {
            if(acc.style.maxHeight === "0px"){
                acc.style.maxHeight = acc.scrollHeight + "px";
                acc.style.borderTop = "1px solid #c4c4c4";
                acc.style.borderBottom = "1px solid #cba42d";
            } else {
                acc.style.maxHeight = "0px";
                acc.style.borderTop = "";
                acc.style.borderBottom = "";
            }
        }
    }

    render() {
        let icon = "";
        if(this.state.language === "English"){
            icon = ukicon;
        } else if(this.state.language === "Bahasa Malaysia") {
            icon = malaysiaicon;
        } else if(this.state.language === "Rohingyalish"){
            icon = rohingyaicon;
        } else if(this.state.language === "Burmese"){
            icon = burmaicon;
        } else if(this.state.language === "Hanifi"){
            icon = hanifiicon;
        }

        return (
            <div>
                <div className="language_select">
                    <div onClick={() => this.accordion()} className="placeholder">{this.state.language ? <div className="selected"><img alt={this.state.language} className="item-icon" src={icon} /> {this.state.language}</div> : this.props.placeholder}<div className="arrow_down"></div></div>
                    <div id="language_dropdown" style={{maxHeight: "0px"}} className="dropdown">
                        <div onClick={() => this.selectLanguage("English")} className="item"><img alt="English" className="item-icon" src={ukicon} /> English</div>
                        <div onClick={() => this.selectLanguage("Bahasa Malaysia")} className="item"><img alt="Bahasa Malaysia" className="item-icon" src={malaysiaicon} /> Bahasa Malaysia</div>
                        <div onClick={() => this.selectLanguage("Rohingyalish")} className="item"><img alt="Rohingyalish" className="item-icon" src={rohingyaicon} /> Rohingyalish</div>
                        <div onClick={() => this.selectLanguage("Burmese")} className="item"><img alt="Burmese" className="item-icon" src={burmaicon} /> Burmese</div>
                        <div onClick={() => this.selectLanguage("Hanifi")} className="item"><img alt="Hanifi" className="item-icon" src={hanifiicon} /> Hanifi</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LANGUAGE_SELECT;