import { API, graphqlOperation } from "aws-amplify";
import { deleteSkill as deleteSkillMutation } from "../../graphql/mutations";
import getUserEndorsements from "../get_skills/getUserEndorsements";
import deleteEndorsement from "./deleteEndorsement";

export default async function delete_skill(user, id) {
    let endorsements = await getUserEndorsements(user);
    endorsements = endorsements.filter(e => e.skill.id === id);
    for(let i = 0, size = endorsements.length; i < size; i++) {
        deleteEndorsement(endorsements[i].id);
    }

    await API.graphql(graphqlOperation(deleteSkillMutation, { input: { id } }));
}