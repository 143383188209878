import React from "react";
import * as Icon from "react-bootstrap-icons";
import LOADING from "../loading/loading";

class NO_WIFI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    render() {
        return (
            <div className="no_wifi_wrap">
                <LOADING show={this.state.loading} />
                <div className="encompass">
                    <div className="body_wrap">
                        <div className="body">
                            <div className="no_wifi">
                                <div className="no_wifi_heading">
                                    Check your internet connection
                                </div>
                                <div className="no_wifi_icon">
                                    <Icon.WifiOff />
                                </div>
                                <button className="primary" onClick={() => {
                                    this.setState({loading: true}, () => {
                                        this.props.refresh();
                                    });
                                    setTimeout(function() {
                                        this.setState({loading: false});
                                    }.bind(this), 1000);
                                }}>
                                    Reload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NO_WIFI;