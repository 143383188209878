import React from "react";
import dict from "../misc/translations";
import MODAL from "../modal/modal";
import toggle_modal from "../modal/toggle_modal";
import cookie from "../../img/cookie.jpg";
import * as Icon from "react-bootstrap-icons";
import uk from "../../img/uk-icon.png";
import malaysia from "../../img/malaysia-icon.png";
import rohingya from "../../img/rohingyalish-icon.png";
import burma from "../../img/burma-icon.png";

class COOKIE_POPUP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "English"
        };
    }

    render() {
        let language = this.state.language;

        return (
            <MODAL onModalClose={() => {
                localStorage.setItem("accept_cookies", false);
                toggle_modal("cookie_modal")
            }} id="cookie_modal" auto={localStorage.getItem("accept_cookies") !== null && localStorage.getItem("accept_cookies") !== undefined ? localStorage.getItem("accept_cookies") : true}>
                <div className="auth_languages">
                    <img alt="English" onClick={() => this.setState({ language: "English" })} style={{ border: this.state.language === "English" ? "2px solid #cba42d" : "" }} className="language_icon" src={uk} />
                    <img alt="Bahasa Malaysia" onClick={() => this.setState({ language: "Bahasa Malaysia" })} style={{ border: this.state.language === "Bahasa Malaysia" ? "2px solid #cba42d" : "" }} className="language_icon" src={malaysia} />
                    <img alt="Rohingyalish" onClick={() => this.setState({ language: "Rohingyalish" })} style={{ border: this.state.language === "Rohingyalish" ? "2px solid #cba42d" : "" }} className="language_icon" src={rohingya} />
                    <img alt="Burmese" onClick={() => this.setState({ language: "Burmese" })} style={{ border: this.state.language === "Burmese" ? "2px solid #cba42d" : "" }} className="language_icon" src={burma} />
                </div>
                <img style={{ width: "100px" }} src={cookie} alt="Cookie"></img>
                <div className="heading">
                    {dict["This website uses cookies"][language]}
                </div>
                <button onClick={() => {
                    localStorage.setItem("accept_cookies", false);
                    toggle_modal("cookie_modal")
                }} className="primary"><div className="button_icon"><Icon.Check2Circle /></div>{dict["Ok"][language]}</button>
                <br />
                <button onClick={() => toggle_modal("cookie_policy")} className="secondary"><div className="button_icon"><Icon.QuestionCircle /></div>{dict["Learn more"][language]}</button>
            </MODAL>
        );
    }
}

export default COOKIE_POPUP;