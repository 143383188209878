import React from "react";

class POSITION_TILE extends React.Component {
    render() {
        return (
            <div className="tile_wrap">
                <div className="tile position_tile" style={{ borderBottom: this.props.verified ? "10px solid green" : "10px solid #970000" }}>
                    <div className="tile_content">
                        <div className="title">
                            {this.props.title}
                        </div>
                        <div className="location">
                            {this.props.location}
                        </div>
                        <div className="description">
                            {this.props.description}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default POSITION_TILE;