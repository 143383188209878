import { API, graphqlOperation } from "aws-amplify";
import { listFollows } from "../../graphql/queries";

export default async function getOtherUserFollows(username) {
    const postData = await API.graphql(graphqlOperation(listFollows, {
        filter: {
            user: {
                eq: username
            }
        }
    }));

    return postData.data.listFollows.items;
}