import React from "react";
import { Analytics, Auth } from "aws-amplify";
import COUNTRY_CODES from "./country_codes";
import LOADING from "../loading/loading";
import LANGUAGE_SELECT from "../misc/language_select";
import * as Icon from "react-bootstrap-icons";
import dict from "../misc/translations";
import MODAL_BUTTON from "../modal/modal_button";

class REGISTER extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            username: "",
            country_code: "+60",
            number: "",
            password: "",
            confirm_password: "",
            loading: false,
            error: null,
            biography: "",
            favoured_language: "",
            accepted: false
        }
    }

    updateState(e, state) {
        let value = e.currentTarget.value.replace(/^0+/, '');
        this.setState({
            [state]: value
        });
    }

    getLanguage(language) {
        this.setState({
            favoured_language: language
        });
    }

    register = () => {
        let taken = ["videos", "login", "register", "verify", "forgot_password", "password_reset", "account", "settings", "saved", "notifications", "new_video", "upload_position", "help", "error", "lost", "admin", "administrator"];
        let username = this.state.username;
        let password = this.state.password;
        let phone_number = this.state.country_code + this.state.number;
        let name = this.state.name;
        let biography = this.state.biography;
        if(biography === "") {
            biography = " ";
        }

        if(this.state.name === "") {
            this.setState({
                error: "Please enter your name"
            });
        } else if (this.state.username === "") {
            this.setState({
                error: "Please enter a username"
            });
        } else if (taken.includes(this.state.username.toLowerCase())) {
            this.setState({
                error: "Sorry, you can't use this name"
            });
        } else if(this.state.username.indexOf(' ') >= 0) {
            this.setState({
                error: "Your username can't contain spaces"
            });
        } else if (this.state.number === "") {
            this.setState({
                error: "Please enter your phone number"
            });
        } else if (this.state.favoured_language === "") {
            this.setState({
                error: "Please select your favoured language"
            });
        } else if (this.state.password === "") {
            this.setState({
                error: "Please enter a password"
            });
        } else if (this.state.confirm_password === "") {
            this.setState({
                error: "Please confirm your password"
            });
        } else if (this.state.password !== this.state.confirm_password) {
            this.setState({
                error: "Passwords do not match"
            });
        } else {
            Auth.signUp({
                username,
                password,
                attributes: {
                    phone_number,
                    name,
                    "custom:bio": biography,
                    "custom:language_preference": this.props.language
                },
                validationData: [] //optional
            })
                .then(data => console.log(data))
                .then(() => {
                    Analytics.record({ name: "register", attributes: { result: "success" } });
                    this.props.verify();
                    this.props.username(username);
                    this.props.updateNumber(phone_number);
                    this.props.storePassword(password);
                }) // switches Sign Up to Verification
                .catch(err => {
                    Analytics.record({ name: "register", attributes: { result: "failed" } });
                    console.log(err);
                    this.setState({
                        loading: false,
                        error: err.message
                    });
                });
        }
    }

    onKeyUp(event) {
        if (event.key === "Enter") {
            this.register()
        }
    }

    goToNext(event, next) {
        if (event.key === "Enter") {
            document.getElementById(next).focus();
        }
    }

    render() {
        if (this.state.loading) {
            return <LOADING />;
        } else {
            return (
                <div className="login-form">
                    <input onKeyUp={(e) => this.goToNext(e, "username")} type="text" value={this.state.name} placeholder={dict["Name"][this.props.language]} onChange={(e) => this.updateState(e, "name")}></input><br />
                    <input onKeyUp={(e) => this.goToNext(e, "number")} id="username" type="text" value={this.state.username} placeholder={dict["Username"][this.props.language]} onChange={(e) => this.updateState(e, "username")}></input><br />
                    <div style={{marginBottom: "10px"}}>
                        <COUNTRY_CODES value={this.state.country_code} function={(e) => this.updateState(e, "country_code")} />
                        <input onKeyUp={(e) => this.goToNext(e, "bio")} id="number" className="phone_input" type="number" value={this.state.number} placeholder={dict["Phone number"][this.props.language]} onChange={(e) => this.updateState(e, "number")}></input><br />
                    </div>
                    <LANGUAGE_SELECT placeholder={dict["Preferred language"][this.props.language]} language={null} function={this.getLanguage.bind(this)} />
                    <textarea onKeyUp={(e) => this.goToNext(e, "password")} id="bio" value={this.state.biography} onChange={(e) => this.updateState(e, "biography")} placeholder={dict["About you"][this.props.language] + "..."}></textarea>
                    <div className="small_text no_margin">{dict["Minimum 8 characters"][this.props.language]}</div>
                    <input onKeyUp={(e) => this.goToNext(e, "confirm_password")} id="password" type="password" value={this.state.password} placeholder={dict["Password"][this.props.language]} onChange={(e) => this.updateState(e, "password")}></input><br />
                    <input onKeyUp={(e) => this.onKeyUp(e)} id="confirm_password" type="password" value={this.state.confirm_password} placeholder={dict["Confirm password"][this.props.language]} onChange={(e) => this.updateState(e, "confirm_password")}></input><br />
                    
                    <input checked={this.state.accepted} onChange={(e) => this.setState({accepted: !this.state.accepted})}type="checkbox" className="checkbox"></input><label>{dict["I agree to the"][this.props.language]} <div style={{display: "inline-block"}}><MODAL_BUTTON id="cookie_policy"><div className="small_text link">{dict["Cookie Policy"][this.props.language]}</div></MODAL_BUTTON></div>
                        , <div style={{display: "inline-block"}}><MODAL_BUTTON id="user_agreement"><div className="small_text link">{dict["User Agreement"][this.props.language]}</div></MODAL_BUTTON></div>
                        , {dict["and"][this.props.language]} <div style={{display: "inline-block"}}><MODAL_BUTTON id="privacy_policy"><div className="small_text link">{dict["Privacy Policy"][this.props.language]}</div></MODAL_BUTTON></div>
                    </label><br/>
                    
                    <div className="error">{this.state.error}</div>
                    <button disabled={!this.state.accepted} className="primary" onClick={this.register}><div className="button_icon"><Icon.PersonPlus /></div>{dict["Register"][this.props.language]}</button><br />

                    <div className="small_text">{dict["Already have an account?"][this.props.language]}&nbsp;</div>
                    <div onClick={this.props.login} className="small_text link">{dict["Login"][this.props.language]}</div>
                </div>
            );
        }
    }
}

export default REGISTER;