import { Analytics, Storage } from "aws-amplify";
import React from "react";
import findDateDifference from "../misc/find_date_difference";
import POST from "./post";
import * as Icon from "react-bootstrap-icons";
import dict from "../misc/translations";
import LOADING from "../loading/loading";

class NEWS_FEED extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            data: null,
            filter: "New",
            following: this.props.following,
            following_arr: [],
            posts: []
        };
    }

    async componentDidMount() {
        let following = this.state.following;
        let arr = [];
        if (following) {
            for (let i = 0, size = following.length; i < size; i++) {
                arr.push(following[i].follows);
            }
        }

        this.setState({
            data: this.props.posts,
            following: following,
            following_arr: arr
        }, () => {
            this.generatePosts();
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.posts !== state.data
            || props.following !== state.following
            || props.audio !== state.following) {
            return {
                data: props.posts,
                following: props.following,
                audio: props.audio
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.posts !== this.props.posts
            || props.following !== this.props.following
            || props.audio !== this.props.audio) {
            this.setState({
                data: this.props.posts,
                following: this.props.following,
                audio: this.state.audio
            }, () => {
                this.componentDidMount();
            });
        }
    }

    async generatePosts() {
        if (this.state.audio !== null) {
            let data = [...this.state.data];
            if (this.state.filter === "Followed") {
                data = data.filter(e => {
                    return this.state.following_arr.includes(e.user)
                });
            } else if (this.state.filter === "Popular") {
                data = data.sort((a, b) => {
                    let a_likes = a.likes?.items;
                    a_likes = a_likes.filter(e => {
                        let dif = findDateDifference(e.updatedAt);
                        let last = dif[dif.length - 1];
                        return last === "s" || last === "m" || last === "h" || last === "d";
                    });

                    let b_likes = b.likes?.items;
                    b_likes = b_likes.filter(e => {
                        let dif = findDateDifference(e.updatedAt);
                        let last = dif[dif.length - 1];
                        return last === "s" || last === "m" || last === "h" || last === "d";
                    });

                    return b_likes?.length - a_likes?.length;
                })
            }

            let post_arr = [];

            for (let i = 0, size = data.length; i < size; i++) {
                let n = data[i];
                if (n.published) {
                    let language = this.props.language;
                    if (language === "Bahasa Malaysia") {
                        language = "Malay";
                    }
                    language = language.toLowerCase();
                    let d = "";

                    if (n[language] !== null) {
                        d = n[language];
                    } else if (n.english !== null) {
                        d = n.english;
                    } else if (n.rohingyalish !== null) {
                        d = n.rohingyalish;
                    } else if (n.malay !== null) {
                        d = n.malay;
                    } else if (n.burmese !== null) {
                        d = n.burmese;
                    }

                    let src = n.src;
                    if (!n.linked) {
                        src = await Storage.get(n.src);
                    }

                    let pos = <POST
                        audio={this.state.audio}
                        language={this.props.language}
                        saves={n.saves}
                        comments={n.comments}
                        currentUser={this.props.currentUser}
                        visitUser={this.props.visitUser}
                        visitPost={this.props.visitPost}
                        refreshPosts={this.props.refreshPosts}
                        post={n}
                        likes={n.likes.items}
                        id={n.id}
                        date={n.createdAt}
                        user={n.user}
                        src={src}
                        file={n.src}
                        description={d} />;

                    post_arr.push(pos);
                }
            }

            this.setState({
                posts: post_arr
            });
        }
    }

    changeFilter(filter) {
        Analytics.record({ name: "change_filter", attributes: { filter: filter } });
        this.setState({
            filter: filter,
            posts: []
        }, () => {
            this.generatePosts();
        });
    }

    render() {
        if (this.state.audio !== null) {
            return (
                <div>
                    <div className="filter_wrap">
                        <div className="feed_filter" onClick={() => this.changeFilter("New")} style={{ borderBottom: this.state.filter === "New" ? "5px solid #cba42d" : "" }}><div style={{ display: "inline-block", verticalAlign: "middle", marginRight: "5px" }}><Icon.ExclamationDiamond /></div>{dict["New"][this.props.language]}</div>
                        <div className="feed_filter" onClick={() => this.changeFilter("Followed")} style={{ borderBottom: this.state.filter === "Followed" ? "5px solid #cba42d" : "" }}><div style={{ display: "inline-block", verticalAlign: "middle", marginRight: "5px" }}><Icon.PersonCheck /></div>{dict["Following"][this.props.language]}</div>
                        <div className="feed_filter" onClick={() => this.changeFilter("Popular")} style={{ borderBottom: this.state.filter === "Popular" ? "5px solid #cba42d" : "" }}><div style={{ display: "inline-block", verticalAlign: "middle", marginRight: "5px" }}><Icon.HandThumbsUp /></div>{dict["Popular"][this.props.language]}</div>
                    </div>
                    {this.state.posts}
                </div>
            );
        } else {
            return <LOADING />;
        }
    }
}

export default NEWS_FEED;