import Storage from "@aws-amplify/storage";

export default async function user_audio() {
    return ({
        "follow": {
            "English": new Audio(await Storage.get("audio/user/English/follow.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "unfollow": {
            "English": new Audio(await Storage.get("audio/user/English/unfollow.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "skills": {
            "English": new Audio(await Storage.get("audio/user/English/skills.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "posts": {
            "English": new Audio(await Storage.get("audio/user/English/videos.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "no_skills": {
            "English": new Audio(await Storage.get("audio/user/English/noSkills.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },
    })
}