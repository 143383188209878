/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPosition = /* GraphQL */ `
  query GetPosition($id: ID!) {
    getPosition(id: $id) {
      id
      user
      title
      location
      phone_number
      src
      english
      rohingyalish
      malay
      verified
      reported
      reported_reason
      createdAt
      updatedAt
    }
  }
`;
export const listPositions = /* GraphQL */ `
  query ListPositions(
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        title
        location
        phone_number
        src
        english
        rohingyalish
        malay
        verified
        reported
        reported_reason
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      src
      user
      phone_number
      english
      rohingyalish
      malay
      published
      reports {
        items {
          id
          reason
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          text
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      saves {
        items {
          id
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        burmese
        published
        reports {
          nextToken
        }
        likes {
          nextToken
        }
        comments {
          nextToken
        }
        saves {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          nextToken
        }
        comments {
          nextToken
        }
        saves {
          nextToken
        }
        createdAt
        updatedAt
      }
      user
      createdAt
      updatedAt
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post {
          id
          src
          user
          phone_number
          english
          rohingyalish
          malay
          published
          createdAt
          updatedAt
        }
        user
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          nextToken
        }
        comments {
          nextToken
        }
        saves {
          nextToken
        }
        createdAt
        updatedAt
      }
      text
      user
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post {
          id
          src
          user
          phone_number
          english
          rohingyalish
          malay
          published
          createdAt
          updatedAt
        }
        text
        user
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($id: ID!) {
    getReport(id: $id) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          nextToken
        }
        comments {
          nextToken
        }
        saves {
          nextToken
        }
        createdAt
        updatedAt
      }
      reason
      user
      createdAt
      updatedAt
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post {
          id
          src
          user
          phone_number
          english
          rohingyalish
          malay
          published
          createdAt
          updatedAt
        }
        reason
        user
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSave = /* GraphQL */ `
  query GetSave($id: ID!) {
    getSave(id: $id) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          nextToken
        }
        comments {
          nextToken
        }
        saves {
          nextToken
        }
        createdAt
        updatedAt
      }
      user
      createdAt
      updatedAt
    }
  }
`;
export const listSaves = /* GraphQL */ `
  query ListSaves(
    $filter: ModelSaveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaves(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post {
          id
          src
          user
          phone_number
          english
          rohingyalish
          malay
          published
          createdAt
          updatedAt
        }
        user
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFollow = /* GraphQL */ `
  query GetFollow($id: ID!) {
    getFollow(id: $id) {
      id
      user
      follows
      createdAt
      updatedAt
    }
  }
`;
export const listFollows = /* GraphQL */ `
  query ListFollows(
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        follows
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSkill = /* GraphQL */ `
  query GetSkill($id: ID!) {
    getSkill(id: $id) {
      id
      user
      skill
      endorsements {
        items {
          id
          user
          about
          review
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        skill
        endorsements {
          items {
            id
            user
            review
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEndorsement = /* GraphQL */ `
  query GetEndorsement($id: ID!) {
    getEndorsement(id: $id) {
      id
      skill {
        id
        user
        skill
        endorsements {
          nextToken
        }
        createdAt
        updatedAt
      }
      user
      about
      review
      createdAt
      updatedAt
    }
  }
`;
export const listEndorsements = /* GraphQL */ `
  query ListEndorsements(
    $filter: ModelEndorsementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEndorsements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        skill {
          id
          user
          skill
          createdAt
          updatedAt
        }
        user
        about
        review
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
