import { API, graphqlOperation } from "aws-amplify";
import { listSkills } from "../../graphql/queries";

export default async function getUserSkills(username) {
    const postData = await API.graphql(graphqlOperation(listSkills, {
        filter: {
            user: {
                eq: username
            }
        }
    }));

    return postData.data.listSkills.items.sort(function(a, b) {
        return (a.createdAt < b.createdAt) ? -1 : ((a.createdAt > b.createdAt) ? 1 : 0);
    });
}