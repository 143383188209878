import { Analytics, Auth } from "aws-amplify";
import React from "react";
import * as Icon from "react-bootstrap-icons";
import dict from "../misc/translations";

class CHANGE_PASSWORD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            new_password: "",
            loading: false,
            error: null
        };
    }

    change = () => {
        if(this.state.new_password !== ""){
        Auth.forgotPasswordSubmit(this.props.username, this.state.code, this.state.new_password)
            .then(() => {
                Analytics.record({name: "forgot_password", attributes: {result: "success"}});
                this.props.login();
            })
            .catch(err => {
                Analytics.record({name: "forgot_password", attributes: {result: "failed"}});
                
                this.setState({
                    error: "Code did not match"
                });
            });
        } else if (this.state.new_password.length < 8 && this.state.new_password.length >= 1) {
            this.setState({
                error: "Password must be longer than 8 characters"
            });
        } else {
            this.setState({
                error: "Please enter a new password"
            });
        }
    }

    updateState(e, state) {
        this.setState({
            [state]: e.currentTarget.value,
            error: null
        });
    }

    render() {
        return (
            <div className="login-form">
                {/* <LOADING show={this.state.loading}/> */}
                <input type="text" value={this.state.code} placeholder={dict["Code"][this.props.language]} onChange={(e) => this.updateState(e, "code")}></input><br />
                <input type="password" value={this.state.new_password} placeholder={dict["New password"][this.props.language]} onChange={(e) => this.updateState(e, "new_password")}></input><br />
                <div className="error">{this.state.error}</div>
                <button className="primary" onClick={this.change}><div className="button_icon"><Icon.Check2Square /></div>{dict["Username"][this.props.language]}</button>

                <hr />

                <button onClick={this.props.login} className="secondary"><div className="button_icon"><Icon.BoxArrowInRight /></div>{dict["Back to login"][this.props.language]}</button><br />
            </div>
        );
    }
}

export default CHANGE_PASSWORD;