import React from "react";
import MODAL from "../modal/modal";
import MODAL_BUTTON from "../modal/modal_button";
import Video from "../video/video";
import * as Icon from "react-bootstrap-icons";
import reportPost from "../../constants/posts/reportPost";
import tick from "../../img/icon-tick.svg";
import Comments from "./comments";
import dict from "../misc/translations";
import toggle_modal from "../modal/toggle_modal";
import deleteComment from "../../constants/delete_post/deleteComment";
import updateComment from "../../constants/posts/updateComment";
import reportComment from "../../constants/posts/reportComment";
import play_audio from "../misc/play_audio";
import hide_audio from "../settings/hide_audio";

class POST extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            other: false,
            reportReason: "",
            loading: false,
            newComment: ""
        };
    }

    async report() {
        this.setState({
            loading: true
        });
        let reason = this.state.options;
        if (this.state.other) {
            reason.push(this.state.reportReason);
        }

        await reportPost(this.props.id, this.props.currentUser, reason.toString());
        this.setState({
            loading: false
        }, () => {
            toggle_modal(this.props.id + "_report");
            toggle_modal("confirmation_modal_report");
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.likes !== state.likes
            || props.saves !== state.saves
            || props.comments !== state.comments) {
            return {
                saves: props.saves,
                likes: props.likes,
                comments: props.comments
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.likes !== this.props.likes
            || props.saves !== this.props.saves
            || props.comments !== this.props.comments) {
            this.render();
        }
    }

    visitUser() {
        this.props.visitUser(this.props.user);
    }

    visitPost() {
        this.props.visitUser("video/" + this.props.id);
    }

    updateOption(option) {
        let arr = this.state.options;
        if (arr.includes(option)) {
            arr.splice(arr.indexOf(option), 1);
        } else {
            arr.push(option);
        }

        this.setState({
            options: arr
        });
    }

    confirm() {
        toggle_modal("confirmation_modal_report");
    }

    copyLink() {
        toggle_modal(this.props.id + "_menu");
        toggle_modal(this.props.id + "_clipboard");
    }

    confirmLink() {
        toggle_modal(this.props.id + "_clipboard");
    }

    async deleteComment(id) {
        let res = await deleteComment(id);
        toggle_modal(id + "_comment_modal");
        this.endProcess(res.data.deleteComment.post);
    }

    async updateComment(id) {
        let res = await updateComment(id, this.state.newComment);
        toggle_modal(id + "_update_comment");
        this.endProcess(res.data.updateComment.post);
    }

    endProcess(res) {
        res.src = this.props.src;
        res.linked = true;
        res.comments.items.sort(function (a, b) {
            return (a.createdAt < b.createdAt) ? -1 : ((a.createdAt > b.createdAt) ? 1 : 0);
        });
        this.props.refreshPosts(res);
    }

    onKeyUp(event, id) {
        if (event.key === "Enter") {
            this.updateComment(id);
        }
    }

    render() {
        let spoken = localStorage.getItem("preferred_spoken_language") || "English";

        let reason = this.props.audio["reason"][spoken];
        let sexual_content = this.props.audio["sexual_content"][spoken];
        let violence = this.props.audio["violence"][spoken];
        let hate_speech = this.props.audio["hate_speech"][spoken];
        let self_injury = this.props.audio["self_injury"][spoken];
        let other = this.props.audio["other"][spoken];
        let thanks = this.props.audio["thanks"][spoken];

        hide_audio();

        return (
            <div className="post">
                <Video visitUser={this.props.visitUser} saves={this.props.saves.items} currentUser={this.props.currentUser} comments={this.props.comments.items} date={this.props.date} refreshPosts={this.props.refreshPosts} likes={this.props.likes} post={this.props.post} user={this.props.user} src={this.props.src} id={this.props.id} description={this.props.description} />

                <Comments visitUser={this.props.visitUser} src={this.props.src} language={this.props.language} refreshPosts={this.props.refreshPosts} currentUser={this.props.currentUser} id={this.props.id} comments={this.props.comments} />

                {this.props.comments?.items.map((comment, i) => {
                    return <div key={i}>
                        <MODAL id={comment.id + "_comment_modal"}>
                            {this.props.currentUser === comment.user ?
                                <div>
                                    <button onClick={() => {
                                        this.setState({ newComment: comment.text.replace(/\r?\n|\r/g, "") });
                                        toggle_modal(comment.id + "_comment_modal");
                                        toggle_modal(comment.id + "_update_comment");
                                    }} className="primary"><div className="button_icon"><Icon.ArrowRepeat /></div>{dict["Edit"][this.props.language]}</button>
                                    <br />
                                    <button onClick={() => this.deleteComment(comment.id)} className="secondary"><div className="button_icon"><Icon.XCircle /></div>{dict["Delete"][this.props.language]}</button>
                                </div>
                                :
                                <div>
                                    <button className="primary" onClick={() => this.visitUser(comment.user)}><div className="button_icon"><Icon.Person /></div>{dict["See user"][this.props.language]}</button>
                                    <br />
                                    <button onClick={() => {
                                        reportComment(comment.id);
                                        toggle_modal(comment.id + "_comment_modal");
                                        toggle_modal(comment.id + "_comment_report");
                                    }} className="secondary"><div className="button_icon"><Icon.ExclamationCircle /></div>{dict["Report"][this.props.language]}</button>
                                </div>
                            }
                        </MODAL>
                        <MODAL id={comment.id + "_update_comment"} onModalClose={() => {
                            this.setState({ newComment: "" });
                            toggle_modal(comment.id + "_update_comment");
                        }}>
                            <textarea onKeyUp={this.state.newComment !== "" || this.state.newComment === null ? (e) => this.onKeyUp(e, comment.id) : null} value={this.state.newComment} onChange={(e) => this.setState({ newComment: e.currentTarget.value.replace(/\r?\n|\r/g, "") })}></textarea><br />
                            <button disabled={this.state.newComment === "" || this.state.newComment === null} onClick={() => this.updateComment(comment.id)} className="primary"><div className="button_icon"><Icon.Check2All /></div>{dict["Save"][this.props.language]}</button>
                            <hr />
                            <button onClick={() => {
                                this.setState({ newComment: "" });
                                toggle_modal(comment.id + "_update_comment");
                            }} className="secondary"><div className="button_icon"><Icon.XCircle /></div>{dict["Cancel"][this.props.language]}</button>
                        </MODAL>
                        <MODAL id={comment.id + "_comment_report"}>
                            <div className="heading">{dict["Thank you"][this.props.language]}!</div>
                            <div className="audio" onClick={() => play_audio(thanks)}><Icon.VolumeUp /></div>
                            <div className="audio_br"></div>
                            <div className="small_text">{dict["One of our administrators will review your report"][this.props.language]}</div>
                            <br /><br />
                            <button className="primary" onClick={() => toggle_modal(comment.id + "_comment_report")}><div className="button_icon"><Icon.Check2Circle /></div>{dict["Ok"][this.props.language]}</button>
                        </MODAL>
                    </div>
                })}

                <div className="report_icon">
                    <MODAL_BUTTON id={this.props.id + "_report"}>
                        <Icon.ExclamationCircle />
                    </MODAL_BUTTON>
                </div>
                <MODAL id={this.props.id + "_report"}>
                    {dict["Why would you like to report this video"][this.props.language]}?
                    <br /><br />
                    <div className="audio" onClick={() => play_audio(reason)}><Icon.VolumeUp /></div>
                    <div className="audio_br"></div>
                    <div style={{width: JSON.parse(localStorage.getItem("hide_audio_icons")) ? "374px" : "304px", display: "inline-block", maxWidth: "100%"}}>
                        <div onClick={() => this.updateOption("Sexual content")} className="report_option audio_text">{dict["Sexual content"][this.props.language]}<img alt="Tick" src={tick} className="tick" style={{ display: this.state.options.includes("Sexual content") ? "inline-block" : "none" }} /></div><div style={{ transform: "scale(0.6)", marginLeft: "0px" }} className="audio" onClick={() => play_audio(sexual_content)}><Icon.VolumeUp /></div>
                        <br /><div onClick={() => this.updateOption("Violence")} className="report_option">{dict["Violence"][this.props.language]}<img alt="Tick" src={tick} className="tick" style={{ display: this.state.options.includes("Violence") ? "inline-block" : "none" }} /></div><div style={{ transform: "scale(0.6)", marginLeft: "0px" }} className="audio" onClick={() => play_audio(violence)}><Icon.VolumeUp /></div>
                        <br /><div onClick={() => this.updateOption("Hate speech")} className="report_option">{dict["Hate speech"][this.props.language]}<img alt="Tick" src={tick} className="tick" style={{ display: this.state.options.includes("Hate speech") ? "inline-block" : "none" }} /></div><div style={{ transform: "scale(0.6)", marginLeft: "0px" }} className="audio" onClick={() => play_audio(hate_speech)}><Icon.VolumeUp /></div>
                        <br /><div onClick={() => this.updateOption("Self injury")} className="report_option">{dict["Self injury"][this.props.language]}<img alt="Tick" src={tick} className="tick" style={{ display: this.state.options.includes("Self injury") ? "inline-block" : "none" }} /></div><div style={{ transform: "scale(0.6)", marginLeft: "0px" }} className="audio" onClick={() => play_audio(self_injury)}><Icon.VolumeUp /></div>
                        <br /><div onClick={() => { this.setState({ other: !this.state.other }); }} className="report_option">{dict["Other"][this.props.language]}<img alt="Tick" src={tick} className="tick" style={{ display: this.state.other ? "inline-block" : "none" }} /></div><div style={{ transform: "scale(0.6)", marginLeft: "0px" }} className="audio" onClick={() => play_audio(other)}><Icon.VolumeUp /></div>
                    </div>
                    <div className="extend" style={{ maxHeight: this.state.other ? "150px" : "0px" }}>
                        <textarea value={this.state.reportReason} onChange={(e) => { this.setState({ reportReason: e.currentTarget.value }); }} placeholder={dict["Other reasons"][this.props.language] + "..."}></textarea>
                    </div>
                    <button style={{ marginTop: "10px" }} disabled={this.state.options.length === 0 && this.state.reportReason === "" ? "disabled" : ""} className="secondary" onClick={() => this.report()}><div className="button_icon"><Icon.ExclamationCircle /></div>{dict["Report"][this.props.language]}</button>
                </MODAL>
                <div className="bubble_icon">
                    <MODAL_BUTTON id={this.props.id + "_menu"}>
                        <Icon.ThreeDotsVertical />
                    </MODAL_BUTTON>
                </div>
                <MODAL id={this.props.id + "_menu"}>
                    <button className="primary" onClick={() => this.visitUser()}><div className="button_icon"><Icon.Person /></div>{dict["See user"][this.props.language]}</button>
                    {/* <CopyToClipboard text={window.location.host + "/post/" + this.props.id}>
                        <button className="primary" onClick={() => this.copyLink()}><div className="button_icon"><Icon.Files /></div>{dict["Copy link"][this.props.language]}</button>
                    </CopyToClipboard>
                    <button onClick={() => openInNewTab("/post/" + this.props.id)} className="primary"><div className="button_icon"><Icon.FilePlus /></div>{dict["Open in new tab"][this.props.language]}</button> */}
                </MODAL>

                {/* <MODAL id={this.props.id + "_clipboard"}>
                    <div className="small_text">{dict["The link has been copied to your clipboard"][this.props.language]}.</div>
                    <button onClick={() => this.confirmLink()} className="primary"><div className="button_icon"><Icon.Check2Circle /></div>{dict["Ok"][this.props.language]}</button>
                </MODAL> */}

                <MODAL id="confirmation_modal_report">
                    <div className="modal_heading">{dict["Thank you"][this.props.language]}!</div>
                    <div className="audio" onClick={() => play_audio(thanks)}><Icon.VolumeUp /></div>
                    <div className="audio_br"></div>
                    <div className="small_text">{dict["One of our administrators will review your report"][this.props.language]}.</div>
                    <br /><br />
                    <button onClick={() => this.confirm()} className="primary"><div className="button_icon"><Icon.Check2Circle /></div>{dict["Ok"][this.props.language]}</button>
                </MODAL>
            </div>
        );
    }
}

export default POST;