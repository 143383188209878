/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:92b847c9-094f-42a7-84a6-bb4854183552",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_AsUyUDhHv",
    "aws_user_pools_web_client_id": "7dplebu9fhqd23f9d4tmb6g2sr",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://rz5br6qqhfgu7brgq7qoejkqru.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-dmllbefninfcppwnqpzhq72rmq",
    "aws_user_files_s3_bucket": "talent-edc9a25f0f6e6d4a19a5487a7f5790649faspire-aspire",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "d09cee67d2c24a799b4ced1dd93972de",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
