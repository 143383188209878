import React from "react";
import MODAL from "../modal/modal";
import * as Icon from "react-bootstrap-icons";
import play_audio from "../misc/play_audio";

class PRIVACY_POLICY extends React.Component {
    render() {
        let language = localStorage.getItem("preferred_spoken_language") || "English";
        let one = this.props.audio["privacy"]["title"][language];
        let two = this.props.audio["privacy"]["what"][language];
        let three = this.props.audio["privacy"]["where"][language];
        let four = this.props.audio["privacy"]["why"][language];
        let five = this.props.audio["privacy"]["how"][language];
        let six = this.props.audio["privacy"]["who"][language];
        let seven = this.props.audio["privacy"]["rights"][language];
        let eight = this.props.audio["privacy"]["laws"][language];
        let nine = this.props.audio["contact"][language];

        return (
            <MODAL id="privacy_policy">
                <div className="policy">
                    <div className="policy_heading">
                        Privacy Policy
                        <div className="audio" onClick={() => play_audio(one)}><Icon.VolumeUp /></div>
                    </div>
                    <div className="policy_content">
                        <div className="policy_sub_heading">
                            <div className="audio_text">Collected information</div>
                            <div className="audio" onClick={() => play_audio(two)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            User information collected:
                            <ul>
                                <li>Name</li>
                                <li>Username</li>
                                <li>Phone number</li>
                                <li>Preferred language</li>
                                <li>A biography of you</li>
                                <li>Your password</li>
                            </ul>
                            Your activity on the app is also stored. AWS Analytics gathers information about how users interact with the app. This data is only accessible by yourself and the TalentEd team.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Information source</div>
                            <div className="audio" onClick={() => play_audio(three)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            Information is collected from your own registration details. Information is also collected when you upload or interact with a video, when you upload a skill or when you endorse another users’ skill.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Reason</div>
                            <div className="audio" onClick={() => play_audio(four)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            The details about you are collected partially for your account page and information recall, but also so that the administration team can get in contact with you should they need to.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Storage</div>
                            <div className="audio" onClick={() => play_audio(five)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            The information gathered is stored on an AWS database, and also on the browser.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Sharing information</div>
                            <div className="audio" onClick={() => play_audio(six)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            The information gathered is not shared with anyone outwith the TalentEd team.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Rights</div>
                            <div className="audio" onClick={() => play_audio(seven)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            You may delete or alter your data at any point through the app.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Protection rights</div>
                            <div className="audio" onClick={() => play_audio(eight)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            We follow both GDPR and PDPA guidelines.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Contact</div>
                            <div className="audio" onClick={() => play_audio(nine)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            If you have any questions about the terms, please contact us at <a href="mailto:rmd5@hw.ac.uk">rmd5@hw.ac.uk</a>.
                        </div>
                    </div>
                </div>
            </MODAL>
        );
    }
}

export default PRIVACY_POLICY;