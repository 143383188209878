import config from '../config';
import getOtherUserPosts from '../posts/getOtherUserPosts';
import getOtherUserFollows from '../user/getOtherFollows';
import getOtherUserFollowers from '../user/getOtherFollowed';
import getUserEndorsements from '../get_skills/getUserEndorsements';
import getUserSkills from '../get_skills/getUserSkills';

export default async function getOtherUser(user, send) {
    let AWS = require('aws-sdk');

    var params = {
        UserPoolId: process.env.REACT_APP_USER_POOL,
        Filter: `username = "${user}"`,
        AttributesToGet: [
            "name",
            "phone_number",
            "custom:bio"
        ]
    };

    AWS.config.update(config);

    var cog = new AWS.CognitoIdentityServiceProvider();

    let posts = await getOtherUserPosts(user);
    // let positions = await getUserPositions(user);
    let follows = await getOtherUserFollows(user);
    let followers = await getOtherUserFollowers(user);
    let endorsements = await getUserEndorsements(user);
    let skills = await getUserSkills(user);

    cog.listUsers(params, function (err, data) {
        if (err) console.log(err); // an error occurred
        else {
            let res = {
                username: data.Users[0].Username,
                biography: data.Users[0].Attributes[0].Value,
                phone_number: data.Users[0].Attributes[2].Value,
                posts: posts,
                // positions: positions,
                follows: follows,
                followers: followers,
                endorsements: endorsements,
                skills: skills
            };

            send(res);
        }
    });
}