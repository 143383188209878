import React from "react";
import MODAL from "../../components/modal/modal";
import MODAL_BUTTON from "../../components/modal/modal_button";
import deleteUser from "../../constants/user/deleteUser";
import dict from "../misc/translations";
import * as Icon from "react-bootstrap-icons";
import play_audio from "../misc/play_audio";
import toggle_modal from "../modal/toggle_modal";

class DELETE_MODAL extends React.Component {
    closeModal(id) {
        toggle_modal(id);
    }

    delete() {
        this.closeModal(this.props.id);
        deleteUser(this.props.username);
    }

    updateVerify(e) {
        this.setState({
            verify: e.currentTarget.value
        });
    }

    render() {
        let audio = localStorage.getItem("preferred_spoken_language") || "English";
        let delete_account = this.props.audio["delete_account"][audio];

        return (
            <div>
                <MODAL_BUTTON id={this.props.id}><button className="primary full"><div className="button_icon"><Icon.PersonX /></div>{dict["Delete account"][this.props.language]}</button></MODAL_BUTTON>
                <MODAL id={this.props.id}>
                    <div className="modal_heading">
                        {dict["Delete account"][this.props.language]}?
                    </div>
                    <div style={{ marginLeft: "0px" }} className="audio" onClick={() => play_audio(delete_account)}><Icon.VolumeUp /></div>
                    <div className="audio_br"></div>
                    <div className="error">{dict['Warning: this cannot be undone'][this.props.language]}</div>
                    {/* <br /> */}
                    <button className="primary" onClick={() => this.delete()}><div className="button_icon"><Icon.PersonX /></div>{dict["Delete"][this.props.language]}</button>
                    <hr />
                    <button className="secondary" onClick={() => toggle_modal(this.props.id)}><div className="button_icon"><Icon.XCircle /></div>{dict["Cancel"][this.props.language]}</button>
                </MODAL>
            </div>
        );
    }
}

export default DELETE_MODAL;