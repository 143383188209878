import React from "react";
import * as Icon from 'react-bootstrap-icons';
import { options } from '../video/options.js';
import Videojs from "../video/video_player";
import dict from "../misc/translations.js";
import { createPost } from "../../graphql/mutations.js";
import { Analytics, API, Storage } from "aws-amplify";
import MODAL from "../modal/modal.js";
import LOADING from "../loading/loading.js";
import MODAL_BUTTON from "../modal/modal_button.js";
import toggle_modal from "../modal/toggle_modal.js";
import upload_audio from "./upload_audio.js";
import play_audio from "../misc/play_audio";

class UPLOAD_POST extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            warning: true,
            file: null,
            english: null,
            englishHold: "",
            malay: null,
            malayHold: "",
            rohingyalish: null,
            rohingyalishHold: "",
            burmese: null,
            burmeseHold: "",
            loading: false,
            error: null
        }
    }

    uploadVideo(e) {
        this.setState({
            file: e.currentTarget.files[0],
            error: null
        });
    }

    cancelPost = () => {
        this.props.cancel();
    }

    accordion(id, id2) {
        let acc = document.getElementById(id);
        let acc_btn = document.getElementById(id2 + "_acc");
        let icon = document.getElementById(id2 + "_acc_icon");
        if (acc && acc_btn) {
            if (acc.style.maxHeight === "0px") {
                acc.style.maxHeight = acc.scrollHeight + "px";
                if (id2 === "english_acc") {
                    acc_btn.innerHTML = "Remove English description";
                } else if (id2 === "rohingyalish_acc") {
                    acc_btn.innerHTML = "Remove Rohingyalish description";
                } else if (id2 === "malay_acc") {
                    acc_btn.innerHTML = "Remove Bahasa Malaysia description";
                }
                icon.classList.remove("fa-plus");
                icon.classList.add("fa-minus");

                this.setState({
                    [id2]: this.state[id2 + "Hold"],
                    error: null
                });
            } else {
                acc.style.maxHeight = "0px";
                if (id2 === "english_acc") {
                    acc_btn.innerHTML = "Add English description";
                } else if (id2 === "rohingyalish_acc") {
                    acc_btn.innerHTML = "Add Rohingyalish description";
                } else if (id2 === "malay_acc") {
                    acc_btn.innerHTML = "Add Bahasa Malaysia description";
                }
                icon.classList.remove("fa-minus");
                icon.classList.add("fa-plus");

                this.setState({
                    [id2]: null,
                    error: null
                });
            }
        }
    }

    updateState(e, state) {
        this.setState({
            [state]: e.currentTarget.value,
            [state + "Hold"]: e.currentTarget.value,
            error: null
        });
    }

    upload = async () => {
        if (this.state.file) {
            this.setState({
                loading: true
            });

            let param = {
                user: this.props.user,
                src: this.props.user + "_" + this.props.postData.length + "_" + this.state.file.name,
                phone_number: this.props.number,
                english: this.state.english,
                rohingyalish: this.state.rohingyalish,
                malay: this.state.malay,
                burmese: this.state.burmese,
                published: true
            };

            let res = await API.graphql({ query: createPost, variables: { input: param } });

            await Storage.put(this.props.user + "_" + this.props.postData.length + "_" + this.state.file.name, this.state.file);
            Analytics.record({ name: "upload_new_post" });
            this.setState({
                loading: false,
                // file: null
            }, () => {
                this.props.addPost(res.data.createPost);
                toggle_modal("confirmation_modal");
            });
        } else {
            this.setState({
                error: "Please add a video"
            });
        }
    }

    confirm() {
        this.props.confirm();
    }

    render() {
        let spoken = localStorage.getItem("preferred_spoken_language");

        let remember = upload_audio["remember"][spoken];
        let face = upload_audio["face"][spoken];
        let location = upload_audio["location"][spoken];
        let upload = upload_audio["upload"][spoken];
        let english = upload_audio["english"][spoken];
        let rohingyalish = upload_audio["rohingyalish"][spoken];
        let malay = upload_audio["malay"][spoken];
        let burmese = upload_audio["burmese"][spoken];
        let thanks = upload_audio["thanks"][spoken];

        let video = <div>
            <label htmlFor="file-upload" className="file_upload">
                <div className="upload_icon"><Icon.Upload /></div> <div className="upload_content" >{dict["Upload video"][this.props.language]}</div>
                <div className="audio" onClick={(e) => {
                    e.preventDefault();
                    play_audio(upload);
                }}><Icon.VolumeUp /></div>
            </label>
            <input onChange={(e) => this.uploadVideo(e)} className="hide_html_upload" id="file-upload" type="file" accept=".mp4,video/*" />
        </div>;
        if (this.state.file) {
            video = <div>
                <Videojs {...options} sources={[{ src: URL.createObjectURL(this.state.file), type: 'video/mp4' }]} />
            </div>;
        }

        let auto = localStorage.getItem("show_warning");
        if (auto === undefined || auto === null) {
            auto = true;
        }

        return (
            <div className="upload">
                <LOADING show={this.state.loading} message="Please wait..." />

                <MODAL id="hide_face_modal" auto={auto}>
                    <div className="heading">
                        {dict["Remember to stay safe"][this.props.language]}
                        <div className="audio" onClick={() => play_audio(remember)}><Icon.VolumeUp /></div>
                    </div>

                    <div style={{ display: "inline-block", verticalAlign: "middle", width: "175px" }}>
                        <div className="circle_wrap">
                            <div className="circle"><Icon.Circle /></div>
                            <div className="face"><Icon.EmojiSmile /></div>
                            <div className="line_through"></div>
                        </div>
                        {dict["Don't show your face"][this.props.language]}
                    </div>
                    <div className="audio" onClick={() => play_audio(face)}><Icon.VolumeUp /></div>

                    <br /><br /><br />
                    <div style={{ display: "inline-block", verticalAlign: "middle", width: "175px" }}>
                        <div className="circle_wrap">
                            <div className="circle"><Icon.Circle /></div>
                            <div className="geo"><Icon.GeoAlt /></div>
                            <div className="line_through"></div>
                        </div>
                        {dict["Don't show your location"][this.props.language]}
                    </div>
                    <div className="audio" onClick={() => play_audio(location)}><Icon.VolumeUp /></div>

                    <br /><br /><br />
                    <input onChange={() => this.setState({ warning: !this.state.warning })} className="checkbox" type="checkbox"></input>
                    <label>{dict["Don't show this again"][this.props.language]}</label>
                    <MODAL_BUTTON id="hide_face_modal"><button onClick={!this.state.warning ? () => localStorage.setItem("show_warning", this.state.warning) : null} className="primary"><div className="button_icon"><Icon.Check2Circle /></div>{dict["Ok"][this.props.language]}</button></MODAL_BUTTON>
                </MODAL>

                <div className="file_upload_wrap">
                    {video}
                </div>

                <div style={{ display: "inline-block", verticalAlign: "middle" }} onClick={() => this.accordion("english_dropdown", "english")} className="description_acc">
                    <i id="english_acc_icon" className="fas fa-plus"></i> <span id="english_acc">{dict["Add English description"][this.props.language]}</span>
                </div>
                <div style={{ transform: "scale(0.6)", color: "#5a5a5a", borderColor: "#5a5a5a", marginLeft: "0px" }} className="audio" onClick={() => play_audio(english)}><Icon.VolumeUp /></div>
                <div style={{ maxHeight: "0px" }} id="english_dropdown" className="extend">
                    <textarea onChange={(e) => this.updateState(e, "english")} value={this.state.englishHold} placeholder={dict["English description"][this.props.language] + "..."}></textarea>
                </div>

                <div style={{ display: "inline-block", verticalAlign: "middle" }} onClick={() => this.accordion("rohingyalish_dropdown", "rohingyalish")} className="description_acc">
                    <i id="rohingyalish_acc_icon" className="fas fa-plus"></i> <span id="rohingyalish_acc">{dict["Add Rohingyalish description"][this.props.language]}</span>
                </div>
                <div style={{ transform: "scale(0.6)", color: "#5a5a5a", borderColor: "#5a5a5a", marginLeft: "0px" }} className="audio" onClick={() => play_audio(rohingyalish)}><Icon.VolumeUp /></div>
                <div style={{ maxHeight: "0px" }} id="rohingyalish_dropdown" className="extend">
                    <textarea onChange={(e) => this.updateState(e, "rohingyalish")} value={this.state.rohingyalishHold} placeholder={dict["Rohingyalish description"][this.props.language] + "..."}></textarea>
                </div>

                <div style={{ display: "inline-block", verticalAlign: "middle" }} onClick={() => this.accordion("malay_dropdown", "malay")} className="description_acc">
                    <i id="malay_acc_icon" className="fas fa-plus"></i> <span id="malay_acc">{dict["Add Bahasa Malaysia description"][this.props.language]}</span>
                </div>
                <div style={{ transform: "scale(0.6)", color: "#5a5a5a", borderColor: "#5a5a5a", marginLeft: "0px" }} className="audio" onClick={() => play_audio(malay)}><Icon.VolumeUp /></div>
                <div style={{ maxHeight: "0px" }} id="malay_dropdown" className="extend">
                    <textarea onChange={(e) => this.updateState(e, "malay")} value={this.state.malayHold} placeholder={dict["Bahasa Malaysia description"][this.props.language] + "..."}></textarea>
                </div>

                <div style={{ display: "inline-block", verticalAlign: "middle" }} onClick={() => this.accordion("burmese_dropdown", "burmese")} className="description_acc">
                    <i id="burmese_acc_icon" className="fas fa-plus"></i> <span id="burmese_acc">{dict["Add Burmese description"][this.props.language]}</span>
                </div>
                <div style={{ transform: "scale(0.6)", color: "#5a5a5a", borderColor: "#5a5a5a", marginLeft: "0px" }} className="audio" onClick={() => play_audio(burmese)}><Icon.VolumeUp /></div>
                <div style={{ maxHeight: "0px" }} id="burmese_dropdown" className="extend">
                    <textarea onChange={(e) => this.updateState(e, "burmese")} value={this.state.burmeseHold} placeholder={dict["Burmese description"][this.props.language] + "..."}></textarea>
                </div>
                {this.state.error ? <div className="error">{this.state.error}</div> : null}
                <button className="primary full" onClick={this.upload}><div className="button_icon"><Icon.Upload /></div>{dict["Upload video"][this.props.language]}</button>
                <button className="secondary full" onClick={this.cancelPost}><div className="button_icon"><Icon.XCircle /></div>{dict["Cancel"][this.props.language]}</button>

                <MODAL id="confirmation_modal" onModalClose={() => this.confirm()}>
                    <div className="modal_heading">{dict["Thank you"][this.props.language]}!</div>
                    <div className="audio" onClick={() => play_audio(thanks)}><Icon.VolumeUp /></div>
                    <div className="audio_br"></div>
                    <div className="small_text">{dict["You can now check out your video on your news feed or in your account page"][this.props.language]}.</div>
                    <br /><br />
                    <button onClick={() => this.confirm()} className="primary"><div className="button_icon"><Icon.Check2Circle /></div>{dict["Ok"][this.props.language]}</button>
                </MODAL>
            </div>
        );
    }
}

export default UPLOAD_POST;