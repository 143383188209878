import React from "react";
import hide_audio from "../settings/hide_audio";
import * as Icon from "react-bootstrap-icons";
import HELP_SECTION from "./help_section";
import TE3 from "../../img/TE3.svg";
import LOADING from "../loading/loading";

class HELP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: null,
            audio: this.props.audio
        };
    }

    async componentDidMount() {
        let id = localStorage.getItem("navigate_to_help");
        if (id) {
            document.getElementById(id).scrollIntoView();
            document.getElementById("body").scrollTop -= 70;
            localStorage.removeItem("navigate_to_help");
        }

        console.log(this.state.videos);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.videos !== state.videos
            || props.audio !== state.audio) {
            return {
                videos: props.videos,
                audio: props.audio
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.videos !== this.props.videos
            || props.audio !== this.props.audio) {
            this.setState({
                videos: this.props.videos,
                audio: this.props.audio
            });
        }
    }

    render() {
        if (this.state.videos !== null && this.state.audio !== null) {
            let language = this.props.language;
            let spoken = localStorage.getItem("preferred_spoken_language") || "English";

            let news_feed = this.state.videos["newsFeed"][spoken];
            let saved = this.state.videos["saved"][spoken];
            let notifications = this.state.videos["notifications"][spoken];
            let account = this.state.videos["account"][spoken];
            let settings = this.state.videos["settings"][spoken];
            let upload = this.state.videos["upload"][spoken];
            let search = this.state.videos["search"][spoken];
            let navigation = this.state.videos["navigation"][spoken];
            let help = this.state.videos["help"][spoken];

            let news_feed_audio = this.state.audio["news_feed"][spoken];
            let account_audio = this.state.audio["account"][spoken];
            let saved_audio = this.state.audio["saved"][spoken];
            let notifications_audio = this.state.audio["notifications"][spoken];
            let help_sound = this.state.audio["help"][spoken];
            let settings_audio = this.state.audio["settings"][spoken];
            let upload_audio = this.state.audio["upload_post"][spoken];
            let search_audio = this.state.audio["search"][spoken];
            let navigation_audio = this.state.audio["navigation"][spoken];

            hide_audio();
            return (
                <div className="help">
                    <HELP_SECTION icon={<img className="te_icon" src={TE3} alt="TalentEd" />} language={language} title="Videos" audio={news_feed_audio} video={news_feed} description="Like, save and comment on a video. Report videos." />

                    <hr />

                    <HELP_SECTION icon={<Icon.Star />} language={language} title="Saved" audio={saved_audio} video={saved} description="Save videos and access saved videos." />

                    <hr />

                    <HELP_SECTION icon={<Icon.Bell />} language={language} title="Notifications" audio={notifications_audio} video={notifications} description="What notifications show and how to access associated videos." />

                    <hr />

                    <HELP_SECTION icon={<Icon.Person />} language={language} title="Account" audio={account_audio} video={account} description="View and alter your details, add new skills, delete skills and view and report endorsements. Publish, unpublish, edit and delete videos." />

                    <hr />

                    <HELP_SECTION icon={<Icon.QuestionCircle />} language={language} title="Help" audio={help_sound} video={help} description="How to use the help page and how to find help for a specific page." />

                    <hr />

                    <HELP_SECTION icon={<Icon.Gear />} language={language} title="Settings" audio={settings_audio} video={settings} description="Change language and audio preferences, hide or show audio icons and delete your account." />

                    <hr />

                    <HELP_SECTION icon={<Icon.BoxArrowUp />} language={language} title="New video" audio={upload_audio} video={upload} description="Upload a new video with different languages. How to remain safe while doing so." />

                    <hr />

                    <HELP_SECTION icon={<Icon.Search />} language={language} title="Search" audio={search_audio} video={search} description="Search for users and content, and filter your search." />

                    <hr />

                    <HELP_SECTION icon={<Icon.List />} language={language} title="Navigation" audio={navigation_audio} video={navigation} description="Navigate between different pages, access the app's menu, logout and view policies." />

                </div>
            );
        } else {
            return <LOADING />;
        }
    }
}

export default HELP;