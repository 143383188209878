export const getPostsWithLikes = /* GraphQL */ `
query getPostsWithLikes(
  $filter: ModelPostFilterInput
) {
    listPosts(filter: $filter) {
      nextToken
      items {
        likes {
          nextToken
          items {
            id
            user
            updatedAt
          }
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          nextToken
          items {
            id
            user
          }
        }
        createdAt
        english
        id
        malay
        burmese
        phone_number
        published
        rohingyalish
        src
        updatedAt
        user
      }
    }
  }
`;

export const getPostsWithReports = /* GraphQL */ `
query getPostsWithLikes(
  $filter: ModelPostFilterInput
) {
    listPosts(filter: $filter) {
      nextToken
      items {
        likes {
          nextToken
          items {
            id
            user
          }
        }
        reports {
          nextToken
          items {
            id
            user
            reason
          }
        }
        createdAt
        english
        id
        malay
        phone_number
        published
        rohingyalish
        src
        updatedAt
        user
      }
    }
  }
`;

export const getOwnPostsWithLikes = /* GraphQL */ `
query getOwnPostsWithLikes {
    listPosts() {
      nextToken
      items {
        likes {
          nextToken
          items {
            id
            user
          }
        }
        createdAt
        english
        id
        malay
        phone_number
        published
        rohingyalish
        src
        updatedAt
        user
      }
    }
  }
`;