import React from "react";
import * as Icon from "react-bootstrap-icons";
import commentPost from "../../constants/posts/commentPost";
import findDateDifference from "../misc/find_date_difference";
import dict from "../misc/translations";
import MODAL_BUTTON from "../modal/modal_button";
require("datejs");

class COMMENTS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            comments: [],
            data: this.props.comments
        };
    }

    componentDidMount() {
        let comments = this.state.data.items;
        this.generateComments(comments);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.comments !== state.comments) {
            return {
                data: props.comments
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.comments !== this.props.comments) {
            this.setState({
                data: this.props.comments
            });
            this.componentDidMount();
        }
    }

    generateComments(comments) {
        comments.sort(function (a, b) {
            return (a.createdAt < b.createdAt) ? -1 : ((a.createdAt > b.createdAt) ? 1 : 0);
        });
        let comment_arr = [];
        for (let i = 0, size = comments.length; i < size; i++) {
            let user = "comment";
            if (comments[i].user === this.props.currentUser) {
                user = "user_comment";
            }

            let comment = <div key={i} style={{position: "relative"}}>
                <MODAL_BUTTON id={comments[i].id + "_comment_modal"}>
                    <div id={comments[i].id} style={{ textAlign: user === "comment" ? "left" : "right", cursor: "pointer" }}>
                        <div className={user}>
                            <div className="speech"><Icon.CaretDownFill /></div>
                            <div className="user_and_date">
                                <strong>{comments[i].user[0].toUpperCase() + comments[i].user.slice(1)}</strong> • {findDateDifference(comments[i].createdAt)}
                            </div>
                            <div className="comment_content">
                                {comments[i].text}
                            </div>
                        </div>
                    </div>
                </MODAL_BUTTON>
            </div>;

            comment_arr.push(comment);
        }

        comment_arr.push(<div id={this.props.id + "scroll_bottom"}></div>);

        this.setState({
            comments: comment_arr
        });
    }

    async sendComment() {
        if (this.state.comment !== "") {
            let res = await commentPost(this.props.id, this.props.currentUser, this.state.comment);
            let src = this.props.src;
            res.data.createComment.post.src = src;
            res.data.createComment.post.linked = true;
            res.data.createComment.post.comments.items.sort(function (a, b) {
                return (a.createdAt < b.createdAt) ? -1 : ((a.createdAt > b.createdAt) ? 1 : 0);
            });
            await this.props.refreshPosts(res.data.createComment.post);

            this.setState({
                comment: ""
            }, () => {
                let comments = document.getElementById(this.props.id);
                if (comments) {
                    comments.style.maxHeight = "none";
                }
            });
        }
    }

    onKeyUp(event) {
        if (event.key === "Enter") {
            this.sendComment()
        }
    }

    render() {
        return (
            <div className="comments">
                <div style={{maxHeight: "500px", overflowY: "auto"}}>
                    <div className="comments_wrap" style={{ maxHeight: "0px" }} id={this.props.id}>{this.state.comments}</div>
                </div>
                <div className="send_box">
                    <input id={this.props.id + "_input"} onKeyUp={(e) => this.onKeyUp(e)} value={this.state.comment} onChange={(e) => { this.setState({ comment: e.currentTarget.value }) }} placeholder={dict["Leave a comment"][this.props.language] + "..."} className="comment_input"></input>
                    <div className="send_icon" onClick={() => this.sendComment()}><Icon.ArrowRightCircle /></div>
                </div>
            </div>
        );
    }
}

export default COMMENTS;