import { Analytics, Auth } from "aws-amplify";
import config from "../config";
import deletePost from "../delete_post/deletePost";
import delete_skill from "../delete_skill/deleteSkill";
import getUserPosts from "../posts/getUserPosts";
import getUserSkills from "../get_skills/getUserSkills";

export default async function deleteUser(username) {
    let AWS = require('aws-sdk');

    var params = {
        UserPoolId: process.env.REACT_APP_USER_POOL,
        Username: username
    };

    AWS.config.update(config);

    var cog = new AWS.CognitoIdentityServiceProvider();

    let posts = await getUserPosts(username);
    await deletePosts(posts);

    let skills = await getUserSkills(username);
    await deleteSkills(username, skills);

    cog.adminDeleteUser(params, async function (err, data) {
        if (err) console.log(err); // an error occurred
        else {
            Analytics.record({name: "delete_account"});
            await Auth.signOut();
            window.location.href = "/";
        }          // successful response
    });
}

async function deletePosts(res){
    for(let i = 0, size = res.length; i < size; i++) {
        await deletePost(res[i].id);
    }
}

async function deleteSkills(username, res) {
    for(let i = 0, size = res.length; i < size; i++) {
        await delete_skill(username, res[i].id);
    }
}