import React from "react";
import { Analytics, Auth } from "aws-amplify";
import LOADING from "../loading/loading";
import * as Icon from "react-bootstrap-icons";
import dict from "../misc/translations";
import history from "../../history";

class LOGIN extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loading: false,
            error: null
        }
    }

    updateState(e, state) {
        this.setState({
            [state]: e.currentTarget.value,
            error: null
        });
    }

    login = () => {
        this.setState({
            loading: true
        });

        Auth.signIn(this.state.username, this.state.password)
            .then(user => console.log(user))
            .then(() => {
                Analytics.record({name: "login", attributes: {result: "success"}});
                history.push("/videos");
            })
            .catch(async err => {
                let error = err.message;
                let code = err.code;
                if(code === "UserNotConfirmedException") {
                    let res = await Auth.resendSignUp(this.state.username);
                    
                    this.props.verify();
                    this.props.username(this.state.username);
                    this.props.updateNumber(res.CodeDeliveryDetails.destination);
                    this.props.storePassword(this.state.password);
                }

                console.log(err);

                Analytics.record({name: "login", attributes: {result: "failed"}});

                this.setState({
                    loading: false,
                    error: error
                });
            });
    }

    onKeyUp(event) {
        if (event.key === "Enter") {
            this.login()
        }
    }

    goToPassword(event) {
        if (event.key === "Enter") {
            document.getElementById("password").focus();
        }
    }

    render() {
        return (
            <div className="login-form">
                <LOADING show={this.state.loading}/>
                <input onKeyUp={(e) => this.goToPassword(e)} type="text" value={this.state.username} placeholder={dict["Username"][this.props.language]} onChange={(e) => this.updateState(e, "username")}></input><br />
                <input id="password" onKeyUp={(e) => this.onKeyUp(e)} type="password" value={this.state.password} placeholder={dict["Password"][this.props.language]} onChange={(e) => this.updateState(e, "password")}></input><br />
                <div className="error">{this.state.error}</div>
                <button className="primary" onClick={this.login}><div className="button_icon"><Icon.BoxArrowInRight/></div>{dict["Login"][this.props.language]}</button>

                <hr />

                <button onClick={this.props.register} className="secondary"><div className="button_icon"><Icon.PersonPlus/></div>{dict["Register"][this.props.language]}</button><br />

                <div className="small_text">{dict["Forgotten your password?"][this.props.language]} <div onClick={this.props.request_reset} className="small_text link">{dict["Reset here"][this.props.language]}</div></div>
            </div>
        );
    }
}

// export default withAuthenticator(Login);
export default LOGIN;