import Storage from "@aws-amplify/storage";

export default async function account_audio() {
    return ({
        "no_followers": {
            "English": new Audio(await Storage.get("audio/account/English/noFollowers.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "not_following": {
            "English": new Audio(await Storage.get("audio/account/English/notFollowing.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "add_skill": {
            "English": new Audio(await Storage.get("audio/account/English/addSkill.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "delete_skill": {
            "English": new Audio(await Storage.get("audio/account/English/deleteSkill.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "no_skills": {
            "English": new Audio(await Storage.get("audio/account/English/noSkills.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "update_account": {
            "English": new Audio(await Storage.get("audio/account/English/updateAccount.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "your_posts": {
            "English": new Audio(await Storage.get("audio/account/English/yourVideos.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "your_skills": {
            "English": new Audio(await Storage.get("audio/account/English/yourSkills.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "thanks": {
            "English": new Audio(await Storage.get("audio/thanks/English/review.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },
    })
}