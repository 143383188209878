import Storage from "@aws-amplify/storage";

export default async function tc_audio() {
    return ({
        "cookie": {
            "title": {
                "English": new Audio(await Storage.get("audio/TC/English/cookies/title.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "what": {
                "English": new Audio(await Storage.get("audio/TC/English/cookies/meaning.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "use": {
                "English": new Audio(await Storage.get("audio/TC/English/cookies/uses.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "how": {
                "English": new Audio(await Storage.get("audio/TC/English/cookies/delete.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },
        },

        "contact": {
            "English": new Audio(await Storage.get("audio/TC/English/contact.m4a")),
            "Rohingyalish": new Audio(),
            "Burmese": new Audio(),
            "Malay": new Audio()
        },

        "user": {
            "title": {
                "English": new Audio(await Storage.get("audio/TC/English/user/title.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "can": {
                "English": new Audio(await Storage.get("audio/TC/English/user/allowed.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "cant": {
                "English": new Audio(await Storage.get("audio/TC/English/user/notAllowed.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "terminate": {
                "English": new Audio(await Storage.get("audio/TC/English/user/termination.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "disclaimer": {
                "English": new Audio(await Storage.get("audio/TC/English/user/disclaimer.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },
        },

        "privacy": {
            "title": {
                "English": new Audio(await Storage.get("audio/TC/English/privacy/title.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "what": {
                "English": new Audio(await Storage.get("audio/TC/English/privacy/info.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "where": {
                "English": new Audio(await Storage.get("audio/TC/English/privacy/source.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "why": {
                "English": new Audio(await Storage.get("audio/TC/English/privacy/reason.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "how": {
                "English": new Audio(await Storage.get("audio/TC/English/privacy/storage.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "who": {
                "English": new Audio(await Storage.get("audio/TC/English/privacy/sharing.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "rights": {
                "English": new Audio(await Storage.get("audio/TC/English/privacy/rights.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },

            "laws": {
                "English": new Audio(await Storage.get("audio/TC/English/privacy/protection.m4a")),
                "Rohingyalish": new Audio(),
                "Burmese": new Audio(),
                "Malay": new Audio()
            },
        }
    })
}