const emoji = {
    "cleaning": <span>🧹</span>,
    "carpentry": <span>🔨</span>,
    "cooking": <span>🍳</span>,
    "baking": <span>🍞</span>,
    "electrician": <span>⚡</span>,
    "plumbing": <span>🚽</span>,
    "childcare": <span>👶</span>,
    "painting": <span>🎨</span>,
    "sewing": <span>🧵</span>
}

export default emoji;