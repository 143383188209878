import React from "react";
import ukicon from "../../img/uk-icon.png";
import rohingyaicon from "../../img/rohingyalish-icon.png";
import malaysiaicon from "../../img/malaysia-icon.png";
import burmaicon from "../../img/burma-icon.png";
import hanifiicon from "../../img/hanifi-icon.png";
import tick from "../../img/icon-tick.svg";
import updateUser from "../../constants/user/updateUser";
import dict from "./translations";

class LANGUAGE_RADIO extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: this.props.language
        }
    }

    selectLanguage(language) {
        localStorage.setItem("preferred_written_language", language);
        this.setState({
            language: language
        }, async () => {
            await updateUser(
                this.props.name,
                this.props.number,
                this.props.bio,
                this.state.language
            );

            this.props.refresh();

            let root = document.documentElement;
            root.style.setProperty('--sans', language === "Hanifi" ? 'hanifi' : "'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif");
            root.style.setProperty('--name', language === "Hanifi" ? 'hanifi' : "'Quicksand', sans-serif");
        });
    }

    render() {
        let language = this.state.language;
        return (
            <div>
                <div className="language_radio">
                    <div onClick={() => this.selectLanguage("English")} className="item"><img alt="English" className="item-icon" src={ukicon} /> {dict["English"][language]} <img alt="Tick" src={tick} className="tick" style={{ display: this.state.language === "English" ? "inline-block" : "none" }} /></div>
                    <hr />
                    <div onClick={() => this.selectLanguage("Bahasa Malaysia")} className="item"><img alt="Bahasa Malaysia" className="item-icon" src={malaysiaicon} /> {dict["Bahasa Malaysia"][language]} <img alt="Tick" src={tick} className="tick" style={{ display: this.state.language === "Bahasa Malaysia" ? "inline-block" : "none" }} /></div>
                    <hr />
                    <div onClick={() => this.selectLanguage("Rohingyalish")} className="item"><img alt="Rohingyalish" className="item-icon" src={rohingyaicon} /> {dict["Rohingyalish"][language]} <img alt="Tick" src={tick} className="tick" style={{ display: this.state.language === "Rohingyalish" ? "inline-block" : "none" }} /></div>
                    <hr />
                    <div onClick={() => this.selectLanguage("Burmese")} className="item"><img alt="Burmese" className="item-icon" src={burmaicon} /> {dict["Burmese"][language]} <img alt="Tick" src={tick} className="tick" style={{ display: this.state.language === "Burmese" ? "inline-block" : "none" }} /></div>
                    <hr />
                    <div onClick={() => this.selectLanguage("Hanifi")} className="item"><img alt="Hanifi" className="item-icon" src={hanifiicon} /> {dict["Hanifi"][language]} <img alt="Tick" src={tick} className="tick" style={{ display: this.state.language === "Hanifi" ? "inline-block" : "none" }} /></div>
                </div>
            </div>
        );
    }
}

export default LANGUAGE_RADIO;