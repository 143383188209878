export default function hide_audio() {
    let n = JSON.parse(localStorage.getItem("hide_audio_icons"));
    if (n) {
        let divsToHide = document.getElementsByClassName("audio");
        for (let i = 0; i < divsToHide.length; i++) {
            divsToHide[i].style.display = "none";
        }

        divsToHide = document.getElementsByClassName("audio_br");
        for (let i = 0; i < divsToHide.length; i++) {
            divsToHide[i].style.display = "none";
        }
    } else {
        let divsToHide = document.getElementsByClassName("audio");
        for (let i = 0; i < divsToHide.length; i++) {
            divsToHide[i].style.display = "";
        }

        divsToHide = document.getElementsByClassName("audio_br");
        for (let i = 0; i < divsToHide.length; i++) {
            divsToHide[i].style.display = "";
        }
    }
}