import Storage from "@aws-amplify/storage";

export default async function help_audio() {
    return ({
        "newsFeed": {
            "English": await Storage.get("video/English/newsFeed.mp4"),
            "Rohingyalish": "",
            "Malay": "",
            "Burmese": ""
        },

        "saved": {
            "English": await Storage.get("video/English/saved.mp4"),
            "Rohingyalish": "",
            "Malay": "",
            "Burmese": ""
        },

        "notifications": {
            "English": await Storage.get("video/English/notifications.mp4"),
            "Rohingyalish": "",
            "Malay": "",
            "Burmese": ""
        },

        "account": {
            "English": await Storage.get("video/English/account.mp4"),
            "Rohingyalish": "",
            "Malay": "",
            "Burmese": ""
        },

        "settings": {
            "English": await Storage.get("video/English/settings.mp4"),
            "Rohingyalish": "",
            "Malay": "",
            "Burmese": ""
        },

        "upload": {
            "English": await Storage.get("video/English/upload.mp4"),
            "Rohingyalish": "",
            "Malay": "",
            "Burmese": ""
        },

        "search": {
            "English": await Storage.get("video/English/search.mp4"),
            "Rohingyalish": "",
            "Malay": "",
            "Burmese": ""
        },

        "navigation": {
            "English": await Storage.get("video/English/navigation.mp4"),
            "Rohingyalish": "",
            "Malay": "",
            "Burmese": ""
        },

        "help": {
            "English": await Storage.get("video/English/help.mp4"),
            "Rohingyalish": "",
            "Malay": "",
            "Burmese": ""
        },
    });
};