import { API, graphqlOperation } from "aws-amplify";
import { getPostsWithLikes } from "../../graphql/custom";

export default async function getAllPosts() {
    const apiData = await API.graphql(graphqlOperation(getPostsWithLikes, {
        // filter: {
        //     published: {
        //         eq: true
        //     }
        // }
    }));
    
    return apiData.data.listPosts.items.sort(function(a, b) {
        return (a.createdAt > b.createdAt) ? -1 : ((a.createdAt < b.createdAt) ? 1 : 0);
    });
}