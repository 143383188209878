import { Auth } from 'aws-amplify';
import history from '../../history';

export default function Current_User(props, path) {
    Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => props(user))
        .catch(err => {
            if(!path) {
                path = "login";
            }

            if (window.location.pathname !== "/" + path.toLowerCase()) {
                history.push(path.toLowerCase());
            }

            props(err);
        });
}