import React from "react";
import Current_User from "../auth/current_user";
import NEWS_FEED from "../feed/news_feed";
import logo from "../../img/logo-new.svg";
import Logout from "../auth/logout";
import ACCOUNT from "../account/account";
import Menu from "../menu/menu";
import HELP from "../help/help";
import history from "../../history";
import SETTINGS from "../settings/settings";
import UPLOAD_POST from "../feed/upload";
import UPLOAD_POSITION from "../positions/upload";
import LOADING from "../loading/loading";
import dict from "../misc/translations";
import * as Icon from 'react-bootstrap-icons';
import getUserPosts from "../../constants/posts/getUserPosts";
import getAllPosts from "../../constants/posts/getAllPosts";
import getAllPositions from "../../constants/positions/getAllPositions";
// import getUser from "../../constants/user/getOtherUser";
import USER from "../user/user";
import Search from "../search/search";
import getAllUsers from "../../constants/user/getAllUsers";
import POST_LINK from "../feed/post_link";
import NOTIFICATIONS from "../notifications/notifications";
import SAVED from "../saved/saved";
import getOtherUserFollowers from "../../constants/user/getOtherFollowed";
import getOtherUserFollows from "../../constants/user/getOtherFollows";
import getUserEndorsements from "../../constants/get_skills/getUserEndorsements";
import getUserSkills from "../../constants/get_skills/getUserSkills";
import COMING_SOON from "../coming_soon/coming_soon";
import { Analytics, Storage } from "aws-amplify";
import print from "../../img/print.svg";
import MODAL_BUTTON from "../modal/modal_button";
import play_audio from "../misc/play_audio";
import main_audio from "./main_audio";
import hide_audio from "../settings/hide_audio";
import ERROR from "../error/error";
import TE from "../../img/TE.svg";
import TE2 from "../../img/TE2.svg";
import help_audio from "../help/help_audio";
import user_audio from "../user/user_audio";
import account_audio from "../account/account_audio";
import feed_audio from "../feed/feed_audio";
import saved_audio from "../saved/saved_audio";
import search_audio from "../search/search_audio";
import settings_audio from "../settings/settings_audio";
require("datejs");

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: this.props.display,
            user: null,
            userPosts: [],
            userPositions: [],
            allUsers: [],
            allPosts: [],
            allPositions: [],
            sub_path: null,
            search: "",
            e: [],
            following: null,
            followers: null,
            loading: true,
            skills: [],
            help_id: null,
            help_videos: null,
            user_audio: null,
            account_audio: null,
            feed_audio: null,
            main_audio: null,
            saved_audio: null,
            search_audio: null,
            settings_audio: null
        };
    }

    componentDidMount() {
        // history.listen(({ action }) => {
        //     if (action === "POP") {
        //         this.refreshPage();
        //     }
        // });

        Current_User((e) => this.SSO(e), "/");
    }

    static getDerivedStateFromProps(props, state) {
        if (props.display !== state.display) {
            return {
                display: props.display
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.display !== this.props.display) {
            this.setState({
                likes: this.props.display
            });
            this.componentDidMount();
        }
    }

    refreshPage() {
        let path = window.location.pathname.replace(/\//, '');
        let sub_path = path.split("/").pop();
        path = path.split("/")[0];

        this.setState({
            display: path,
            sub_path: sub_path
        });
    }

    SSO(user) {
        if (user?.username) {
            let path = window.location.pathname.replace(/\//, '');
            let sub_path = path.split("/").pop();
            path = path.split("/")[0];
            user.username = user.username.toLowerCase();

            this.setState({
                user: user,
                language: user?.attributes["custom:language_preference"],
                loading: false,
                display: this.props.display,
                sub_path: sub_path
            }, async () => {
                this.getHelpVideos();
                this.getAudio();
                this.getAllPosts();
                // this.getAllPositions();
                this.getUserDetails();
                this.getAllUsers();
                this.setState({ loading: false });
            });
        } else {
            history.push("/");
        }
    }

    async getHelpVideos() {
        let videos = await help_audio();
        this.setState({
            help_videos: videos
        });
    }

    async getAudio() {
        let main = await main_audio();
        let audio = await user_audio();
        let account = await account_audio();
        let feed = await feed_audio();
        let saved = await saved_audio();
        let search = await search_audio();
        let settings = await settings_audio();
        this.setState({
            user_audio: audio,
            account_audio: account,
            feed_audio: feed,
            main_audio: main,
            saved_audio: saved,
            search_audio: search,
            settings_audio: settings
        });
    }

    async getUserDetails() {
        try {
            // let positionData = await getUserPositions(this.state.user.username);
            await this.getPosts(this.state.user.username);
            await this.getFollowing(this.state.user.username);
            await this.getFollowers(this.state.user.username);
            await this.getEndorsements(this.state.user.username);
            await this.getSkills(this.state.user.username);
        } catch (err) {
            // window.location.href = "/error";
        }

        this.setState({
            // userPositions: positionData,
        });
    }

    async getEndorsements(username) {
        let endorsements = await getUserEndorsements(username);

        this.setState({
            e: endorsements
        });
    }

    async getFollowing(username) {
        let following = await getOtherUserFollows(username);

        this.setState({
            following: following
        });
    }

    async getFollowers(username) {
        let followers = await getOtherUserFollowers(username);

        this.setState({
            followers: followers
        });
    }

    async getSkills(username) {
        let skills = await getUserSkills(username);

        this.setState({
            skills: skills
        });
    }

    async getPosts(username) {
        let posts = await getUserPosts(username);

        this.setState({
            userPosts: posts
        });
    }

    async getAllPosts() {
        let posts = await getAllPosts();

        for (let i = 0, size = posts.length; i < size; i++) {
            posts[i].src = await Storage.get(posts[i].src);
            posts[i].linked = true;
        }

        this.setState({
            allPosts: posts
        });
    }

    async getAllPositions() {
        let positions = await getAllPositions();

        this.setState({
            allPositions: positions
        });
    }

    async getAllUsers() {
        await getAllUsers(e => {
            this.setState({
                allUsers: e
            });
        })
    }

    changePage(page) {
        let sub_path = page.split("/").pop();
        let path = page.split("/")[0];
        Analytics.record({ name: "change_page", attributes: { page: path } });
        this.setState({
            display: path,
            sub_path: sub_path,
            search: ""
        });
    }

    loadNew(page) {
        this.setState({
            search: ""
        });
        history.push("/" + page);
        // this.changePage(page);
    }

    updateSearch(e) {
        this.setState({
            search: e.currentTarget.value
        });
    }

    updatePost(e) {
        let posts = [...this.state.allPosts];
        let index = posts.map(function (e) { return e.id; }).indexOf(e?.id);;
        posts[index] = e;

        this.setState({
            allPosts: posts
        });
    }

    removePost(id) {
        let posts = [...this.state.allPosts];
        let index = posts.map(function (e) { return e.id; }).indexOf(id);
        posts.splice(index, 1);

        this.setState({
            allPosts: posts
        });
    }

    addPost(e) {
        let posts = [...this.state.allPosts];
        posts.unshift(e);

        this.setState({
            allPosts: posts
        });
    }

    render() {
        if (this.state.main_audio !== null) {
            let display = <ERROR />;
            let language = this.state.language;
            let root = document.documentElement;
            root.style.setProperty('--sans', language === "Hanifi" ? 'hanifi' : "'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif");
            root.style.setProperty('--name', language === "Hanifi" ? 'hanifi' : "'Quicksand', sans-serif");
            let page = this.state.display;
            let heading = "";

            if (this.state.search !== "") {
                page = "search";
            }

            let spoken = localStorage.getItem("preferred_spoken_language") || "English";

            let news_feed = this.state.main_audio["news_feed"][spoken];
            let account = this.state.main_audio["account"][spoken];
            let saved = this.state.main_audio["saved"][spoken];
            let notifications = this.state.main_audio["notifications"][spoken];
            let help = this.state.main_audio["help"][spoken];
            let settings = this.state.main_audio["settings"][spoken];
            let upload_post = this.state.main_audio["upload_post"][spoken];
            let search = this.state.main_audio["search"][spoken];

            switch (page) {
                case "videos":
                    heading = <div>{dict["Videos"][language]}<div style={{ color: "#e9e9e9" }} className="audio" onClick={() => play_audio(news_feed)}><Icon.VolumeUp /></div></div>
                    display = <NEWS_FEED
                        refresh={() => this.componentDidMount()}
                        refreshPosts={(e) => this.updatePost(e)}
                        posts={this.state.allPosts.filter(e => e.published === true)}
                        language={language}
                        visitUser={(e) => this.loadNew(e)}
                        visitPost={(e) => this.loadNew(e)}
                        currentUser={this.state.user?.username}
                        following={this.state.following}
                        audio={this.state.feed_audio} />;
                    break;

                case "account":
                    heading = <div>{dict["Account"][language]}<div style={{ color: "#e9e9e9" }} className="audio" onClick={() => play_audio(account)}><Icon.VolumeUp /></div></div>
                    display = <ACCOUNT
                        skills={this.state.skills}
                        refresh={() => this.componentDidMount()}
                        refreshSkills={() => this.getSkills(this.state.user?.username)}
                        e={this.state.e}
                        refreshAccount={() => this.getUserDetails()}
                        removePost={(e) => this.removePost(e)}
                        refreshPosts={(e) => this.updatePost(e)}
                        refreshUserPosts={() => this.getPosts(this.state.user?.username)}
                        user={this.state.user}
                        name={this.state.user?.attributes?.name}
                        username={this.state.user?.username}
                        number={this.state.user?.attributes?.phone_number}
                        bio={this.state.user?.attributes["custom:bio"]}
                        positionData={this.state.userPositions}
                        postData={this.state.allPosts.filter(e => e.user === this.state.user?.username)}
                        visitUser={(e) => this.loadNew(e)}
                        language={language}
                        followers={this.state.followers}
                        following={this.state.following}
                        audio={this.state.account_audio} />;
                    break;

                case "positions":
                    heading = <div>{dict["Positions"][language]}<div style={{ color: "#e9e9e9" }} className="audio"><Icon.VolumeUp /></div></div>
                    // display = <Positions
                    //     refresh={() => this.componentDidMount()}
                    //     positions={this.state.allPositions}
                    //     language={language} />;

                    display = <COMING_SOON language={language} />
                    break;

                case "saved":
                    heading = <div>{dict["Saved"][language]}<div style={{ color: "#e9e9e9" }} className="audio" onClick={() => play_audio(saved)}><Icon.VolumeUp /></div></div>
                    display = <SAVED
                        visitUser={(e) => this.loadNew(e)}
                        refresh={() => this.componentDidMount()}
                        refreshPosts={(e) => this.updatePost(e)}
                        posts={this.state.allPosts.filter(e => e.published === true)}
                        currentUser={this.state.user?.username}
                        feed={() => this.loadNew("videos")}
                        language={language}
                        audio={this.state.saved_audio}
                        feed_audio={this.state.feed_audio} />;
                    break;

                case "notifications":
                    heading = <div>{dict["Notifications"][language]}<div style={{ color: "#e9e9e9" }} className="audio" onClick={() => play_audio(notifications)}><Icon.VolumeUp /></div></div>
                    display = <NOTIFICATIONS
                        refresh={() => this.componentDidMount()}
                        name={this.state.user?.attributes?.name}
                        username={this.state.user?.username}
                        number={this.state.user?.attributes?.phone_number}
                        bio={this.state.user?.attributes["custom:bio"]}
                        language={language}
                        posts={this.state.allPosts.filter(e => e.published === true)}
                        userPosts={this.state.userPosts}
                        positions={this.state.allPositions}
                        following={this.state.following}
                        currentUser={this.state.user?.username}
                        visitPost={(e) => this.loadNew(e)}
                        endorsements={this.state.endorsements} />;
                    break;

                case "help":
                    heading = <div>{dict["Help"][language]}<div style={{ color: "#e9e9e9" }} className="audio" onClick={() => play_audio(help)}><Icon.VolumeUp /></div></div>
                    display = <HELP
                        id={this.state.help_id}
                        refresh={() => this.componentDidMount()}
                        language={language}
                        videos={this.state.help_videos}
                        audio={this.state.main_audio} />;
                    break;

                case "settings":
                    heading = <div>{dict["Settings"][language]}<div style={{ color: "#e9e9e9" }} className="audio" onClick={() => play_audio(settings)}><Icon.VolumeUp /></div></div>
                    display = <SETTINGS
                        refresh={() => this.componentDidMount()}
                        name={this.state.user?.attributes?.name}
                        username={this.state.user?.username}
                        number={this.state.user?.attributes?.phone_number}
                        bio={this.state.user?.attributes["custom:bio"]}
                        language={language}
                        rerender={() => this.props.rerender()}
                        changeFont={(e) => this.props.changeFont(e)}
                        audio={this.state.settings_audio}
                    />;
                    break;

                case "upload_position":
                    heading = dict[this.state.display !== "" ? this.state.display[0].toUpperCase() + this.state.display.slice(1).replace(/_/g, " ") : ""][language]
                    display = <UPLOAD_POSITION
                        refresh={() => this.componentDidMount()}
                        refreshAccount={() => this.getUserDetails()}
                        cancel={() => this.loadNew("positions")}
                        user={this.state.user?.username}
                        number={this.state.user?.attributes?.phone_number}
                        language={language}
                        confirm={() => this.loadNew("positions")} />;
                    break;

                case "new_video":
                    heading = <div>{dict["New video"][language]}<div style={{ color: "#e9e9e9" }} className="audio" onClick={() => play_audio(upload_post)}><Icon.VolumeUp /></div></div>
                    display = <UPLOAD_POST
                        refresh={() => this.componentDidMount()}
                        refreshPosts={(e) => this.updatePost(e)}
                        refreshAccount={() => this.getUserDetails()}
                        addPost={(e) => this.addPost(e)}
                        cancel={() => this.loadNew("videos")}
                        user={this.state.user?.username}
                        number={this.state.user?.attributes?.phone_number}
                        language={language}
                        postData={this.state.userPosts}
                        confirm={() => this.loadNew("videos")} />;
                    break;

                case "user":
                    heading = "";
                    display = <USER
                        refresh={() => this.componentDidMount()}
                        updateFollowing={() => this.getFollowing(this.state.user?.username)}
                        user={this.props.match.params.id}
                        language={language}
                        visitUser={(e) => this.loadNew(e)}
                        currentUser={this.state.user?.username}
                        audio={this.state.user_audio}
                        feed_audio={this.state.feed_audio}
                        account_audio={this.state.account_audio} />;
                    break;

                case "video":
                    heading = "";
                    if (this.state.allPosts.length > 0) {
                        display = <POST_LINK
                            refresh={() => this.componentDidMount()}
                            refreshPosts={(e) => this.updatePost(e)}
                            posts={this.state.allPosts}
                            post={this.props.match.params.id}
                            language={language}
                            visitUser={(e) => this.loadNew(e)}
                            currentUser={this.state.user?.username}
                            audio={this.state.feed_audio} />;
                    } else {
                        display = <LOADING show />;
                    }
                    break;

                case "search":
                    heading = <div>{dict["Search"][language]}<div style={{ color: "#e9e9e9" }} className="audio" onClick={() => play_audio(search)}><Icon.VolumeUp /></div></div>;
                    display = <Search
                        refreshPosts={() => this.getAllPosts()}
                        search={this.state.search}
                        language={language}
                        posts={this.state.allPosts}
                        positions={this.state.allPositions}
                        users={this.state.allUsers}
                        link={(e) => this.loadNew(e)}
                        visitUser={(e) => this.loadNew(e)}
                        currentUser={this.state.user?.username}
                        clearSearch={() => this.setState({ search: "" })}
                        audio={this.state.search_audio}
                        feed_audio={this.state.feed_audio} />
                    break;

                default:
                    heading = "";
                    display = <USER
                        refresh={() => this.componentDidMount()}
                        updateFollowing={() => this.getFollowing(this.state.user?.username)}
                        user={this.props.match.params.id}
                        language={language}
                        visitUser={(e) => this.loadNew(e)}
                        currentUser={this.state.user?.username} />;
                    break;
            }

            hide_audio();

            return (
                <div id="outer-container">
                    <Menu
                        language={language}
                        pageWrapId={"page-wrap"}
                        outerContainerId={"outer-container"}
                        current={this.state.display}
                        account={() => this.loadNew("account")}
                        feed={() => this.loadNew("videos")}
                        positions={() => this.loadNew("positions")}
                        help={() => this.loadNew("help")}
                        settings={() => this.loadNew("settings")}
                        saved={() => this.loadNew("saved")}
                        notifications={() => this.loadNew("notifications")} />
                    <main id="page-wrap">
                        <LOADING show={this.state.loading} />
                        <div className="encompass">
                            <div className="menu">
                                <img alt="Logo" className="logo" src={logo} />
                                <div className="heading">TalentEd</div>
                                <div className="search_icon"><Icon.Search /></div>
                                <input type="text" value={this.state.search} onChange={(e) => this.updateSearch(e)} placeholder={dict["Search here"][language] + "..."} className="search_bar"></input>
                                <hr style={{ marginTop: "20px" }} />
                                <div style={{ color: this.state.display === "videos" ? "#cba42d" : "#292929" }} onClick={() => this.loadNew("videos")} className="item"><div className="menu_icon">{this.state.display === "videos" ? <img className="te_icon" src={TE2} alt="TalentEd" /> : <img className="te_icon" src={TE} alt="TalentEd" />}</div>{dict["Videos"][language]}</div>
                                {/* <div style={{ color: this.state.display === "positions" ? "#cba42d" : "#292929" }} onClick={() => this.loadNew("positions")} className="item"><div className="menu_icon"><Icon.Briefcase /></div>{dict["Positions"][language]}</div> */}

                                {/* <hr /> */}
                                <div style={{ color: this.state.display === "saved" ? "#cba42d" : "#292929" }} onClick={() => this.loadNew("saved")} className="item"><div className="menu_icon"><Icon.Star /></div>{dict["Saved"][language]}</div>
                                <div style={{ color: this.state.display === "notifications" ? "#cba42d" : "#292929" }} onClick={() => this.loadNew("notifications")} className="item"><div className="menu_icon"><Icon.Bell /></div>{dict["Notifications"][language]}</div>
                                <div style={{ color: this.state.display === "account" ? "#cba42d" : "#292929" }} onClick={() => this.loadNew("account")} className="item"><div className="menu_icon"><Icon.Person /></div>{dict["Account"][language]}</div>
                                <hr />
                                <div style={{ color: this.state.display === "help" ? "#cba42d" : "#292929" }} onClick={() => this.loadNew("help")} className="item"><div className="menu_icon"><Icon.QuestionCircle /></div>{dict["Help"][language]}</div>
                                <div style={{ color: this.state.display === "settings" ? "#cba42d" : "#292929" }} onClick={() => this.loadNew("settings")} className="item"><div className="menu_icon"><Icon.Gear /></div>{dict["Settings"][language]}</div>
                                <hr />
                                <div className="item"><Logout language={language} /></div>
                                <hr />
                                <MODAL_BUTTON id="cookie_policy"><div className="item small">{dict["Cookie Policy"][language]}</div></MODAL_BUTTON>
                                <MODAL_BUTTON id="user_agreement"><div className="item small">{dict["User Agreement"][language]}</div></MODAL_BUTTON>
                                <MODAL_BUTTON id="privacy_policy"><div className="item small">{dict["Privacy Policy"][language]}</div></MODAL_BUTTON>
                            </div>
                            <div className="body_wrap">
                                <div className="print_wrap"><img alt="Print" className="print" src={print}></img></div>
                                <div className="body" id="body">
                                    <div className="home">
                                        <div className="header">
                                            <i onClick={() => {
                                                localStorage.setItem("navigate_to_help", this.state.display);
                                                this.loadNew("help");
                                            }} className="help"><Icon.QuestionCircle /></i>
                                            <div className="search_icon"><Icon.Search /></div>
                                            <input type="text" value={this.state.search} onChange={(e) => this.updateSearch(e)} placeholder={dict["Search here"][language] + "..."} className="search_bar"></input>
                                            <i onClick={() => {
                                                document.querySelector('.bm-burger-button button').click();
                                            }} className="burger"><Icon.List /></i>
                                        </div>
                                        {/* <img className="main_logo" style={{ display: this.state.display === "account" ? "none" : "", width: "150px" }} src={logo} /> */}
                                        <div className="page_heading">{heading}</div>
                                        <div style={{ paddingBottom: this.state.display === "videos" || this.state.display === "positions" ? "150px" : "80px" }} className="padding_bottom">{display}</div>
                                    </div>
                                </div>
                                <div className="footer_menu" /*style={{ marginBottom: this.state.display === "help" || this.state.display === "settings" ? "-80px" : "" }}*/>
                                    <div style={{ color: this.state.display === "videos" ? "#cba42d" : "#292929", background: this.state.display === "videos" ? "linear-gradient(to top, #e9e9e960 0%, #e9e9e900 100%)" : "#e9e9e9" }} onClick={() => this.loadNew("videos")} className="footer_item">{this.state.display === "videos" ? <img className="te_icon" src={TE2} alt="TalentEd" /> : <img className="te_icon" src={TE} alt="TalentEd" />}</div>
                                    {/* <div style={{ color: this.state.display === "positions" ? "#cba42d" : "#292929", backgroundColor: this.state.display === "positions" ? "#292929" : "#e9e9e9"  }} onClick={() => this.loadNew("positions")} className="footer_item"><Icon.Briefcase /></div> */}
                                    <div style={{ color: this.state.display === "saved" ? "#cba42d" : "#292929", background: this.state.display === "saved" ? "linear-gradient(to top, #e9e9e960 0%, #e9e9e900 100%)" : "#e9e9e9" }} onClick={() => this.loadNew("saved")} className="footer_item">{this.state.display === "saved" ? <Icon.StarFill /> : <Icon.Star />}</div>
                                    <div style={{ color: this.state.display === "notifications" ? "#cba42d" : "#292929", background: this.state.display === "notifications" ? "linear-gradient(to top, #e9e9e960 0%, #e9e9e900 100%)" : "#e9e9e9" }} onClick={() => this.loadNew("notifications")} className="footer_item">{this.state.display === "notifications" ? <Icon.BellFill /> : <Icon.Bell />}</div>
                                    <div style={{ color: this.state.display === "account" ? "#cba42d" : "#292929", background: this.state.display === "account" ? "linear-gradient(to top, #e9e9e960 0%, #e9e9e900 100%)" : "#e9e9e9" }} onClick={() => this.loadNew("account")} className="footer_item">{this.state.display === "account" ? <Icon.PersonFill /> : <Icon.Person />}</div>
                                </div>
                            </div>
                            <div
                                style={{ display: this.state.display === "videos" /*|| this.state.display === "positions"*/ ? "block" : "none" }}
                                className="plus_button"
                                onClick={this.state.display === "videos" ? () => this.loadNew("new_video") : () => this.loadNew("upload_position")}
                            >
                                &#43;
                        </div>
                        </div>
                    </main>
                </div>
            );
        } else {
            return <LOADING />;
        }
    }
}

export default Main;