import React from "react";
import MODAL from "../modal/modal";
import * as Icon from "react-bootstrap-icons";
import play_audio from "../misc/play_audio";

class USER_AGREEMENT extends React.Component {
    render() {
        let language = localStorage.getItem("preferred_spoken_language") || "English";
        let heading = this.props.audio["user"]["title"][language];
        let one = this.props.audio["user"]["can"][language];
        let two = this.props.audio["user"]["cant"][language];
        let three = this.props.audio["user"]["terminate"][language];
        let four = this.props.audio["user"]["disclaimer"][language];
        let five = this.props.audio["contact"][language];

        return (
            <MODAL id="user_agreement">
                <div className="policy">
                    <div className="policy_heading">
                        User Agreement
                        <div className="audio" onClick={() => play_audio(heading)}><Icon.VolumeUp /></div>
                    </div>
                    <div className="policy_content">
                        <div className="policy_normal">
                            <strong>Enactment Date: 02/2021</strong>
                        </div>
                        <div className="policy_sub_heading">
                            <div className="audio_text">Allowed</div>
                            <div className="audio" onClick={() => play_audio(one)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            You may:
                            <ul>
                                <li>Upload videos</li>
                                <li>Add and modify account details</li>
                                <li>Interact with videos</li>
                                <li>Endorse the skills of other users</li>
                                <li>Report content</li>
                                <li>Follow and unfollow other users</li>
                            </ul>
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Not allowed</div>
                            <div className="audio" onClick={() => play_audio(two)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            You may not:
                            <ul>
                                <li>Upload any inappropriate videos or content</li>
                                <li>Attempt to hack or gain sensitive information from this app</li>
                                <li>Sell, lease or copy the technology</li>
                                <li>Use the app to facilitate criminal behaviour</li>
                                <li>Use this app as a platform for cyber-bullying</li>
                            </ul>
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Termination</div>
                            <div className="audio" onClick={() => play_audio(three)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            To terminate the User Agreement, delete your account associated with the app and delete the app from your device.
                            The TalentEd administration team reserves the right to delete your account without forewarning should you breach the User Agreement.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Disclaimer</div>
                            <div className="audio" onClick={() => play_audio(four)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            The app does not provide a warranty, it is not guaranteed that the app will meet your requirements, or that the app will be error-free. You assume all responsibility for the installation and use of the app.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Contact</div>
                            <div className="audio" onClick={() => play_audio(five)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                        If you have any questions about the terms, please contact us at <a href="mailto:rmd5@hw.ac.uk">rmd5@hw.ac.uk</a>.
                        </div>
                    </div>
                </div>
            </MODAL>
        );
    }
}

export default USER_AGREEMENT;