export default function findDateDifference(date) {
    let now = new Date();
    let dif = now - Date.parse(date);
    dif = dif / 1000;
    if (dif > 60) {
        dif = dif / 60;
    } else {
        return Math.floor(dif) + "s";
    }
    if (dif > 60) {
        dif = dif / 60;
    } else {
        return Math.floor(dif) + "m";
    }
    if (dif > 24) {
        dif = dif / 24;
    } else {
        return Math.floor(dif) + "h";
    }
    if (dif > 7) {
        dif = dif / 7
    } else {
        return Math.floor(dif) + "d";
    }
    if (dif > 52) {
        dif = dif / 52
    } else {
        return Math.floor(dif) + "w";
    }

    return Math.floor(dif) + "y";
}