import React from "react";
import findDateDifference from "../misc/find_date_difference";
import dict from "../misc/translations";
import MODAL from "../modal/modal";
import toggle_modal from "../modal/toggle_modal";
import * as Icon from "react-bootstrap-icons";
import deleteEndorsement from "../../constants/delete_skill/deleteEndorsement";
import reportEndorsement from "../../constants/delete_skill/reportEndorsement";
import account_audio from "../account/account_audio";
import play_audio from "../misc/play_audio";
require("datejs");

class ENDORSEMENTS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    async removeEndorsement(id) {
        await deleteEndorsement(id);
        toggle_modal(id + "_menu");
        this.props.refresh();
    }

    async reportEndorsement(id) {
        await reportEndorsement(id);
        toggle_modal(id + "_menu");
        toggle_modal(id + "_endorsement_report");
    }

    render() {
        let spoken = localStorage.getItem("preferred_spoken_language") || "English";
        let language = this.props.language;
        let e = this.props.e;

        let thanks = this.props.audio["thanks"][spoken];

        return (
            <span>
                <div className="endorsement" onClick={() => toggle_modal(e.id + "_menu")}>
                    <div className="user">{e.user} • {findDateDifference(e.updatedAt)}</div>
                    <div className="review">{e.review}</div>
                </div>
                <MODAL id={e.id + "_menu"}>
                    <button className="primary" onClick={() => {
                        this.props.visitUser(e.user);
                        document.getElementById(e.id + "_menu").style.display = "none";
                        this.props.build();
                    }}><div className="button_icon"><Icon.Person /></div>{dict["See user"][this.props.language]}</button>

                    <br />
                    {e.user === this.props.currentUser ?
                        <button className="danger" onClick={() => this.removeEndorsement(e.id)}><div className="button_icon"><Icon.FolderX /></div>{dict["Delete"][language]}</button>
                        : null}
                    {e.user !== this.props.currentUser ?
                        <button className="secondary" onClick={() => this.reportEndorsement(e.id)}><div className="button_icon"><Icon.ExclamationCircle /></div>{dict["Report"][language]}</button>
                        : null}
                </MODAL>
                <MODAL id={e.id + "_endorsement_report"}>
                    <div className="heading">{dict["Thank you"][language]}!</div>
                    <div className="audio" onClick={() => play_audio(thanks)}><Icon.VolumeUp /></div>
                    <div className="audio_br"/>
                    <div className="small_text">{dict["One of our administrators will review your report"][language]}</div>
                    <br /><br />
                    <button className="primary" onClick={() => toggle_modal(e.id + "_endorsement_report")}><div className="button_icon"><Icon.Check2Circle /></div>{dict["Ok"][language]}</button>
                </MODAL>
            </span>
        );
    }
}

export default ENDORSEMENTS;