import Storage from "@aws-amplify/storage";

export default async function feed_audio() {
    return ({
        "reason": {
            "English": new Audio(await Storage.get("audio/news_feed/English/reportPostReason.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "sexual_content": {
            "English": new Audio(await Storage.get("audio/news_feed/English/sexualContent.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "violence": {
            "English": new Audio(await Storage.get("audio/news_feed/English/violence.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "hate_speech": {
            "English": new Audio(await Storage.get("audio/news_feed/English/hateSpeech.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "self_injury": {
            "English": new Audio(await Storage.get("audio/news_feed/English/selfInjury.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "other": {
            "English": new Audio(await Storage.get("audio/news_feed/English/other.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "thanks": {
            "English": new Audio(await Storage.get("audio/thanks/English/review.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },
    })
}