import React from "react";
import emoji from "./emoji";
import dict from "./translations";

class ADD_SKILL_SELECT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            skill: this.props.skill
        }
    }

    selectSkill(skill) {
        this.setState({
            skill: skill
        }, () => {
            this.accordion();
            this.props.function(this.state.skill);
        });
    }

    accordion() {
        let acc = document.getElementById("skill_dropdown");
        if(acc) {
            if(acc.style.maxHeight === "0px"){
                acc.style.maxHeight = acc.scrollHeight + "px";
                acc.style.borderTop = "1px solid #c4c4c4";
                acc.style.borderBottom = "1px solid #cba42d";
            } else {
                acc.style.maxHeight = "0px";
                acc.style.borderTop = "";
                acc.style.borderBottom = "";
            }
        }
    }

    hasSkill(skill) {
        return this.props.skills.some(e => e.skill === skill);
    }

    render() {
        let icon = emoji[this.state.skill];

        return (
            <div>
                <div className="skill_select">
                    <div onClick={() => this.accordion()} className="placeholder">{this.state.skill ? <div className="selected">{icon} {this.state.skill[0].toUpperCase() + this.state.skill.slice(1)}</div> : dict["Select an option"][this.props.language] + "..." /*dict["Preferred language"][this.props.language]*/}<div className="arrow_down"></div></div>
                    <div id="skill_dropdown" style={{maxHeight: "0px", marginTop: "-1px"}} className="dropdown">
                        
                        <div className="sub_heading">{dict["Home skills"][this.props.language]}</div>
                        <div style={{display: this.hasSkill("cleaning") ? "none" : ""}} onClick={() => this.selectSkill("cleaning")} className="item">{emoji["cleaning"]} {dict["Cleaning"][this.props.language]}</div>
                        <div style={{display: this.hasSkill("cooking") ? "none" : ""}} onClick={() => this.selectSkill("cooking")} className="item">{emoji["cooking"]} {dict["Cooking"][this.props.language]}</div>
                        <div style={{display: this.hasSkill("baking") ? "none" : ""}} onClick={() => this.selectSkill("baking")} className="item">{emoji["baking"]} {dict["Baking"][this.props.language]}</div>
                        <div style={{display: this.hasSkill("sewing") ? "none" : ""}} onClick={() => this.selectSkill("sewing")} className="item">{emoji["sewing"]} {dict["Sewing"][this.props.language]}</div>
                        <div style={{display: this.hasSkill("childcare") ? "none" : ""}} onClick={() => this.selectSkill("childcare")} className="item">{emoji["childcare"]} {dict["Childcare"][this.props.language]}</div>

                        <div className="sub_heading">{dict["Trade skills"][this.props.language]}</div>
                        <div style={{display: this.hasSkill("carpentry") ? "none" : ""}} onClick={() => this.selectSkill("carpentry")} className="item">{emoji["carpentry"]} {dict["Carpentry"][this.props.language]}</div>
                        <div style={{display: this.hasSkill("electrician") ? "none" : ""}} onClick={() => this.selectSkill("electrician")} className="item">{emoji["electrician"]} {dict["Electrician"][this.props.language]}</div>
                        <div style={{display: this.hasSkill("plumbing") ? "none" : ""}} onClick={() => this.selectSkill("plumbing")} className="item">{emoji["plumbing"]} {dict["Plumbing"][this.props.language]}</div>
                        <div style={{display: this.hasSkill("painting") ? "none" : ""}} onClick={() => this.selectSkill("painting")} className="item">{emoji["painting"]} {dict["Painting"][this.props.language]}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ADD_SKILL_SELECT;