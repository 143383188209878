export default function find_country_code(number) {
    let reg = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)$/;
    let cc = "";
    if(reg.test(number[0] + number[1])){
        cc = number[0] + number[1];
    } else if(reg.test(number[0] + number[1] + number[2])) {
        cc = number[0] + number[1] + number[2];
    } else if(reg.test(number[0] + number[1] + number[2] + number[3])) {
        cc = number[0] + number[1] + number[2] + number[3];
    }
    return cc;
}