import React from "react";
import dict from "../misc/translations";
import UPDATE_MODAL from "./update_modal";
import * as Icon from 'react-bootstrap-icons';
import { Analytics, API, Storage } from "aws-amplify";
import POSITION_TILE from "./position_tile";
import POST_TILE from "./post_tile";
import MODAL_BUTTON from "../modal/modal_button";
import MODAL from "../modal/modal";
import Followers from "../user/followers";
import Following from "../user/following";
import Endorsements from "../user/endorsements";
import { createSkill } from "../../graphql/mutations";
import Skills from "../user/skills";
import getUserSkills from "../../constants/get_skills/getUserSkills";
import ADD_SKILL_SELECT from "../misc/add_skill_select";
import toggle_modal from "../modal/toggle_modal";
import play_audio from "../misc/play_audio";
import hide_audio from "../settings/hide_audio";
import LOADING from "../loading/loading";

class ACCOUNT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            positionData: this.props.positionData,
            positions: [],
            postData: this.props.postData,
            posts: [],
            e: this.props.e,
            endorsements: [],
            skills: this.props.skills,
            skills_arr: [],
            skill_select: "",
            skill_write: "",
            error: null,
            bio: this.props.bio,
            audio: null
        };
    }

    async componentDidMount() {
        this.generatePositions(this.state.positionData, "positions");
        this.generatePositions(this.state.postData, "posts");
        this.generateSkills(this.state.skills);
        this.createEndorsements(this.state.e);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.positionData !== state.positionData
            || props.postData !== state.postData
            || props.followers !== state.followers
            || props.following !== state.following
            || props.e !== state.e
            || props.skills !== state.skills
            || props.bio !== state.bio
            || props.audio !== state.audio) {
            return {
                positionData: props.positionData,
                postData: props.postData,
                followers: props.followers,
                following: props.following,
                e: props.e,
                skills: props.skills,
                bio: props.bio,
                audio: props.audio
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.positionData !== this.props.positionData
            || props.postData !== this.props.postData
            || props.followers !== this.props.followers
            || props.following !== this.props.following
            || props.e !== this.state.e
            || props.skills !== this.state.skills
            || props.bio !== this.state.bio
            || props.audio !== this.state.audio) {
            this.setState({
                positionData: this.props.positionData,
                postData: this.props.postData,
                followers: this.props.followers,
                following: this.props.following,
                e: this.props.e,
                skills: this.props.skills,
                bio: this.props.bio,
                audio: this.props.audio
            });
            this.componentDidMount();
        }
    }

    async generatePositions(data, type) {
        let pos_arr = [];

        for (let i = 0, size = data.length; i < size; i++) {
            let n = data[i];
            let language = this.props.language;
            if (language === "Bahasa Malaysia") {
                language = "Malay";
            }
            language = language.toLowerCase();
            let d = "";

            if (n[language] !== null) {
                d = n[language];
            } else if (n.english !== null) {
                d = n.english;
            } else if (n.rohingyalish !== null) {
                d = n.rohingyalish;
            } else if (n.malay !== null) {
                d = n.malay;
            }

            let src = n.src;
            if (!n.linked) {
                src = await Storage.get(n.src);
            }

            let pos = null;
            if (type === "positions") {
                pos = <POSITION_TILE verified={n.verified} title={n.title} location={n.location} description={d} />;
            } else {
                pos = <POST_TILE i={i} updatePost={this.props.refreshPosts} english={n.english} rohingyalish={n.rohingyalish} malay={n.malay} burmese={n.burmese} removePost={this.props.removePost} language={this.props.language} file={n.src} refreshAccount={this.props.refreshAccount} refreshPosts={this.props.refreshPosts} published={n.published} id={n.id} createdAt={n.createdAt} user={n.user} src={src} description={d} />
            }

            pos_arr.push(pos);
        }

        this.setState({
            [type]: pos_arr
        });
    }

    async generateSkills(s) {
        let s_arr = [];

        for (let i = 0, size = s.length; i < size; i++) {
            let sk = <Skills audio={this.state.audio} visitUser={this.props.visitUser} refresh={this.props.refreshSkills} data={s[i]} language={this.props.language} user={this.props.user} currentUser={this.props.username} />;
            s_arr.push(sk);
        }

        this.setState({
            skills_arr: s_arr
        });
    }

    async createEndorsements(e) {
        let e_arr = [];

        for (let i = 0, size = e.length; i < size; i++) {
            let en = <Endorsements e={e[i]} />;
            e_arr.push(en);
        }

        this.setState({
            endorsements: e_arr
        });
    }

    async addSkill() {
        let skill = this.state.skill_select;
        if (this.state.skill_write !== "") {
            skill = this.state.skill_write;
        }

        let res = await getUserSkills(this.props.username);
        res = res.filter(e => e.skill === skill);

        if (res.length === 0) {
            let param = {
                user: this.props.username,
                skill: skill.toLowerCase()
            };

            await API.graphql({ query: createSkill, variables: { input: param } });
            Analytics.record({ name: "add_skill", attributes: { skill: param.skill } });
            this.props.refreshSkills();
            toggle_modal("add_skill");
            // document.getElementById("skill_select").selectedIndex = null;
            this.setState({
                skill_select: "",
                skill_write: ""
            });
        } else {
            this.setState({
                error: "You already have " + skill + " as a skill"
            });
        }
    }

    hasSkill(skill) {
        return this.state.skills.some(e => e.skill === skill);
    }

    updateBio(newBio) {
        this.setState({
            bio: newBio
        });
    }

    render() {
        if (this.state.audio !== null) {
            let spoken = localStorage.getItem("preferred_spoken_language") || "English";

            let your_skills = this.state.audio["your_skills"][spoken];
            let your_posts = this.state.audio["your_posts"][spoken];
            let add_skill = this.state.audio["add_skill"][spoken];

            hide_audio();

            return (
                <div className="account">
                    <div className="user_pic">
                        <Icon.PersonCircle />
                    </div>

                    <div className="section" id="username">
                        <div className="heading">
                            {this.props.username[0].toUpperCase() + this.props.username.slice(1)}
                        </div>
                        <div className="content">
                            {this.props.number}
                        </div>
                    </div>

                    <div className="follower_wrap">
                        <div style={{ marginBottom: "20px" }}>
                            <MODAL_BUTTON id={this.props.username + "_followers"}>
                                <div className="follower_set left"><div style={{ display: "inline-block", verticalAlign: "middle", fontSize: "27px", marginRight: "10px" }}><Icon.People /></div>{dict["Followers"][this.props.language]}</div>
                            </MODAL_BUTTON>
                            <MODAL_BUTTON id={this.props.username + "_following"}>
                                <div className="follower_set right">{dict["Following"][this.props.language]}<div style={{ display: "inline-block", verticalAlign: "middle", fontSize: "27px", marginLeft: "10px" }}><Icon.PersonCheck /></div></div>
                            </MODAL_BUTTON>
                            <MODAL_BUTTON id={this.props.username + "_followers"}>
                                <div className="follower_set left">{this.props.followers?.length}</div>
                            </MODAL_BUTTON>
                            <MODAL_BUTTON id={this.props.username + "_following"}>
                                <div className="follower_set right">{this.props.following?.length}</div>
                            </MODAL_BUTTON>
                        </div>

                        <MODAL id={this.props.username + "_followers"}>
                            <Followers audio={this.state.audio} language={this.props.language} id={this.props.username + "_followers"} link={this.props.visitUser} followers={this.props.followers} />
                        </MODAL>

                        <MODAL id={this.props.username + "_following"}>
                            <Following audio={this.state.audio} language={this.props.language} id={this.props.username + "_following"} link={this.props.visitUser} following={this.props.following} />
                        </MODAL>
                    </div>

                    <div className="section" id="bio">
                        <div className="content">
                            {this.state.bio}
                        </div>
                    </div>

                    <div className="section" id="skills">
                        <div className="content audio_text">{dict["Your skills"][this.props.language]}</div>
                        <div style={{ color: "#5a5a5a", borderColor: "#5a5a5a" }} className="audio" onClick={() => play_audio(your_skills)}><Icon.VolumeUp /></div>

                        <div className="skill_wrap">
                            {this.state.skills_arr}
                        </div>

                        <MODAL_BUTTON id="add_skill">
                            <button className="secondary full">
                                <div className="button_icon"><Icon.PlusCircle /></div>{dict["Add skill"][this.props.language]}
                            </button>
                        </MODAL_BUTTON>
                        <MODAL id="add_skill">
                            <div>{dict["What skill would you like to add"][this.props.language]}?</div>
                            <br />
                            <div className="audio" onClick={() => play_audio(add_skill)}><Icon.VolumeUp /></div>
                            <div className="audio_br"></div>
                            <div>{dict["Select one"][this.props.language]}...</div>
                            <ADD_SKILL_SELECT audio={this.state.audio} language={this.props.language} skills={this.state.skills} function={e => this.setState({ skill_select: e, error: null })} />
                            
                        
                            <div style={{ marginTop: "10px" }}>...{dict["or write your own"][this.props.language]}</div>
                            <input onChange={e => this.setState({ skill_write: e.currentTarget.value, error: null })} type="text" placeholder={dict["Skill"][this.props.language] + "..."}></input>
                            <br /><span className="error">{this.state.error}</span><br />
                            <button disabled={this.state.skill_select === "" && this.state.skill_write === ""} onClick={() => this.addSkill()} className="primary"><div className="button_icon"><Icon.PlusCircle /></div>{dict["Add skill"][this.props.language]}</button>
                            <hr />
                            <button onClick={() => toggle_modal("add_skill")} className="secondary"><div className="button_icon"><Icon.XCircle /></div>{dict["Cancel"][this.props.language]}</button>
                        </MODAL>
                    </div>

                    <div className="section">
                        {this.props.number ? <UPDATE_MODAL audio={this.state.audio} updateBio={(e) => this.updateBio(e)} id="update_modal" number={this.props.number} name={this.props.name} bio={this.props.bio} language={this.props.language} /> : null}
                    </div>

                    <div className="tiles" style={{ display: this.state.posts.length > 0 ? "block" : "none" }}>
                        <div className="tiles_heading audio_text">{dict["Your videos"][this.props.language]}</div>
                        <div style={{ color: "#5a5a5a", borderColor: "#5a5a5a" }} className="audio" onClick={() => play_audio(your_posts)}><Icon.VolumeUp /></div>
                        {this.state.posts}
                    </div>

                    {/* <div className="tiles" style={{ display: this.state.positions.length > 0 ? "block" : "none" }}>
                    <div className="tiles_heading">{dict["Your positions"][this.props.language]}:</div>
                    {this.state.positions}
                </div> */}
                </div>
            );
        } else {
            return <LOADING />;
        }
    }
}

export default ACCOUNT;