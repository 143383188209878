import Storage from "@aws-amplify/storage";

export default async function saved_audio() {
    return ({
        "no_saved_posts": {
            "English": new Audio(await Storage.get("audio/saved/English/noSavedVideos.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },
    })
}