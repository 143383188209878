import React from "react";
import MODAL from "../modal/modal";
import * as Icon from "react-bootstrap-icons";
import toggle_modal from "../modal/toggle_modal";
import uk from "../../img/uk-icon.png";
import malaysia from "../../img/malaysia-icon.png";
import rohingya from "../../img/rohingyalish-icon.png";
import burma from "../../img/burma-icon.png";
import dict from "../misc/translations";

class Chrome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            language: "English"
        };
    }

    async install() {
        this.props.deferredPrompt.prompt();
        const { outcome } = await this.props.deferredPrompt.userChoice;
        
        toggle_modal("chrome_prompt_modal");
    }

    showAgain() {
        if(this.state.show) {
            localStorage.setItem("install_do_not_show", true);
        }
        toggle_modal("chrome_prompt_modal");
    }

    render() {
        let language = this.state.language;
        return (
            <MODAL id="chrome_prompt_modal" onModalClose={() => this.showAgain()} auto={localStorage.getItem("install_do_not_show") ? false : true}>
                <div className="auth_languages">
                    <img alt="English" onClick={() => this.setState({ language: "English" })} style={{ border: this.state.language === "English" ? "2px solid #cba42d" : "" }} className="language_icon" src={uk} />
                    <img alt="Bahasa Malaysia" onClick={() => this.setState({ language: "Bahasa Malaysia" })} style={{ border: this.state.language === "Bahasa Malaysia" ? "2px solid #cba42d" : "" }} className="language_icon" src={malaysia} />
                    <img alt="Rohingyalish" onClick={() => this.setState({ language: "Rohingyalish" })} style={{ border: this.state.language === "Rohingyalish" ? "2px solid #cba42d" : "" }} className="language_icon" src={rohingya} />
                    <img alt="Burmese" onClick={() => this.setState({ language: "Burmese" })} style={{ border: this.state.language === "Burmese" ? "2px solid #cba42d" : "" }} className="language_icon" src={burma} />
                </div>
                <div className="install_icon"><Icon.BoxArrowInDown /></div>
                <div className="heading">{dict["Install TalentEd"][language]}?</div>
                <div className="install_reason">
                    {dict["Install this application on your home screen for quick and easy access"][language]}
                </div>
                <input onChange={() => this.setState({ show: !this.state.show })} className="checkbox" type="checkbox"></input>
                <label>{dict["Don't show this again"][language]}</label>
                <br/>
                <button onClick={() => this.install()} className="primary"><div className="button_icon"><Icon.BoxArrowInDown /></div>{dict["Install"][language]}</button>
                {/* <hr />
                <button onClick={() => toggle_modal("chrome_prompt_modal")} className="secondary"><div className="button_icon"><Icon.XCircle /></div>No Thanks</button> */}
            </MODAL>
        );
    }
}

export default Chrome;