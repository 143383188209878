import React from "react";
import MODAL from "../modal/modal";
import * as Icon from "react-bootstrap-icons";
import play_audio from "../misc/play_audio";

class COOKIE_POLICY extends React.Component {
    render() {
        let language = localStorage.getItem("preferred_spoken_language") || "English";
        let heading = this.props.audio["cookie"]["title"][language];
        let one = this.props.audio["cookie"]["what"][language];
        let two = this.props.audio["cookie"]["use"][language];
        let three = this.props.audio["cookie"]["how"][language];
        let four = this.props.audio["contact"][language];

        return (
            <MODAL id="cookie_policy">
                <div className="policy">
                    <div className="policy_heading">
                        Cookie Policy
                        <div className="audio" onClick={() => play_audio(heading)}><Icon.VolumeUp /></div>
                    </div>
                    <div className="policy_content">
                        <div className="policy_sub_heading">
                            <div className="audio_text">Meaning</div>
                            <div className="audio" onClick={() => play_audio(one)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            A cookie is a small piece of information stored by an internet browser while browsing a website.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Uses</div>
                            <div className="audio" onClick={() => play_audio(two)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            We use cookies to allow for automatic sign-in and to remember your preferences. We <strong>do not</strong> use cookies for advertising, to store your personal data, and we do not share data with any third parties.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Delete cookies</div>
                            <div className="audio" onClick={() => play_audio(three)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            To delete cookies, clear your browser cache.
                        </div>

                        <div className="policy_sub_heading">
                            <div className="audio_text">Contact</div>
                            <div className="audio" onClick={() => play_audio(four)}><Icon.VolumeUp /></div>
                        </div>
                        <div className="policy_normal">
                            If you have any questions about the terms, please contact us at <a href="mailto:rmd5@hw.ac.uk">rmd5@hw.ac.uk</a>.
                        </div>
                    </div>
                    {/* <button className="primary full" onClick={() => window.location.href = "/"}>
                    <div className="button_icon"><Icon.ArrowLeft /></div>Back
                </button> */}
                </div>
            </MODAL>
        );
    }
}

export default COOKIE_POLICY;