import React, { Component } from 'react';
import { options } from '../video/options';
import * as Icon from "react-bootstrap-icons";
import dict from '../misc/translations';
import play_audio from '../misc/play_audio';
import Videojs from "../video/video_player";

class HELP_SECTION extends Component {
    componentDidMount() {

    }

    render() {
        let language = this.props.language;
        let id = this.props.title.replace(" ", "_").toLowerCase();
        
        return (
            <div id={id} className="help_section">
                <div className="heading">
                    <div className="audio_text">
                        <div className="help_icon">{this.props.icon}</div>
                        {dict[this.props.title][language]}</div>
                    <div style={{ color: "#e9e9e9" }} className="audio" onClick={() => play_audio(this.props.audio)}><Icon.VolumeUp /></div>
                </div>
                <div className="help_video">
                    <div className="phone_line"></div>
                    <div className="phone_dot"></div>
                    <Videojs {...options} sources={[{ src: this.props.video, type: 'video/mp4' }]} />
                    {/* <div className="phone_button"></div> */}
                </div>
                <div className="help_description">
                    {dict[this.props.description][language]}
                </div>
            </div>
        );
    }
}

export default HELP_SECTION;