import React from "react";
import * as Icon from "react-bootstrap-icons";

class Lost extends React.Component {
    render() {
        return (
            <div className="no_wifi_wrap">
                <div className="encompass">
                    <div className="body_wrap">
                        <div className="body">
                            <div className="no_wifi">
                                <div className="no_wifi_heading">
                                    Oh dear, you appear to be lost
                                </div>
                                <br/>
                                <div className="no_wifi_icon">
                                    <Icon.QuestionCircle />
                                </div>
                                <button className="primary" onClick={() => window.location.href = "/"}>
                                    Go back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Lost;