import React from "react";
import { Auth } from 'aws-amplify';
import * as Icon from "react-bootstrap-icons";
import dict from "../misc/translations";

class REQUEST_RESET extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            loading: false,
            error: null
        };
    }

    reset = () => {
        // Send confirmation code to user's email
        Auth.forgotPassword(this.state.username)
            .then(data => {
                console.log(data);
                this.props.change_password();
                this.props.username(this.state.username);
            })
            .catch(err => {
                console.log(err);
                let error = "User does not exist.";
                if(err.message === "Attempt limit exceeded, please try after some time."){
                    error = "Too many attempts, try again later.";
                }

                this.setState({
                    error: error
                });
            });
    }

    updateState(e, state) {
        this.setState({
            [state]: e.currentTarget.value,
            error: null
        });
    }

    render() {
        return (
            <div className="login-form">
                {/* <LOADING show={this.state.loading}/> */}
                <input type="text" value={this.state.username} placeholder={dict["Username"][this.props.language]} onChange={(e) => this.updateState(e, "username")}></input><br />
                <div className="error">{this.state.error}</div>
                <button className="primary" onClick={this.reset}><div className="button_icon"><Icon.ChatRightDots /></div>{dict["Send code"][this.props.language]}</button>

                <hr />

                <button onClick={this.props.login} className="secondary"><div className="button_icon"><Icon.BoxArrowInRight /></div>{dict["Back to login"][this.props.language]}</button><br />
            </div>
        );
    }
}

export default REQUEST_RESET;