import React from "react";
import MODAL from "../../components/modal/modal";
import MODAL_BUTTON from "../../components/modal/modal_button";
import updateUser from "../../constants/user/updateUser";
import find_country_code from "../misc/find_country_code";
import dict from "../misc/translations";
import * as Icon from "react-bootstrap-icons";
import play_audio from "../misc/play_audio";
import hide_audio from "../settings/hide_audio";

class UPDATE_MODAL extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newName: "",
            currentNumber: "",
            newNumber: "",
            country_code: "",
            bio: "",
            language: "",
        };
    }

    componentDidMount() {
        let number = this.props.number;
        let cc = find_country_code(number);
        this.setState({
            country_code: cc,
            currentNumber: number.replace(cc, "")
        });
    }

    closeModal(id) {
        let modal = document.getElementById(id);
        if (modal) {
            modal.style.display = "none";
        }
    }

    async update() {
        try {
            this.closeModal(this.props.id);
            this.props.updateBio(this.state.bio === "" ? this.props.bio : this.state.bio);
            let number = this.state.newNumber;
            if (number === "") {
                number = this.state.currentNumber;
            }
            
            await updateUser(
                this.state.newName === "" ? this.props.name : this.state.newName,
                this.state.country_code + number,
                this.state.bio === "" ? this.props.bio : this.state.bio,
                this.state.language === "" ? this.props.language : this.state.language
            );
        } catch (err) {
            window.location.href = "/error";
        }
    }

    updateVerify(e) {
        this.setState({
            verify: e.currentTarget.value
        });
    }

    updateState(e, state) {
        let value = e.currentTarget.value.replace(/^0+/, '');

        this.setState({
            [state]: value
        });
    }

    getLanguage(language) {
        this.setState({
            language: language
        });
    }

    render() {
        let spoken = localStorage.getItem("preferred_spoken_language") || "English";
        let update_account = this.props.audio["update_account"][spoken];

        hide_audio();
        
        return (
            <div>
                <MODAL_BUTTON id={this.props.id}><button className="primary full"><div className="button_icon"><Icon.ArrowRepeat /></div>{dict["Update account"][this.props.language]}</button></MODAL_BUTTON>
                <MODAL id={this.props.id}>
                    <div className="heading">{dict["Update account"][this.props.language]}?</div>
                    <div className="audio" onClick={() => play_audio(update_account)}><Icon.VolumeUp /></div>
                    <div className="audio_br"></div>
                    <div className="small_text">{dict["Please enter your new details"][this.props.language]}</div><br/>
                    {/* <input onChange={(e) => this.updateState(e, "newName")} value={this.state.newName} type="text" placeholder={this.props.name}></input><br /> */}
                    {/* <LANGUAGE_SELECT language={this.props.language} function={this.getLanguage.bind(this)} /> */}
                    <textarea value={this.state.bio} onChange={(e) => this.updateState(e, "bio")} placeholder={dict["About you"][this.props.language] + "..."}></textarea><br />
                    {/* <div style={{height: "65px"}}>
                        <Country_Codes value={this.state.country_code} function={(e) => this.updateState(e, "country_code")} />
                        <input className="phone_input" type="number" value={this.state.newNumber} placeholder={this.state.currentNumber} onChange={(e) => this.updateState(e, "newNumber")}></input><br />
                    </div> */}
                    <button disabled={this.state.newName === "" && this.state.bio === "" && this.state.language === "" && this.state.newNumber === "" ? "disabled" : ""} className="primary" onClick={() => this.update()}><div className="button_icon"><Icon.ArrowRepeat /></div>{dict["Update"][this.props.language]}</button>
                    <hr />
                    <button className="secondary" onClick={() => this.closeModal(this.props.id)}><div className="button_icon"><Icon.XCircle /></div>{dict["Cancel"][this.props.language]}</button>
                </MODAL>
            </div>
        );
    }
}

export default UPDATE_MODAL;