import { Storage } from "aws-amplify";
import React from "react";
import LOADING from "../loading/loading";
import Post from "./post";

class POST_LINK extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            post: null,
            audio: this.props.audio
        };
    }

    async componentDidMount() {
        if (this.state.audio !== null) {
            let id = this.props.post;
            let n = this.props.posts.find(e => e.id === id);
            let language = this.props.language;
            console.log(n);
            if (language === "Bahasa Malaysia") {
                language = "Malay";
            }
            language = language.toLowerCase();
            let d = "";

            if (n[language] !== null) {
                d = n[language];
            } else if (n.english !== null) {
                d = n.english;
            } else if (n.rohingyalish !== null) {
                d = n.rohingyalish;
            } else if (n.malay !== null) {
                d = n.malay;
            }

            let src = n.src;
            if (!n.linked) {
                src = await Storage.get(n.src);
            }

            let post = <Post audio={this.state.audio} language={this.props.language} saves={n.saves} comments={n.comments} currentUser={this.props.currentUser} visitUser={this.props.visitUser} refreshPosts={this.props.refreshPosts} post={n} likes={n.likes.items} id={n.id} date={n.createdAt} user={n.user} src={src} file={n.src} description={d} />

            this.setState({
                post: post
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.audio !== state.audio) {
            return {
                audio: props.audio
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.audio !== this.props.audio) {
            this.setState({
                audio: this.props.audio
            }, () => {
                this.componentDidMount();
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.post ? this.state.post : <LOADING />}
            </div>
        );
    }
}

export default POST_LINK;