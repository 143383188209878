import React from "react";
import dict from "../misc/translations";
import * as Icon from "react-bootstrap-icons";
import play_audio from "../misc/play_audio";

class FOLLOWING extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            log: []
        }
    }

    componentDidMount() {
        let following = this.props.following;
        this.sort(following);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.following !== state.following) {
            return {
                following: props.following
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.following !== this.props.following) {
            this.componentDidMount();
        }
    }

    sort(f) {
        let log = [];

        for (let i = 0, size = f?.length; i < size; i++) {
            let display = <div className="follower" onClick={() => {
                // window.location.href = "/" + f[i].follows;
                this.props.link(f[i].follows);
                document.getElementById(this.props.id).style.display = "none";
                this.props.build();
            }}>
                <div className="username">{f[i].follows[0].toUpperCase() + f[i].follows.slice(1)}</div>
                <button className="primary"><div className="button_icon"><Icon.Person /></div>{dict["See user"][this.props.language]}</button>
            </div>

            log.push(display);
        }

        this.setState({
            log: log
        });
    }

    render() {
        let spoken = localStorage.getItem("preferred_spoken_language") || "English";
        let not_following = this.props.audio["not_following"][spoken];
        return (
            <div style={{ width: "300px", display: "inline-block", maxWidth: "calc(100% - 20px)" }}>
                {this.state.log.length > 0 ? this.state.log : <div><i>{dict["This user isn't following anyone yet"][this.props.language]}</i><br /><br /><div className="audio" onClick={() => play_audio(not_following)}><Icon.VolumeUp /></div><div className="audio_br"></div></div>}
            </div>
        );
    }
}

export default FOLLOWING;