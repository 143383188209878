import React from "react";
import * as Icon from "react-bootstrap-icons";
import dict from "../misc/translations";

class COMING_SOON extends React.Component {
    render() {
        return (
            <div className="construction">
                <div className="construction_icon"><Icon.ConeStriped /></div>
                <div className="content">{dict["Under construction"][this.props.language]}</div>
            </div>
        );
    }
}

export default COMING_SOON;