import React from "react";
import MODAL from "../modal/modal";
import MODAL_BUTTON from "../modal/modal_button";
import * as Icon from "react-bootstrap-icons";
import dict from "../misc/translations";
require('datejs');

class POSITION extends React.Component {
    render() {
        return (
            <div className="position">
                <div className="title">
                    {this.props.title}
                </div>
                <div className="location">
                    {this.props.user} - {Date.parse(this.props.date).toString("MMMM dS, yyyy")}
                </div>
                <div style={{ display: this.props.src ? "block" : "none" }} className="audio_wrap">
                    <audio controls controlsList="nodownload">
                        <source src={this.props.src}></source>
                    </audio>
                </div>
                <div className="description">
                    <div className="user">
                        {this.props.location}
                    </div>
                    &nbsp; • &nbsp;{this.props.description}
                </div>
                <div className="bubble_icon">
                    <MODAL_BUTTON id={this.props.id}>
                        <Icon.ThreeDotsVertical />
                    </MODAL_BUTTON>
                </div>
                <MODAL id={this.props.id}>
                    {this.props.id}
                </MODAL>

                <div className="apply">
                    <div className="button_icon"><Icon.DoorOpen /></div>{dict["Apply"][this.props.language]}
                </div>
            </div>
        );
    }
}

export default POSITION;