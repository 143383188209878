import React from "react";
import { Analytics, Auth } from 'aws-amplify';
import dict from "../misc/translations";
import * as Icon from 'react-bootstrap-icons';
import Amplify from 'aws-amplify';
import config from '../../aws-exports';
Amplify.configure(config);

class LOGOUT extends React.Component {

    logout = async() => {
        try {
            Analytics.record({name: "logout"});
            await Auth.signOut();
            window.location.href = "/";
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    render() {
        return (
            <div className="item">
                <div onClick={this.logout}><div className="menu_icon"><Icon.BoxArrowLeft /></div>{dict["Logout"][this.props.language]}</div>
            </div>
        );
    }
}

export default LOGOUT;