import config from '../config';

export default async function getAllUsers(props) {
    let AWS = require('aws-sdk');

    var params = {
        UserPoolId: process.env.REACT_APP_USER_POOL,
        AttributesToGet: [
            "name",
            "phone_number"
        ]
    };

    AWS.config.update(config);

    var cog = new AWS.CognitoIdentityServiceProvider();

    cog.listUsers(params, function (err, data) {
        if (err) console.log(err); // an error occurred
        else props(data.Users);           // successful response
    });
}