import { Storage } from "aws-amplify";
import React from "react";
import POST from "../feed/post";
import dict from "../misc/translations";
import Position from "../positions/post";
import * as Icon from "react-bootstrap-icons";
import play_audio from "../misc/play_audio";
import hide_audio from "../settings/hide_audio";
import LOADING from "../loading/loading";

class SAVED extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            saved: [],
            posts: [],
            audio: this.props.audio
        };
    }

    async componentDidMount() {
        let posts = this.props.posts.filter(e => e.saves.items.find(e2 => e2.user === this.props.currentUser));

        await this.generate(posts, "posts");
    }

    static getDerivedStateFromProps(props, state) {
        if (props.posts !== state.data
            || props.audio !== state.audio) {
            return {
                data: props.posts,
                audio: props.audio
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.posts !== this.props.posts
            || props.audio !== this.props.audio) {
            this.setState({
                data: this.props.posts,
                audio: this.props.audio
            });
            this.componentDidMount();
        }
    }

    async generate(data, type) {
        let pos_arr = [];

        for (let i = 0, size = data.length; i < size; i++) {
            let n = data[i];
            let language = this.props.language;
            if (language === "Bahasa Malaysia") {
                language = "Malay";
            }
            language = language.toLowerCase();
            let d = "";

            if (n[language] !== null) {
                d = n[language];
            } else if (n.english !== null) {
                d = n.english;
            } else if (n.rohingyalish !== null) {
                d = n.rohingyalish;
            } else if (n.malay !== null) {
                d = n.malay;
            }

            let src = null;
            if (n.src !== null) {
                src = n.src;
                if (!n.linked) {
                    src = await Storage.get(n.src);
                }
            }

            let pos = null;
            if (type === "positions") {
                pos = <Position src={src} id={n.id} date={n.createdAt} user={n.user} title={n.title} location={n.location} description={d} />;
            } else {
                pos = <POST
                    visitUser={this.props.visitUser}
                    language={this.props.language}
                    refreshPosts={this.props.refreshPosts}
                    saves={n.saves}
                    comments={n.comments}
                    currentUser={this.props.currentUser}
                    post={n}
                    likes={n.likes.items}
                    id={n.id}
                    date={n.createdAt}
                    user={n.user}
                    src={src}
                    file={n.src}
                    description={d}
                    audio={this.props.feed_audio} />;
            }

            pos_arr.push(pos);
        }

        this.setState({
            [type]: pos_arr
        });
    }

    render() {
        if (this.state.audio !== null) {
            let spoken = localStorage.getItem("preferred_spoken_language") || "English";
            let no_saved_posts = this.state.audio["no_saved_posts"][spoken];
            hide_audio();
            return (
                <div className="saved">
                    {this.state.posts.length > 0 ?
                        <div>
                            <i>{dict["You have"][this.props.language]} {this.state.posts.length} {this.state.posts.length !== 1 ? dict["saved videos"][this.props.language] : dict["saved video"][this.props.language]}</i>
                            {this.state.posts}
                        </div>
                        :
                        <div>
                            <i>{dict["You don't currently have any saved videos"][this.props.language]}</i>
                            <br /><br />
                            <div style={{ color: "#e9e9e9", display: JSON.parse(localStorage.getItem("hide_audio_icons")) ? "none" : "" }} className="audio" onClick={() => play_audio(no_saved_posts)}><Icon.VolumeUp /></div>
                            <div className="audio_br" style={{ display: JSON.parse(localStorage.getItem("hide_audio_icons")) ? "none" : "" }}></div>
                            <button onClick={this.props.feed} className="primary" style={{ marginTop: "30px" }}><div className="button_icon"><Icon.House /></div>{dict["News feed"][this.props.language]}</button>
                        </div>
                    }
                </div>
            );
        } else {
            return <LOADING />;
        }
    }
}

export default SAVED;