import React from "react";
import * as Icon from 'react-bootstrap-icons';
import dict from "../misc/translations.js";
import { API, Storage } from 'aws-amplify';
import { createPosition as createPositionMutation } from '../../graphql/mutations';
import LOADING from "../../components/loading/loading";
import MODAL from "../modal/modal.js";

class UPLOAD_POSITION extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            location: "",
            file: null,
            english: null,
            englishHold: "",
            malay: null,
            malayHold: "",
            rohingyalish: null,
            rohingyalishHold: "",
            loading: false
        }
    }

    uploadAudio(e) {
        this.setState({
            file: e.currentTarget.files[0]
        });
    }

    cancelPost = () => {
        this.props.cancel();
    }

    accordion(id, id2) {
        let acc = document.getElementById(id);
        let acc_btn = document.getElementById(id2 + "_acc");
        let icon = document.getElementById(id2 + "_acc_icon");
        if (acc && acc_btn) {
            if (acc.style.maxHeight === "0px") {
                acc.style.maxHeight = acc.scrollHeight + "px";
                if (id2 === "english_acc") {
                    acc_btn.innerHTML = "Remove English description";
                } else if (id2 === "rohingyalish_acc") {
                    acc_btn.innerHTML = "Remove Rohingyalish description";
                } else if (id2 === "malay_acc") {
                    acc_btn.innerHTML = "Remove Bahasa Malaysia description";
                }
                icon.classList.remove("fa-plus");
                icon.classList.add("fa-minus");

                this.setState({
                    [id2]: this.state[id2 + "Hold"]
                });
            } else {
                acc.style.maxHeight = "0px";
                if (id2 === "english_acc") {
                    acc_btn.innerHTML = "Add English description";
                } else if (id2 === "rohingyalish_acc") {
                    acc_btn.innerHTML = "Add Rohingyalish description";
                } else if (id2 === "malay_acc") {
                    acc_btn.innerHTML = "Add Bahasa Malaysia description";
                }
                icon.classList.remove("fa-minus");
                icon.classList.add("fa-plus");

                this.setState({
                    [id2]: null
                });
            }
        }
    }

    updateState(e, state) {
        this.setState({
            [state]: e.currentTarget.value,
            [state + "Hold"]: e.currentTarget.value
        });
    }

    updateInfo(e, state) {
        this.setState({
            [state]: e.currentTarget.value
        });
    }

    upload = () => {
        this.setState({
            loading: true
        }, () => {
            this.createPosition();
        });
    }

    async createPosition() {
        let param = {
            user: this.props.user,
            title: this.state.title,
            location: this.state.location,
            phone_number: this.props.number,
            src: this.state.file?.name || null,
            english: this.state.english,
            rohingyalish: this.state.rohingyalish,
            malay: this.state.malay,
            verified: false,
            reported: false,
            reported_reason: null
        };

        let data = await API.graphql({ query: createPositionMutation, variables: { input: param } });
        if(this.state.file !== null){
            await Storage.put(this.state.file.name, this.state.file);
        }
        
        this.setState({
            loading: false
        }, () => {
            let modal = document.getElementById("confirmation_modal");
            if(modal) {
                modal.style.display = "block";
            }
        });
    }

    confirm() {
        this.props.confirm();
    }

    render() {
        let video = <div>
            <label htmlFor="file-upload" className="file_upload">
                <div className="upload_icon"><Icon.Upload /></div> <div className="upload_content" >{dict["Upload audio"][this.props.language]}</div>
            </label>
            <input onChange={(e) => this.uploadAudio(e)} className="hide_html_upload" id="file-upload" type="file" accept=".mp3,audio/*" />
        </div>;
        if (this.state.file) {
            video = <div>
                {/* <Videojs {...options} sources={[{ src: this.state.file, type: 'audio/mp3' }]} /> */}
                <audio controls>
                    <source src={URL.createObjectURL(this.state.file)} type="audio/mpeg"></source>
                </audio>
            </div>;
        }

        return (
            <div className="upload">
                <LOADING show={this.state.loading} />
                <input value={this.state.title} onChange={(e) => this.updateInfo(e, "title")} type="text" placeholder={dict["Position title"][this.props.language]}></input><br/>
                <input value={this.state.location} onChange={(e) => this.updateInfo(e, "location")} type="text" placeholder={dict["Location"][this.props.language]}></input>

                <div className="file_upload_wrap">
                    {video}
                </div>

                <div onClick={() => this.accordion("english_dropdown", "english")} className="description_acc">
                    <i id="english_acc_icon" className="fas fa-plus"></i> <span id="english_acc">{dict["Add English description"][this.props.language]}</span>
                </div>
                <div style={{ maxHeight: "0px" }} id="english_dropdown" className="extend">
                    <textarea onChange={(e) => this.updateState(e, "english")} value={this.state.englishHold} placeholder="English description..."></textarea>
                </div>

                <div onClick={() => this.accordion("rohingyalish_dropdown", "rohingyalish")} className="description_acc">
                    <i id="rohingyalish_acc_icon" className="fas fa-plus"></i> <span id="rohingyalish_acc">{dict["Add Rohingyalish description"][this.props.language]}</span>
                </div>
                <div style={{ maxHeight: "0px" }} id="rohingyalish_dropdown" className="extend">
                    <textarea onChange={(e) => this.updateState(e, "rohingyalish")} value={this.state.rohingyalishHold} placeholder="Rohigyalish description..."></textarea>
                </div>

                <div onClick={() => this.accordion("malay_dropdown", "malay")} className="description_acc">
                    <i id="malay_acc_icon" className="fas fa-plus"></i> <span id="malay_acc">{dict["Add Bahasa Malaysia description"][this.props.language]}</span>
                </div>
                <div style={{ maxHeight: "0px" }} id="malay_dropdown" className="extend">
                    <textarea onChange={(e) => this.updateState(e, "malay")} value={this.state.malayHold} placeholder="Bahasa Malaysia description..."></textarea>
                </div>

                <button className="primary full" onClick={this.upload}><div className="button_icon"><Icon.Upload /></div>{dict["Upload position"][this.props.language]}</button>
                <button className="secondary full" onClick={this.cancelPost}><div className="button_icon"><Icon.XCircle /></div>{dict["Cancel"][this.props.language]}</button>

                <MODAL id="confirmation_modal">
                    <div className="modal_heading">{dict["Thank you"][this.props.language]}!</div>
                    <div className="small_text">{dict["Thank you for uploading a position, one of our administrators will review your post before publishing. You may receive a phone call to discuss the position you are offering"][this.props.language]}.</div>
                    <br/>
                    <button onClick={() => this.confirm()} className="primary"><div className="button_icon"><Icon.Check2Circle /></div>{dict["Ok"][this.props.language]}</button>
                </MODAL>
            </div>
        );
    }
}

export default UPLOAD_POSITION;