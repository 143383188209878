/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPosition = /* GraphQL */ `
  mutation CreatePosition(
    $input: CreatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    createPosition(input: $input, condition: $condition) {
      id
      user
      title
      location
      phone_number
      src
      english
      rohingyalish
      malay
      verified
      reported
      reported_reason
      createdAt
      updatedAt
    }
  }
`;
export const updatePosition = /* GraphQL */ `
  mutation UpdatePosition(
    $input: UpdatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    updatePosition(input: $input, condition: $condition) {
      id
      user
      title
      location
      phone_number
      src
      english
      rohingyalish
      malay
      verified
      reported
      reported_reason
      createdAt
      updatedAt
    }
  }
`;
export const deletePosition = /* GraphQL */ `
  mutation DeletePosition(
    $input: DeletePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    deletePosition(input: $input, condition: $condition) {
      id
      user
      title
      location
      phone_number
      src
      english
      rohingyalish
      malay
      verified
      reported
      reported_reason
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      src
      user
      phone_number
      english
      rohingyalish
      malay
      published
      reports {
        items {
          id
          reason
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          text
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      saves {
        items {
          id
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      src
      user
      phone_number
      english
      rohingyalish
      malay
      published
      reports {
        items {
          id
          reason
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          text
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      saves {
        items {
          id
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      src
      user
      phone_number
      english
      rohingyalish
      malay
      published
      reports {
        items {
          id
          reason
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      likes {
        items {
          id
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          text
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      saves {
        items {
          id
          user
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLike = /* GraphQL */ `
  mutation CreateLike(
    $input: CreateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    createLike(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      user
      createdAt
      updatedAt
    }
  }
`;
export const updateLike = /* GraphQL */ `
  mutation UpdateLike(
    $input: UpdateLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    updateLike(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      user
      createdAt
      updatedAt
    }
  }
`;
export const deleteLike = /* GraphQL */ `
  mutation DeleteLike(
    $input: DeleteLikeInput!
    $condition: ModelLikeConditionInput
  ) {
    deleteLike(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      user
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      text
      user
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      text
      user
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      text
      user
      createdAt
      updatedAt
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      reason
      user
      createdAt
      updatedAt
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      reason
      user
      createdAt
      updatedAt
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      reason
      user
      createdAt
      updatedAt
    }
  }
`;
export const createSave = /* GraphQL */ `
  mutation CreateSave(
    $input: CreateSaveInput!
    $condition: ModelSaveConditionInput
  ) {
    createSave(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      user
      createdAt
      updatedAt
    }
  }
`;
export const updateSave = /* GraphQL */ `
  mutation UpdateSave(
    $input: UpdateSaveInput!
    $condition: ModelSaveConditionInput
  ) {
    updateSave(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      user
      createdAt
      updatedAt
    }
  }
`;
export const deleteSave = /* GraphQL */ `
  mutation DeleteSave(
    $input: DeleteSaveInput!
    $condition: ModelSaveConditionInput
  ) {
    deleteSave(input: $input, condition: $condition) {
      id
      post {
        id
        src
        user
        phone_number
        english
        rohingyalish
        malay
        published
        reports {
          nextToken
        }
        likes {
          items {
            id
            user
            updatedAt
          }
          nextToken
        }
        comments {
          items {
            id
            text
            user
            updatedAt
            createdAt
          }
          nextToken
        }
        saves {
          items {
            id
            user
          }
          nextToken
        }

        createdAt
        updatedAt
      }
      user
      createdAt
      updatedAt
    }
  }
`;
export const createFollow = /* GraphQL */ `
  mutation CreateFollow(
    $input: CreateFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    createFollow(input: $input, condition: $condition) {
      id
      user
      follows
      createdAt
      updatedAt
    }
  }
`;
export const updateFollow = /* GraphQL */ `
  mutation UpdateFollow(
    $input: UpdateFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    updateFollow(input: $input, condition: $condition) {
      id
      user
      follows
      createdAt
      updatedAt
    }
  }
`;
export const deleteFollow = /* GraphQL */ `
  mutation DeleteFollow(
    $input: DeleteFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    deleteFollow(input: $input, condition: $condition) {
      id
      user
      follows
      createdAt
      updatedAt
    }
  }
`;
export const createSkill = /* GraphQL */ `
  mutation CreateSkill(
    $input: CreateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    createSkill(input: $input, condition: $condition) {
      id
      user
      skill
      endorsements {
        items {
          id
          user
          about
          review
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSkill = /* GraphQL */ `
  mutation UpdateSkill(
    $input: UpdateSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    updateSkill(input: $input, condition: $condition) {
      id
      user
      skill
      endorsements {
        items {
          id
          user
          about
          review
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSkill = /* GraphQL */ `
  mutation DeleteSkill(
    $input: DeleteSkillInput!
    $condition: ModelSkillConditionInput
  ) {
    deleteSkill(input: $input, condition: $condition) {
      id
      user
      skill
      endorsements {
        items {
          id
          user
          about
          review
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEndorsement = /* GraphQL */ `
  mutation CreateEndorsement(
    $input: CreateEndorsementInput!
    $condition: ModelEndorsementConditionInput
  ) {
    createEndorsement(input: $input, condition: $condition) {
      id
      skill {
        id
        user
        skill
        endorsements {
          nextToken
        }
        createdAt
        updatedAt
      }
      user
      about
      review
      createdAt
      updatedAt
    }
  }
`;
export const updateEndorsement = /* GraphQL */ `
  mutation UpdateEndorsement(
    $input: UpdateEndorsementInput!
    $condition: ModelEndorsementConditionInput
  ) {
    updateEndorsement(input: $input, condition: $condition) {
      id
      skill {
        id
        user
        skill
        endorsements {
          nextToken
        }
        createdAt
        updatedAt
      }
      user
      about
      review
      createdAt
      updatedAt
    }
  }
`;
export const deleteEndorsement = /* GraphQL */ `
  mutation DeleteEndorsement(
    $input: DeleteEndorsementInput!
    $condition: ModelEndorsementConditionInput
  ) {
    deleteEndorsement(input: $input, condition: $condition) {
      id
      skill {
        id
        user
        skill
        endorsements {
          nextToken
        }
        createdAt
        updatedAt
      }
      user
      about
      review
      createdAt
      updatedAt
    }
  }
`;
