import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import "./index.sass";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router';
import history from "./history";

import Amplify from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

ReactDOM.render(
	<React.StrictMode>
		<Router history={history}>
			<App />
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

serviceWorkerRegistration.register();

// reportWebVitals();