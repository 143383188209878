const dict = {
    "Audio": {
        "English": "Audio",
        "Rohingyalish": "Abas",
        "Bahasa Malaysia": "Audio",
        "Burmese": "အသံ",
        "Hanifi": "𐴀𐴝𐴃𐴝𐴏"
    },  
    
    "Account": {
        "English": "Account",
        "Rohingyalish": "Hísab",
        "Bahasa Malaysia": "Akaun",
        "Burmese": "အကောင့်",
        "Hanifi": "𐴇𐴞𐴏𐴝𐴁𐴢"
    },
    
    "Settings": {
        "English": "Settings",
        "Rohingyalish": "Séthín",
        "Bahasa Malaysia": "Tetapan",
        "Burmese": "ချိန်ညှိချက်များ",
        "Hanifi": "𐴏𐴠𐴄𐴞𐴕"
    }, 
    
    "Help": {
        "English": "Help",
        "Rohingyalish": "Modot",
        "Bahasa Malaysia": "Tolonglah",
        "Burmese": "ကူညီကြပါ",
        "Hanifi": "𐴔𐴡𐴊𐴡𐴃𐴢"
    }, 
    
    "Logout": {
        "English": "Logout",
        "Rohingyalish": "Baárkule neeiló",
        "Bahasa Malaysia": "Log keluar",
        "Burmese": "ထွက်ပေါက်",
        "Hanifi": "𐴁𐴦𐴝𐴌𐴑𐴟𐴓𐴠 𐴕𐴦𐴠𐴓𐴡"
    }, 
    
    "Login": {
        "English": "Login",
        "Rohingyalish": "Bútore gól",
        "Bahasa Malaysia": "Log masuk",
        "Burmese": "လော့ဂ်အင်",
        "Hanifi": "𐴁𐴟𐴃𐴡𐴌𐴠 𐴒𐴡𐴓"
    }, 
    
    "About you": {
        "English": "About you",
        "Rohingyalish": "Baabote tuñí",
        "Bahasa Malaysia": "Tentang awak",
        "Burmese": "သင့်အကြောင်း",
        "Hanifi": "𐴁𐴦𐴝𐴁𐴟𐴃𐴠 𐴃𐴟𐴛"
    }, 
    
    "Already have an account?": {
        "English": "Already have an account?",
        "Rohingyalish": "Foóila asé uggwá hísab?",
        "Bahasa Malaysia": "Sudah mempunyai akaun?",
        "Burmese": "အကောင့်ရှိပြီးသားလား?",
        "Hanifi": "𐴂𐴡𐴘𐴓𐴝 𐴀𐴝𐴏𐴠 𐴀𐴡𐴒𐴧𐴟𐴖𐴝 𐴇𐴞𐴏𐴝𐴁𐴢"
    }, 
    
    "Password": {
        "English": "Password",
        "Rohingyalish": "Húfiawaádh",
        "Bahasa Malaysia": "Kata laluan",
        "Burmese": "စကားဝှက်",
        "Hanifi": "𐴇𐴟𐴉𐴞𐴖𐴝𐴌𐴋"
    }, 
    
    "Verify": {
        "English": "Verify",
        "Rohingyalish": "Tosdikgor",
        "Bahasa Malaysia": "Sahkan",
        "Burmese": "အတည်ပြုသည်",
        "Hanifi": "𐴃𐴡𐴏𐴊𐴞𐴑𐴒𐴡𐴌"
    }, 
    
    "Confirm password": {
        "English": "Confirm password",
        "Rohingyalish": "Fakkagor húfiawaádh",
        "Bahasa Malaysia": "Sahkan kata laluan",
        "Burmese": "စကားဝှက်ကိုအတည်ပြုပါ",
        "Hanifi": "𐴉𐴝𐴑𐴧𐴝𐴒𐴡𐴌 𐴇𐴟𐴉𐴞𐴖𐴝𐴌𐴋"
    }, 
    
    "New password": {
        "English": "New password",
        "Rohingyalish": "Noya húfiawaádh",
        "Bahasa Malaysia": "Kata laluan baharu",
        "Burmese": "စကားဝှကိအသစ်",
        "Hanifi": "𐴕𐴡𐴘𐴝 𐴇𐴟𐴉𐴞𐴖𐴝𐴌𐴋 "
    }, 
    
    "Register": {
        "English": "Register",
        "Rohingyalish": "Rejisthar gor",
        "Bahasa Malaysia": "Daftar",
        "Burmese": "မှတ်ပုံတင်ပါ",
        "Hanifi": "𐴌𐴞𐴅𐴞𐴏𐴄𐴝𐴌 𐴒𐴡𐴌"
    }, 
    
    "Forgotten your password?": {
        "English": "Forgotten your password?",
        "Rohingyalish": "Foórái giyégói húfiawaádh?",
        "Bahasa Malaysia": "Lupa kata laluan?",
        "Burmese": "မင်းရဲ့စကားဝှက်မေ့နေတာလား",
        "Hanifi": "𐴉𐴡𐴌𐴝𐴘𐴒𐴞𐴘𐴡𐴘 𐴇𐴟𐴉𐴞𐴖𐴝𐴌𐴋 "
    }, 
    
    "Reset here": {
        "English": "Reset here",
        "Rohingyalish": "Dubara séthgor eçé",
        "Bahasa Malaysia": "Tetapkan semula di sini",
        "Burmese": "ဤနေရာတွင်ပြန်လည်စတင်ပါ",
        "Hanifi": "𐴊𐴟𐴁𐴝𐴌𐴝 𐴏𐴠𐴄𐴞𐴕 𐴒𐴡𐴌"
    }, 
    
    "Resend": {
        "English": "Resend",
        "Rohingyalish": "Redefeçá",
        "Bahasa Malaysia": "Hantar semula",
        "Burmese": "ပြန်လည်ပေးပို့သည်",
        "Hanifi": "𐴌𐴞𐴊𐴠𐴉𐴠𐴍𐴝"
    }, 
    
    "Haven't received a code?": {
        "English": "Haven't received a code?",
        "Rohingyalish": "Asé no gosífaiyé ekkán koóudh?",
        "Bahasa Malaysia": "Belum terima kod?",
        "Burmese": "ကုဒ်တစ်ခုမရသေးဘူးလား။",
        "Hanifi": "𐴀𐴝𐴏𐴠𐴕𐴡 𐴒𐴡𐴏𐴞𐴉𐴝𐴘𐴠 𐴀𐴠𐴑𐴧𐴝𐴕 𐴑𐴟𐴊𐴢"
    }, 
    
    "Send code": {
        "English": "Send code",
        "Rohingyalish": "Defeçá koóudh",
        "Bahasa Malaysia": "Hantar kod",
        "Burmese": "ကုဒ်ပို့ပါ",
        "Hanifi": "𐴊𐴠𐴉𐴠𐴍𐴝 𐴑𐴟𐴊𐴢"
    }, 
    
    "Code sent to": {
        "English": "Code sent to",
        "Rohingyalish": "Koóudh defeçáiye olla",
        "Bahasa Malaysia": "Kod dihantar ke",
        "Burmese": "ကုဒ်ပို့သည်",
        "Hanifi": "𐴑𐴟𐴊𐴢 𐴊𐴠𐴉𐴠𐴍𐴝𐴘𐴠 𐴀𐴡𐴓𐴧𐴝"
    }, 
    
    "Wrong number?": {
        "English": "Wrong number?",
        "Rohingyalish": "Golot nombór?",
        "Bahasa Malaysia": "Salah nombor?",
        "Burmese": "နံပါတ်အမှား?",
        "Hanifi": "𐴒𐴡𐴓𐴡𐴃𐴢 𐴕𐴡𐴔𐴁𐴡𐴌"
    }, 
    
    "Back to login": {
        "English": "Back to login",
        "Rohingyalish": "Fith olla bútore gól",
        "Bahasa Malaysia": "Kembali ke log masuk",
        "Burmese": "သို့ပြန်သွားရန်",
        "Hanifi": "𐴉𐴞𐴃𐴢 𐴀𐴡𐴓𐴧𐴝𐴁𐴟𐴃𐴡𐴌𐴠 𐴒𐴡𐴓"
    }, 
    
    "Code": {
        "English": "Code",
        "Rohingyalish": "Koóudh",
        "Bahasa Malaysia": "Kod",
        "Burmese": "ကုဒ်",
        "Hanifi": "𐴑𐴟𐴊𐴢"
    }, 
    
    "News feed": {
        "English": "News feed",
        "Rohingyalish": "Hóbor hábo",
        "Bahasa Malaysia": "Suapan berita",
        "Burmese": "သတင်းများ",
        "Hanifi": "𐴇𐴡𐴁𐴡𐴌 𐴇𐴝𐴁𐴡"
    }, 
    
    "Preferred language": {
        "English": "Preferred language",
        "Rohingyalish": "Fosóni báca",
        "Bahasa Malaysia": "Bahasa pilihan",
        "Burmese": "ပိုမိုနှစ်သက်ဘာသာစကား",
        "Hanifi": "𐴉𐴡𐴏𐴡𐴕𐴞 𐴁𐴝𐴐𐴝"
    }, 
    
    "Language settings": {
        "English": "Language settings",
        "Rohingyalish": "Zuban séthín",
        "Bahasa Malaysia": "Tetapan bahasa",
        "Burmese": "ဘာသာစကားဆက်တင်များ",
        "Hanifi": "𐴎𐴟𐴁𐴝𐴕 𐴏𐴠𐴄𐴞𐴕"
    }, 
    
    "Audio settings": {
        "English": "Audio settings",
        "Rohingyalish": "Abas séthín",
        "Bahasa Malaysia": "Tetapan audio",
        "Burmese": "အသံဆက်တင်များ",
        "Hanifi": "𐴀𐴝𐴁𐴝𐴏 𐴏𐴠𐴄𐴞𐴕 "
    }, 
    
    "Account settings": {
        "English": "Account settings",
        "Rohingyalish": "Hísab séthín",
        "Bahasa Malaysia": "Tetapan akaun",
        "Burmese": "အကောင့်ဆက်တင်များ",
        "Hanifi": "𐴇𐴞𐴏𐴝𐴁𐴢 𐴏𐴠𐴄𐴞𐴕 "
    }, 
    
    "Delete account": {
        "English": "Delete account",
        "Rohingyalish": "Buzaiféla hísab",
        "Bahasa Malaysia": "Padamkan akaun",
        "Burmese": "အကောင့်ဖျက်ပါ",
        "Hanifi": "𐴁𐴟𐴎𐴝𐴘𐴉𐴠𐴓𐴝 𐴇𐴞𐴏𐴝𐴁𐴢"
    }, 
    
    "Delete": {
        "English": "Delete",
        "Rohingyalish": "Buzaiféla",
        "Bahasa Malaysia": "Padam",
        "Burmese": "ဖျက်ပါ",
        "Hanifi": " 𐴁𐴟𐴎𐴝𐴘𐴉𐴠𐴓𐴝 "
    }, 
    
    "Update account": {
        "English": "Update account",
        "Rohingyalish": "Kiórzana hísab",
        "Bahasa Malaysia": "Kemas kini akaun",
        "Burmese": "အကောင့်အသစ်ပြောင်းပါ",
        "Hanifi": "𐴇𐴞𐴏𐴝𐴁𐴢 𐴑𐴡𐴘𐴌𐴎𐴝𐴕𐴝"
    }, 
    
    "Name": {
        "English": "Name",
        "Rohingyalish": "Nam",
        "Bahasa Malaysia": "Nama",
        "Burmese": "နာမည်",
        "Hanifi": "𐴕𐴦𐴝𐴕"
    }, 
    
    "Username": {
        "English": "Username",
        "Rohingyalish": "Nam estemal goróya",
        "Bahasa Malaysia": "Nama pengguna",
        "Burmese": "အသုံးပြုသူအမည်",
        "Hanifi": "𐴀𐴠𐴏𐴃𐴝𐴔𐴝𐴓 𐴒𐴡𐴌𐴡𐴘𐴧𐴝𐴌 𐴕𐴦𐴝𐴔"
    }, 
    
    "Phone number": {
        "English": "Phone number",
        "Rohingyalish": "Fún nombór",
        "Bahasa Malaysia": "Nombor telefon",
        "Burmese": "ဖုန်းနံပါတ်",
        "Hanifi": "𐴉𐴟𐴕 𐴕𐴡𐴔𐴁𐴡𐴌"
    }, 
    
    "Update": {
        "English": "Update",
        "Rohingyalish": "Kiórzana",
        "Bahasa Malaysia": "Kemas kini",
        "Burmese": "နောက်ဆုံးသတင်း",
        "Hanifi": "𐴑𐴡𐴘𐴌 𐴎𐴝𐴕𐴝"
    }, 
    
    "Cancel": {
        "English": "Cancel",
        "Rohingyalish": "Kensel",
        "Bahasa Malaysia": "Batal",
        "Burmese": "ပယ်ဖျက်ပါ",
        "Hanifi": "𐴑𐴠𐴕𐴏𐴠𐴓"
    }, 
    
    "Please enter your new details": {
        "English": "Please enter your new details",
        "Rohingyalish": "Meérbanigorí gól tuáñr noya tofsil",
        "Bahasa Malaysia": "Sila masukkan maklumat baru anda",
        "Burmese": "ကျေးဇူးပြု၍ မင်းရဲ့အသေးစိတ်အချက်အလက်အသစ်ဖြည့်ပါ",
        "Hanifi": "𐴔𐴠𐴇𐴠𐴌𐴁𐴝𐴕𐴞𐴒𐴡𐴌𐴞 𐴃𐴟𐴣𐴀𐴝𐴌 𐴕𐴡𐴘𐴝 𐴃𐴡𐴉𐴢𐴏𐴞𐴓"
    }, 
    
    "Enter": {
        "English": "Enter",
        "Rohingyalish": "Gól",
        "Bahasa Malaysia": "Masukkan",
        "Burmese": "ရိုက်ထည့်ပါ",
        "Hanifi": "𐴒𐴡𐴓𐴡"
    }, 
    
    'Please enter "Delete" to delete': {
        "English": 'Please enter "Delete" to delete',
        "Rohingyalish": 'Meérbanigorí gól "Buzaiféla" ot buzaiéla',
        "Bahasa Malaysia": 'Sila masukkan "Padam" untuk memadam',
        "Burmese": 'ဖျက်ရန် "ဖျက်ရန်" ကိုဖြည့်ပါ',
        "Hanifi": " 𐴔𐴠𐴇𐴠𐴌𐴁𐴝𐴕𐴞𐴒𐴡𐴌𐴞 𐴒𐴡𐴓 𐴁𐴟𐴎𐴝𐴘𐴉𐴠𐴓𐴝 𐴀𐴡𐴃𐴢 𐴁𐴟𐴎𐴝𐴘𐴉𐴠𐴓𐴝 "
    }, 
    
    "Warning: this cannot be undone": {
        "English": "Warning: this cannot be undone",
        "Rohingyalish": "Warnín: yián nofaré ó agoijjá",
        "Bahasa Malaysia": "Amaran: ini tidak boleh dibuat asal",
        "Burmese": "သတိပေးချက် - ဤသည်ကို ပြန်၍ မရပါ",
        "Hanifi": "𐴖𐴝𐴌𐴕𐴞𐴕 𐴘𐴝𐴕 𐴕𐴡𐴉𐴝𐴌𐴠 𐴀𐴝𐴒𐴡𐴅𐴧𐴝"
    }, 
    
    "Upload video": {
        "English": "Upload video",
        "Rohingyalish": "Uorefoóñsa vidu",
        "Bahasa Malaysia": "Muat naik video",
        "Burmese": "ဗွီဒီယိုတင်ပါ",
        "Hanifi": "𐴀𐴡𐴌𐴠 𐴉𐴟𐴕𐴏𐴝 𐴜𐴠𐴋𐴟"
    }, 
    
    "Upload audio": {
        "English": "Upload audio",
        "Rohingyalish": "Uorefoóñsa abas",
        "Bahasa Malaysia": "Muat naik audio",
        "Burmese": "အသံတင်ပါ",
        "Hanifi": " 𐴀𐴡𐴌𐴠 𐴉𐴟𐴕𐴏𐴝 𐴀𐴝𐴁𐴝𐴏𐴢"
    }, 
    
    "Add English description": {
        "English": "Add English description",
        "Rohingyalish": "Zomade Ingilíc tofsil",
        "Bahasa Malaysia": "Tambahkan keterangan Bahasa Inggeris",
        "Burmese": "အင်္ဂလိပ်ဖော်ပြချက်ထည့်ပါ",
        "Hanifi": "𐴎𐴡𐴔𐴝𐴊𐴠 𐴀𐴞𐴚𐴓𐴞𐴐𐴢 𐴃𐴡𐴉𐴏𐴞𐴓𐴢"
    }, 
    
    "Add Rohingyalish description": {
        "English": "Add Rohingyalish description",
        "Rohingyalish": "Zomade Rohingyalish tofsil",
        "Bahasa Malaysia": "Tambahkan keterangan Rohingyalish",
        "Burmese": "ရိုဟင်ဂျာဖော်ပြချက်ထည့်ပါ",
        "Hanifi": " 𐴎𐴡𐴔𐴝𐴊𐴠 𐴌𐴟𐴇𐴥𐴝𐴙𐴚𐴒𐴝 𐴃𐴡𐴉𐴏𐴞𐴓𐴢"
    }, 
    
    "Add Bahasa Malaysia description": {
        "English": "Add Bahasa Malaysia description",
        "Rohingyalish": "Zomade Bahasa Malésia tofsil",
        "Bahasa Malaysia": "Tambah keterangan Bahasa Malaysia",
        "Burmese": "မလေးဖော်ပြချက်ထည့်ပါ",
        "Hanifi": " 𐴎𐴡𐴔𐴝𐴊𐴠 𐴔𐴠𐴓𐴠𐴐𐴞𐴘𐴝 𐴃𐴡𐴉𐴏𐴞𐴓𐴢"
    },
    
    "Add Burmese description": {
        "English": "Add Burmese description",
        "Rohingyalish": "Zomade Bormar tofsil",
        "Bahasa Malaysia": "Tambahkan keterangan Burma",
        "Burmese": "ဗမာဖော်ပြချက်ထည့်ပါ",
        "Hanifi": " 𐴎𐴡𐴔𐴝𐴊𐴠 𐴁𐴡𐴌𐴔𐴝𐴌 𐴃𐴡𐴉𐴏𐴞𐴓𐴢"
    },
    
    "Add Hanifi description": {
        "English": "Add Hanifi description",
        "Rohingyalish": "Zomade Hanifi tofsil",
        "Bahasa Malaysia": "Tambahkan keterangan Hanifi",
        "Burmese": "Hanifi ဖော်ပြချက်ထည့်ပါ",
        "Hanifi": " 𐴎𐴡𐴔𐴝𐴊𐴠 𐴇𐴝𐴕𐴞𐴉𐴞 𐴃𐴡𐴉𐴏𐴞𐴓"
    },
    
    "User": {
        "English": "User",
        "Rohingyalish": "Estemal goróya",
        "Bahasa Malaysia": "Pengguna",
        "Burmese": "အသုံးပြုသူကို",
        "Hanifi": "𐴀𐴠𐴏𐴃𐴝𐴔𐴝𐴓 𐴒𐴡𐴌𐴡𐴘𐴧𐴝"
    }, 
    
    "Users": {
        "English": "Users",
        "Rohingyalish": "Estemal goróya",
        "Bahasa Malaysia": "Pengguna",
        "Burmese": "အသုံးပြုသူများ",
        "Hanifi": " 𐴀𐴠𐴏𐴃𐴝𐴔𐴝𐴓 𐴒𐴡𐴌𐴡𐴘𐴧𐴝 "
    },
    
    "Saved": {
        "English": "Saved",
        "Rohingyalish": "Basaiyé",
        "Bahasa Malaysia": "Disimpan",
        "Burmese": "သိမ်းပြီး",
        "Hanifi": "𐴁𐴝𐴏𐴝𐴘𐴧𐴠"
    }, 
    
    "Notifications": {
        "English": "Notifications",
        "Rohingyalish": "Etelá ókkol",
        "Bahasa Malaysia": "Pemberitahuan",
        "Burmese": "အသိပေးချက်များ",
        "Hanifi": "𐴀𐴠𐴃𐴠𐴓𐴝𐴀𐴡𐴑𐴧𐴡𐴓𐴢"
    }, 
    
    "Search here": {
        "English": "Search here",
        "Rohingyalish": "Tua eçé",
        "Bahasa Malaysia": "Cari di sini",
        "Burmese": "ဤနေရာတွင်ရှာပါ",
        "Hanifi": "𐴃𐴟𐴖𐴝 𐴀𐴠𐴍𐴠"
    }, 
    
    "Search": {
        "English": "Search",
        "Rohingyalish": "Tua",
        "Bahasa Malaysia": "Cari",
        "Burmese": "ရှာပါ",
        "Hanifi": "𐴃𐴟𐴖𐴝"
    }, 
    
    "Filter your search": {
        "English": "Filter your search",
        "Rohingyalish": "Salo tuáñr tua",
        "Bahasa Malaysia": "Tapis carian anda",
        "Burmese": "သင့်ရှာဖွေမှုကိုစစ်ထုတ်ပါ",
        "Hanifi": "𐴏𐴝𐴓𐴡 𐴃𐴟𐴣𐴖𐴝𐴌 𐴃𐴟𐴖𐴝"
    }, 
    
    "See user": {
        "English": "See user",
        "Rohingyalish": "Dehé estemal goróya",
        "Bahasa Malaysia": "Lihat pengguna",
        "Burmese": "အသုံးပြုသူကိုကြည့်သည်",
        "Hanifi": "𐴊𐴠𐴇𐴠 𐴀𐴠𐴏𐴃𐴝𐴔𐴝𐴓 𐴒𐴡𐴌𐴡𐴘𐴧𐴝"
    }, 
    
    "Report": {
        "English": "Report",
        "Rohingyalish": "Repouth",
        "Bahasa Malaysia": "Lapor",
        "Burmese": "အစီရင်ခံစာ",
        "Hanifi": "𐴌𐴞𐴂𐴡𐴌𐴄"
    }, 
    
    "Why would you like to report this video": {
        "English": "Why would you like to report this video",
        "Rohingyalish": "Kiyá saá tuñí fosóngoró olla repouth yián vidu",
        "Bahasa Malaysia": "Mengapa anda ingin melaporkan video ini",
        "Burmese": "ဘာကြောင့်ဒီဗီဒီယိုကိုသတင်းပို့ချင်ရတာလဲ",
        "Hanifi": "𐴑𐴝𐴘𐴝 𐴏𐴝 𐴃𐴟𐴛 𐴉𐴡𐴏𐴡𐴕𐴒𐴡𐴌𐴡 𐴡𐴓𐴧𐴝 𐴌𐴞𐴂𐴡𐴌𐴄 𐴘𐴝𐴕 𐴁𐴞𐴊𐴟"
    }, 
    
    "Sexual content": {
        "English": "Sexual content",
        "Rohingyalish": "Jinsí mila",
        "Bahasa Malaysia": "Kandungan seksual",
        "Burmese": "လိင်အကြောင်းအရာ",
        "Hanifi": "𐴅𐴞𐴕𐴞𐴐 𐴔𐴞𐴓𐴝"
    }, 
    
    "Violence": {
        "English": "Violence",
        "Rohingyalish": "Dhónga fósat",
        "Bahasa Malaysia": "Keganasan",
        "Burmese": "အကြမ်းဖက်မှု",
        "Hanifi": "𐴊𐴡𐴕𐴒𐴝 𐴉𐴡𐴏𐴝𐴃𐴢"
    }, 
    
    "Hate speech": {
        "English": "Hate speech",
        "Rohingyalish": "Íncagor hotá",
        "Bahasa Malaysia": "Ucapan benci",
        "Burmese": "အမုန်းစကား",
        "Hanifi": "𐴀𐴞𐴕𐴐𐴝 𐴇𐴡𐴃𐴝"
    }, 
    
    "Self injury": {
        "English": "Self injury",
        "Rohingyalish": "Nize zohóm",
        "Bahasa Malaysia": "Kecederaan diri",
        "Burmese": "မိမိကိုယ်ကိုအနာတရဖြစ်အောင်လုပ်ခြင်း",
        "Hanifi": "𐴕𐴞𐴎𐴠 𐴎𐴡𐴇𐴡𐴔"
    }, 
    
    "Other": {
        "English": "Other",
        "Rohingyalish": "Oinno",
        "Bahasa Malaysia": "Yang lain",
        "Burmese": "အခြား",
        "Hanifi": "𐴀𐴡𐴙𐴕𐴧𐴡"
    }, 
    
    "Other reasons": {
        "English": "Other reasons",
        "Rohingyalish": "Oinno wojás",
        "Bahasa Malaysia": "Sebab lain",
        "Burmese": "အခြားအကြောင်းပြချက်များ",
        "Hanifi": " 𐴀𐴡𐴙𐴕𐴧𐴡  𐴖𐴝𐴅𐴝"
    }, 
    
    "Leave a comment": {
        "English": "Leave a comment",
        "Rohingyalish": "Neel ekkán nozoriya",
        "Bahasa Malaysia": "Tinggalkan komen",
        "Burmese": "မှတ်ချက်တစ်ခုချန်ထားပါ",
        "Hanifi": "𐴕𐴠𐴓𐴝 𐴀𐴠𐴑𐴧𐴝𐴕 𐴕𐴡𐴎𐴡𐴌𐴞𐴘𐴝"
    }, 
    
    
    "Followers": {
        "English": "Followers",
        "Rohingyalish": "Fiséfiséza",
        "Bahasa Malaysia": "Pengikut",
        "Burmese": "နောက်လိုက်များ",
        "Hanifi": "𐴉𐴞𐴏𐴠𐴉𐴞𐴏𐴠𐴎𐴝"
    }, 
    
    "Following": {
        "English": "Following",
        "Rohingyalish": "Fiséfisé zar",
        "Bahasa Malaysia": "Mengikuti",
        "Burmese": "အောက်ပါ",
        "Hanifi": " 𐴉𐴞𐴏𐴠𐴉𐴞𐴏𐴠𐴎𐴝𐴌"
    },
    
    "You don't currently have any saved videos": {
        "English": "You don't currently have any saved videos",
        "Rohingyalish": "Tuñí gor no ehón asé honó basaiyé vidu",
        "Bahasa Malaysia": "Anda tidak mempunyai video yang disimpan buat masa ini",
        "Burmese": "သင့်တွင်သိမ်းဆည်းထားသောဗီဒီယိုမရှိပါ",
        "Hanifi": "𐴁𐴟𐴀𐴟𐴛 𐴒𐴡𐴌 𐴕𐴡 𐴀𐴠𐴇𐴡𐴕 𐴀𐴝𐴏𐴠𐴇𐴡𐴕𐴡 𐴁𐴝𐴏𐴝𐴘𐴠 𐴁𐴞𐴊𐴟"
    }, 
    
    "You have": {
        "English": "You have",
        "Rohingyalish": "Tuñí asé",
        "Bahasa Malaysia": "Anda mempunyai",
        "Burmese": "မင်းဆီမှ",
        "Hanifi": " 𐴁𐴟𐴀𐴟𐴛  𐴀𐴝𐴏𐴠"
    }, 
    
    "saved videos": {
        "English": "saved videos",
        "Rohingyalish": "basaiyé vidu",
        "Bahasa Malaysia": "video yang disimpan",
        "Burmese": "ဗွီဒီယိုများသိမ်းဆည်းထား",
        "Hanifi": "𐴁𐴝𐴏𐴝𐴘𐴠 𐴁𐴞𐴊𐴟"
    }, 
    
    "saved video": {
        "English": "saved video",
        "Rohingyalish": "basaiyé vidu",
        "Bahasa Malaysia": "video yang disimpan",
        "Burmese": "ဗွီဒီယိုသိမ်းဆည်းထား",
        "Hanifi": "𐴁𐴝𐴏𐴝𐴘𐴠 𐴁𐴞𐴊𐴟" 
    }, 
    
    "English description": {
        "English": "English description",
        "Rohingyalish": "Ingilíc tofsil",
        "Bahasa Malaysia": "Huraian Bahasa Inggeris",
        "Burmese": "အင်္ဂလိပ်ဖော်ပြချက်",
        "Hanifi": "𐴀𐴞𐴚𐴓𐴞𐴐 𐴃𐴡𐴉𐴏𐴞𐴓"
    }, 
    
    "Rohingyalish description": {
        "English": "Rohingyalish description",
        "Rohingyalish": "Rohingyalish tofsil",
        "Bahasa Malaysia": "Penerangan Rohingyalish",
        "Burmese": "ရိုဟင်ဂျာဖော်ပြချက်",
        "Hanifi": "𐴌𐴟𐴖𐴝𐴙𐴚𐴝𐴓𐴞𐴐 𐴃𐴡𐴉𐴏𐴞𐴓 "
    }, 
    
    "Bahasa Malaysia description": {
        "English": "Bahasa Malaysia description",
        "Rohingyalish": "Bahasa Malésia tofsil",
        "Bahasa Malaysia": "Huraian Bahasa Melayu",
        "Burmese": "မလေးဖော်ပြချက်",
        "Hanifi": "𐴔𐴠𐴓𐴠𐴐𐴞𐴘𐴝 𐴃𐴡𐴉𐴏𐴞𐴓 "
    },
    
    "Burmese description": {
        "English": "Burmese description",
        "Rohingyalish": "Bormis tofsi",
        "Bahasa Malaysia": "Huraian Burma",
        "Burmese": "မြန်မာဖော်ပြချက်",
        "Hanifi": "𐴁𐴡𐴌𐴔𐴝𐴌 𐴃𐴡𐴉𐴏𐴞𐴓 "
    },
    
    "Thank you": {
        "English": "Thank you",
        "Rohingyalish": "Cúkuria",
        "Bahasa Malaysia": "Terima kasih",
        "Burmese": "ကျေးဇူးတင်ပါတယ်",
        "Hanifi": "𐴐𐴟𐴍𐴟𐴌𐴞𐴘𐴝"
    }, 
    
    "You can now check out your video on your news feed or in your account page": {
        "English": "You can now check out your video on your news feed or in your account page",
        "Rohingyalish": "Tuñí foth ehón sek baáre tuáñr vidu uore tuáñr hóbor hána yáto bútore tuáñr hísab sófa",
        "Bahasa Malaysia": "Anda kini boleh melihat video anda di suapan berita atau di halaman akaun anda",
        "Burmese": "သင်၏ဗွီဒီယိုကိုသင်၏သတင်းသတင်းသို့မဟုတ်သင်၏အကောင့်စာမျက်နှာတွင်စစ်ဆေးနိုင်သည်",
        "Hanifi": "𐴃𐴟𐴀𐴟𐴛 𐴉𐴡𐴃𐴢 𐴀𐴠𐴇𐴡𐴕 𐴏𐴠𐴑 𐴁𐴝𐴌𐴠 𐴃𐴟𐴣𐴖𐴝𐴌 𐴁𐴞𐴊𐴟𐴀𐴡𐴌𐴠 𐴃𐴟𐴣𐴖𐴝𐴌 𐴇𐴡𐴁𐴡𐴌 𐴇𐴝𐴕𐴝𐴘𐴝𐴃𐴡 𐴁𐴟𐴃𐴡𐴌𐴠 𐴃𐴟𐴣𐴖𐴝𐴌 𐴇𐴞𐴏𐴝𐴁𐴢 𐴏𐴡𐴉𐴝"
    }, 
    
    "Ok": {
        "English": "Ok",
        "Rohingyalish": "Thíkasé",
        "Bahasa Malaysia": "Okey",
        "Burmese": "အဆင်ပြေလား",
        "Hanifi": "𐴄𐴞𐴑𐴀𐴝𐴏𐴠"
    }, 
    
    "Publish": {
        "English": "Publish",
        "Rohingyalish": "Sába",
        "Bahasa Malaysia": "Menerbitkan",
        "Burmese": "ထုတ်ဝေသည်",
        "Hanifi": "𐴏𐴝𐴁𐴝"
    }, 
    
    "This user isn't following anyone yet": {
        "English": "This user isn't following anyone yet",
        "Rohingyalish": "Yián estemal goróya ói no fiséfisé zar honikka ájjo",
        "Bahasa Malaysia": "Pengguna ini belum mengikuti sesiapa",
        "Burmese": "ဤအသုံးပြုသူသည်မည်သူတစ် ဦး တစ်ယောက်မှမကြည့်သေးပါ",
        "Hanifi": "𐴘𐴝𐴕𐴀𐴠𐴏𐴃𐴝𐴔𐴝𐴓 𐴒𐴡𐴌𐴡𐴘𐴧𐴝 𐴀𐴡𐴘 𐴕𐴠 𐴉𐴞𐴏𐴠𐴉𐴞𐴏𐴠𐴎𐴝𐴌 𐴇𐴡𐴕𐴞𐴑𐴧𐴝 𐴀𐴡𐴅𐴡"
    }, 
    
    "This user doesn't have any followers yet": {
        "English": "This user doesn't have any followers yet",
        "Rohingyalish": "Yián estemal goróya gor no asé honó fiséfiséza ájjo",
        "Bahasa Malaysia": "Pengguna ini belum mempunyai pengikut",
        "Burmese": "ဤအသုံးပြုသူတွင်နောက်လိုက်မရှိပါ",
        "Hanifi": "𐴃𐴟𐴀𐴛 𐴀𐴠𐴏𐴃𐴝𐴔𐴝𐴓 𐴒𐴡𐴌𐴡𐴘𐴧𐴝 𐴒𐴡𐴌 𐴕𐴡 𐴀𐴝𐴏𐴠 𐴇𐴡𐴕𐴡 𐴉𐴞𐴏𐴠𐴉𐴞𐴏𐴠𐴗𐴀𐴝𐴅𐴡"
    }, 
    
    "Follow": {
        "English": "Follow",
        "Rohingyalish": "Fiséfiséza",
        "Bahasa Malaysia": "Ikut",
        "Burmese": "လိုက်နာပါ",
        "Hanifi": "𐴉𐴞𐴏𐴠𐴉𐴞𐴏𐴠𐴎𐴝"
    }, 
    
    "Unfollow": {
        "English": "Unfollow",
        "Rohingyalish": "No fiséfiséza",
        "Bahasa Malaysia": "Berhenti mengikut",
        "Burmese": "မရပ်မနား",
        "Hanifi": "𐴕𐴡 𐴉𐴞𐴏𐴠𐴉𐴞𐴏𐴠𐴎𐴝"
    }, 
    
    "Endorse": {
        "English": "Endorse",
        "Rohingyalish": "Tosdik",
        "Bahasa Malaysia": "Sokong",
        "Burmese": "ထောက်ခံသည်",
        "Hanifi": "𐴃𐴡𐴏𐴊𐴞𐴑𐴢"
    }, 
    
    "Endorsements": {
        "English": "Endorsements",
        "Rohingyalish": "Tosdiks",
        "Bahasa Malaysia": "Pengendorsan",
        "Burmese": "ထောက်ခံချက်များ",
        "Hanifi": " 𐴃𐴡𐴏𐴊𐴞𐴑𐴢 𐴀𐴡𐴑𐴧𐴝𐴓𐴢"
    }, 
    
    "Endorsement": {
        "English": "Endorsement",
        "Rohingyalish": "Tosdik",
        "Bahasa Malaysia": "Pengesahan",
        "Burmese": "ထောက်ခံချက်",
        "Hanifi": " 𐴃𐴡𐴏𐴊𐴞𐴑𐴢 "
    }, 
    
    "Your endorsements": {
        "English": "Your endorsements",
        "Rohingyalish": "Tuáñr tosdiks",
        "Bahasa Malaysia": "Sokongan anda",
        "Burmese": "သင့်ရဲ့ထောက်ခံချက်များ",
        "Hanifi": "𐴃𐴟𐴣𐴖𐴝𐴌 𐴃𐴡𐴏𐴊𐴞𐴑𐴢 "
    }, 
    
    "One of our administrators will review your report": {
        "English": "One of our administrators will review your report",
        "Rohingyalish": "Ek or añárár entezam goróya saá nozorsáni tuáñr repouth",
        "Bahasa Malaysia": "Salah seorang pentadbir kami akan menyemak laporan anda",
        "Burmese": "ကျွန်ုပ်တို့၏အုပ်ချုပ်ရေးမှူးတစ် ဦး သည်သင်၏အစီရင်ခံစာကိုပြန်လည်သုံးသပ်လိမ့်မည်",
        "Hanifi": "𐴀𐴝𐴣𐴌𐴝𐴌 𐴀𐴠𐴕𐴃𐴝𐴎𐴝𐴔 𐴒𐴡𐴌𐴡𐴧𐴝 𐴀𐴡𐴒𐴧𐴟𐴖𐴝𐴘𐴠 𐴃𐴟𐴣𐴖𐴝𐴌 𐴌𐴞𐴂𐴡𐴄𐴀𐴡𐴌𐴠𐴏𐴝𐴙𐴁𐴡"
    }, 
    
    "Leave a review": {
        "English": "Leave a review",
        "Rohingyalish": "Félairak ekkán nozorsáni",
        "Bahasa Malaysia": "Tinggalkan ulasan",
        "Burmese": "သုံးသပ်ချက်ချန်ထားပါ",
        "Hanifi": "𐴉𐴠𐴓𐴝𐴘𐴢 𐴌𐴝𐴑 𐴀𐴠𐴑𐴧𐴝𐴕 𐴕𐴡𐴎𐴡𐴌𐴞𐴘𐴝"
    }, 
    
    "What do you think of": {
        "English": "What do you think of",
        "Rohingyalish": "Ki gor tuni bafo or",
        "Bahasa Malaysia": "Apa pendapat anda mengenai bakat",
        "Burmese": "သငျသညျ",
        "Hanifi": "𐴃𐴟𐴀𐴟𐴛 𐴑𐴞𐴁𐴝𐴉𐴡"
    }, 
    
    "'s talent": {
        "English": "'s talent",
        "Rohingyalish": "'s habiliyot",
        "Bahasa Malaysia": "",
        "Burmese": "သငျသညျ",
        "Hanifi": "𐴀𐴠𐴏𐴢 𐴇𐴟𐴁𐴞𐴘𐴡𐴃𐴢"
    }, 
    
    "You don't have any notifications": {
        "English": "You don't have any notifications",
        "Rohingyalish": "Tuni gor no ase hono etela okkol",
        "Bahasa Malaysia": "Anda tidak mempunyai pemberitahuan",
        "Burmese": "ရဲ့အခွက်တဆယ်ကိုဘယ်လိုထင်သလဲ",
        "Hanifi": "𐴃𐴟𐴣𐴖𐴝𐴓𐴧𐴝 𐴇𐴡𐴕𐴡 𐴇𐴡𐴁𐴡𐴌 𐴕𐴝𐴙"
    }, 
    
    "Your skills": {
        "English": "Your skills",
        "Rohingyalish": "Tuanr maheri",
        "Bahasa Malaysia": "Kemahiran awak",
        "Burmese": "သင့်ရဲ့ကျွမ်းကျင်မှု",
        "Hanifi": "𐴃𐴟𐴣𐴖𐴝𐴌 𐴔𐴝𐴇𐴠𐴌𐴞"
    }, 
    
    "Skills": {
        "English": "Skills",
        "Rohingyalish": "Maheri",
        "Bahasa Malaysia": "Kemahiran",
        "Burmese": "ကျွမ်းကျင်မှုများ",
        "Hanifi": " 𐴔𐴝𐴇𐴠𐴌𐴞 "
    }, 
    
    "Under construction": {
        "English": "Under construction",
        "Rohingyalish": "Nise bañdá",
        "Bahasa Malaysia": "Dalam pembinaan",
        "Burmese": "ဆောက်လုပ်ဆဲ",
        "Hanifi": "𐴕𐴠𐴏𐴠 𐴁𐴝𐴕𐴊𐴝"
    }, 
    
    "Select an option": {
        "English": "Select an option",
        "Rohingyalish": "Baso ekkan ektiyar",
        "Bahasa Malaysia": "Buat pilihan",
        "Burmese": "ရွေးစရာတစ်ခုကိုရွေးချယ်ပါ",
        "Hanifi": "𐴁𐴝𐴏𐴡 𐴀𐴠𐴑𐴧𐴝𐴕 𐴀𐴠𐴑𐴃𐴞𐴘𐴝𐴌"
    }, 
    
    "Carpentry": {
        "English": "Carpentry",
        "Rohingyalish": "Mistri",
        "Bahasa Malaysia": "Pertukangan kayu",
        "Burmese": "လက်သမားဆရာ",
        "Hanifi": "𐴔𐴞𐴏𐴃𐴞𐴌𐴞"
    }, 
    
    "Electrician": {
        "English": "Electrician",
        "Rohingyalish": "Elektric ola",
        "Bahasa Malaysia": "Juruelektrik",
        "Burmese": "လျှပ်စစ်",
        "Hanifi": "𐴀𐴠𐴓𐴠𐴑 𐴄𐴞𐴌𐴞𐴑 𐴀𐴡𐴓𐴝"
    }, 
    
    "Plumbing": {
        "English": "Plumbing",
        "Rohingyalish": "Fáik'or miser",
        "Bahasa Malaysia": "Paip",
        "Burmese": "ပိုက်",
        "Hanifi": "𐴉𐴝𐴙𐴑 𐴇𐴡𐴌𐴒𐴡𐴌𐴡𐴘𐴧𐴝"
    }, 
    
    "Painting": {
        "English": "Painting",
        "Rohingyalish": "Sabatuloon",
        "Bahasa Malaysia": "Mengecat",
        "Burmese": "ပန်းချီကား",
        "Hanifi": "𐴏𐴝𐴁𐴝𐴃𐴟𐴓𐴡𐴕"
    }, 
    
    "Trade skills": {
        "English": "Trade skills",
        "Rohingyalish": "Bica-kina'r mahéri",
        "Bahasa Malaysia": "Kemahiran perdagangan",
        "Burmese": "ကုန်သွယ်ရေးကျွမ်းကျင်မှု",
        "Hanifi": "𐴐𐴡𐴊𐴡𐴌𐴞"
    }, 
    
    "Home skills": {
        "English": "Home skills",
        "Rohingyalish": "Gor maheri",
        "Bahasa Malaysia": "Kemahiran di rumah",
        "Burmese": "အိမ်တွင်းကျွမ်းကျင်မှု",
        "Hanifi": "𐴒𐴡𐴌 𐴔𐴝𐴇𐴠𐴌𐴞"
    }, 
    
    "Cooking": {
        "English": "Cooking",
        "Rohingyalish": "Randede",
        "Bahasa Malaysia": "Memasak",
        "Burmese": "ဟင်းချက်",
        "Hanifi": "𐴌𐴝𐴕𐴊𐴠𐴊𐴠"
    }, 
    
    "Sewing": {
        "English": "Sewing",
        "Rohingyalish": "Habor siloon",
        "Bahasa Malaysia": "Menjahit",
        "Burmese": "အပ်ချုပ်",
        "Hanifi": "𐴇𐴡𐴌 𐴏𐴞𐴓𐴝"
    }, 
    
    "Cleaning": {
        "English": "Cleaning",
        "Rohingyalish": "Safgoroon",
        "Bahasa Malaysia": "Pembersihan",
        "Burmese": "သန့်ရှင်းရေး",
        "Hanifi": "𐴏𐴝𐴉 𐴒𐴡𐴌𐴡𐴕"
    }, 
    
    "Childcare": {
        "English": "Childcare",
        "Rohingyalish": "Fuain sámaloya",
        "Bahasa Malaysia": "Penjagaan kanak-kanak",
        "Burmese": "ကလေးထိန်း",
        "Hanifi": "𐴉𐴟𐴖𐴝𐴙𐴕 𐴏𐴡𐴕𐴓𐴡𐴘𐴧𐴝"
    }, 
    
    "Baking": {
        "English": "Baking",
        "Rohingyalish": "Cake banoya",
        "Bahasa Malaysia": "Pembakar",
        "Burmese": "မုန့်ဖုတ်",
        "Hanifi": "𐴑𐴠𐴙𐴑 𐴁𐴝𐴕𐴡𐴘𐴧𐴝"
    }, 
    
    "What skill would you like to add": {
        "English": "What skill would you like to add",
        "Rohingyalish": "Ki maheri saa tuni foson zomade",
        "Bahasa Malaysia": "Kemahiran apa yang ingin anda tambah",
        "Burmese": "ဘာကျွမ်းကျင်မှုကိုထည့်ချင်ပါသလဲ",
        "Hanifi": "𐴑𐴞 𐴔𐴝𐴇𐴠𐴌𐴞 𐴏𐴝 𐴃𐴟𐴀𐴟𐴛 𐴉𐴡𐴏𐴡𐴕 𐴎𐴡𐴔𐴝𐴊𐴠"
    }, 
    
    "Select one": {
        "English": "Select one",
        "Rohingyalish": "basilo ekkan",
        "Bahasa Malaysia": "Pilih satu",
        "Burmese": "တစ်ခုရွေးပါ",
        "Hanifi": "𐴁𐴝𐴏𐴞𐴓𐴡 𐴀𐴠𐴑𐴧𐴝𐴕"
    }, 
    
    "or write your own": {
        "English": "or write your own",
        "Rohingyalish": "or leko tuanr nijor",
        "Bahasa Malaysia": "atau tulis sendiri",
        "Burmese": "သို့မဟုတ်သင့်ကိုယ်ပိုင်ရေးပါ",
        "Hanifi": "𐴀𐴡𐴌 𐴓𐴠𐴑𐴡 𐴀𐴠𐴑𐴧𐴝𐴕"
    }, 
    
    "Skill": {
        "English": "Skill",
        "Rohingyalish": "Hunor",
        "Bahasa Malaysia": "Kemahiran",
        "Burmese": "ကျွမ်းကျင်မှု",
        "Hanifi": "𐴇𐴟𐴕𐴡𐴌"
    }, 
    
    "Talent": {
        "English": "Talent",
        "Rohingyalish": "Giyani",
        "Bahasa Malaysia": "Bakat",
        "Burmese": "ထူးချွန်",
        "Hanifi": "𐴒𐴞𐴘𐴝𐴕𐴞"
    }, 
    
    "New": {
        "English": "New",
        "Rohingyalish": "Noya",
        "Bahasa Malaysia": "Baru",
        "Burmese": "အသစ်",
        "Hanifi": "𐴕𐴡𐴘𐴧𐴝"
    }, 
    
    "Popular": {
        "English": "Popular",
        "Rohingyalish": "Fosondi",
        "Bahasa Malaysia": "Popular",
        "Burmese": "လူကြိုက်များ",
        "Hanifi": "𐴉𐴡𐴏𐴡𐴕𐴊𐴞"
    }, 
    
    "Create an account": {
        "English": "Create an account",
        "Rohingyalish": "Foidagor ekkán hísaf",
        "Bahasa Malaysia": "Buat akaun",
        "Burmese": "အကောင့်တစ်ခုဖန်တီးသည်",
        "Hanifi": "𐴉𐴡𐴘𐴊𐴝𐴒𐴡𐴌 𐴀𐴠𐴑𐴧𐴝𐴕 𐴇𐴝𐴏𐴝𐴁𐴢"
    }, 
    
    "Forgotten your password": {
        "English": "Forgotten your password",
        "Rohingyalish": "Foóráifelye tuáñr húfiawaádh",
        "Bahasa Malaysia": "Lupa kata laluan",
        "Burmese": "သင်၏စကားဝှက်ကိုမေ့သွားတယ်",
        "Hanifi": "𐴉𐴡𐴌𐴝𐴙𐴉𐴠𐴓𐴝𐴘𐴧𐴡 𐴃𐴟𐴣𐴖𐴝𐴌 𐴇𐴟𐴉𐴞𐴘𐴝𐴖𐴝𐴌𐴋"
    }, 
    
    "Reset": {
        "English": "Reset",
        "Rohingyalish": "Dubara séthgor",
        "Bahasa Malaysia": "Tetapkan semula",
        "Burmese": "ပြန်လည်စတင်",
        "Hanifi": "𐴊𐴟𐴁𐴝𐴌𐴝 𐴏𐴠𐴄𐴒𐴡𐴌"
    }, 
    
    "Submit": {
        "English": "Submit",
        "Rohingyalish": "Háwalagor",
        "Bahasa Malaysia": "Hantar",
        "Burmese": "တင်သွင်းပါ",
        "Hanifi": "𐴇𐴝𐴖𐴝𐴓𐴝𐴒𐴡𐴌"
    }, 
    
    "Already have an account": {
        "English": "Already have an account",
        "Rohingyalish": "Foóila asé ekkán hísab",
        "Bahasa Malaysia": "Sudah mempunyai akaun",
        "Burmese": "အကောင့်ရှိပြီးသားလား",
        "Hanifi": "𐴉𐴡𐴙𐴓𐴝 𐴀𐴝𐴏𐴠 𐴀𐴠𐴑𐴧𐴝𐴕 𐴇𐴞𐴏𐴝𐴁𐴢"
    }, 
    
    "Wrong number": {
        "English": "Wrong number",
        "Rohingyalish": "Sóiyi noó nombór",
        "Bahasa Malaysia": "Salah nombor",
        "Burmese": "နံပါတ်မှား",
        "Hanifi": "𐴕𐴡𐴔𐴁𐴡𐴌 𐴏𐴡𐴘𐴞𐴥𐴡"
    }, 
    
    "Haven’t received a code": {
        "English": "Haven’t received a code",
        "Rohingyalish": "Asé nóo gosílo ekkán koóudh",
        "Bahasa Malaysia": "Belum menerima kod",
        "Burmese": "ကုဒ်တစ်ခုလက်ခံရရှိသည်မဟုတ်",
        "Hanifi": "𐴀𐴝𐴏𐴠 𐴕𐴡 𐴒𐴡𐴏𐴞𐴓𐴡 𐴀𐴠𐴑𐴧𐴝𐴕 𐴑𐴟𐴊𐴢"
    }, 
    
    "Please wait": {
        "English": "Please wait",
        "Rohingyalish": "Meérbanigorí tíyo",
        "Bahasa Malaysia": "Sila tunggu",
        "Burmese": "ကျေးဇူးပြုပြီးခဏစောင့်ပါ",
        "Hanifi": "𐴔𐴠𐴇𐴠𐴌𐴁𐴝𐴕𐴞𐴒𐴡𐴌𐴞 𐴃𐴞𐴘𐴡"
    }, 
    
    "This will take about 30 seconds": {
        "English": "This will take about 30 seconds",
        "Rohingyalish": "Yián saá loiza baabote 30 sekén",
        "Bahasa Malaysia": "Ini akan mengambil masa kira-kira 30 saat",
        "Burmese": "၎င်းသည် 30 စက္ကန့်ခန့်ကြာမြင့်မည်",
        "Hanifi": "𐴳𐴰 𐴏𐴠𐴒𐴠𐴣𐴊𐴢 𐴓𐴝𐴙𐴁𐴡"
    }, 
    
    "Days (d)": {
        "English": "Days (d)",
        "Rohingyalish": "Din",
        "Bahasa Malaysia": "Hari (h)",
        "Burmese": "နေ့ရက်များ",
        "Hanifi": "𐴊𐴞𐴕"
    }, 
    
    "Hours (h)": {
        "English": "Hours (h)",
        "Rohingyalish": "Góndha",
        "Bahasa Malaysia": "Jam (j)",
        "Burmese": "နာရီ",
        "Hanifi": "𐴒𐴡𐴕𐴊𐴝"
    }, 
    
    "Seconds (s)": {
        "English": "Seconds (s)",
        "Rohingyalish": "Sekén",
        "Bahasa Malaysia": "Detik (d)",
        "Burmese": "စက္ကန့်",
        "Hanifi": "𐴏𐴠𐴒𐴠𐴣𐴊𐴢"
    }, 
    
    "Weeks (w)": {
        "English": "Weeks (w)",
        "Rohingyalish": "Háfta",
        "Bahasa Malaysia": "Minggu (m)",
        "Burmese": "ရက်သတ္တပတ်များ",
        "Hanifi": "𐴇𐴝𐴉𐴃𐴝"
    }, 
    
    "Years (y)": {
        "English": "Years (y)",
        "Rohingyalish": "Bosór",
        "Bahasa Malaysia": "Bertahun-tahun (b)",
        "Burmese": "နှစ်များ",
        "Hanifi": "𐴁𐴡𐴏𐴡𐴌"
    }, 
    
    "people have liked your video": {
        "English": "people have liked your video",
        "Rohingyalish": "manúic asé fosóngoró tuáñr vidu",
        "Bahasa Malaysia": "orang menyukai video anda",
        "Burmese": "လူတွေကမင်းရဲ့ဗီဒီယိုကိုကြိုက်တယ်",
        "Hanifi": "𐴔𐴝𐴕𐴐𐴠 𐴀𐴝𐴏𐴠𐴉𐴡𐴏𐴡𐴕𐴊 𐴒𐴡𐴌𐴡 𐴃𐴟𐴣𐴀𐴝𐴌 𐴁𐴞𐴊𐴟"
    }, 
    
    "person has liked your video": {
        "English": "person has liked your video",
        "Rohingyalish": "manúic asé fosóngoró tuáñr vidu",
        "Bahasa Malaysia": "orang telah menyukai video anda",
        "Burmese": "လူတစ်ယောက်ကမင်းရဲ့ဗီဒီယိုကိုကြိုက်တယ်",
        "Hanifi": " 𐴔𐴝𐴕𐴐𐴠 𐴀𐴝𐴏𐴠 𐴉𐴡𐴏𐴝𐴕 𐴒𐴡𐴌𐴡 𐴃𐴟𐴣𐴀𐴝𐴌 𐴁𐴞𐴊𐴟"
    }, 
    
    "person has commented on your video": {
        "English": "person has commented on your video",
        "Rohingyalish": "manúic asé nozoriya diyé uore tuáñr vidu",
        "Bahasa Malaysia": "orang telah mengulas pada video anda",
        "Burmese": "လူတစ်ယောက်ကသင်၏ဗီဒီယိုကိုမှတ်ချက်ပေးသည်",
        "Hanifi": "𐴔𐴝𐴕𐴐𐴠 𐴀𐴝𐴏𐴠 𐴕𐴡𐴎𐴡𐴌𐴞𐴘𐴝 𐴊𐴞𐴘𐴠 𐴀𐴡𐴌𐴠 𐴃𐴟𐴣𐴀𐴝𐴌 𐴁𐴞𐴊𐴟"
    }, 
    
    "people have commented on your video": {
        "English": "people have commented on your video",
        "Rohingyalish": "manúic asé nozoriya diyé uore tuáñr vidu",
        "Bahasa Malaysia": "orang telah mengulas pada video anda",
        "Burmese": "လူတွေကမင်းရဲ့ဗွီဒီယိုကိုမှတ်ချက်ပေးကြတယ်",
        "Hanifi": " 𐴔𐴝𐴕𐴐𐴠 𐴀𐴝𐴏𐴠 𐴕𐴡𐴎𐴡𐴌𐴞𐴘𐴝 𐴊𐴞𐴘𐴠 𐴀𐴡𐴌𐴠 𐴃𐴟𐴣𐴀𐴝𐴌 𐴁𐴞𐴊𐴟 "
    }, 
    
    "has uploaded a new video": {
        "English": "has uploaded a new video",
        "Rohingyalish": "asé uoretuló uggwá noya vidu",
        "Bahasa Malaysia": "telah memuat naik video baru",
        "Burmese": "ဗွီဒီယိုအသစ်တစ်ခုတင်လိုက်သည်",
        "Hanifi": "𐴀𐴝𐴏𐴠 𐴀𐴡𐴌𐴠 𐴃𐴟𐴓𐴡 𐴀𐴡𐴒𐴧𐴟𐴖𐴝 𐴁𐴞𐴊𐴟"
    }, 
    
    "You have received a new endorsement": {
        "English": "You have received a new endorsement",
        "Rohingyalish": "Uñí asé gosífa uggwá noya tosdik",
        "Bahasa Malaysia": "Anda telah mendapat sokongan baru",
        "Burmese": "သင်ထောက်ခံချက်အသစ်တစ်ခုရရှိထားပြီ",
        "Hanifi": "𐴃𐴟𐴀𐴟𐴛 𐴀𐴝𐴏𐴠 𐴒𐴡𐴏𐴞𐴉𐴝 𐴀𐴡𐴒𐴧𐴟𐴖𐴝 𐴕𐴡𐴘𐴝 𐴃𐴡𐴏𐴊𐴞𐴑"
    }, 
    
    "Add an emoji": {
        "English": "Add an emoji",
        "Rohingyalish": "Zomade uggwá emoji",
        "Bahasa Malaysia": "Tambahkan emoji",
        "Burmese": "တစ် ဦး ရုပ်ပြောင်ထည့်ပါ",
        "Hanifi": "𐴎𐴡𐴔𐴝𐴊𐴠 𐴀𐴡𐴒𐴧𐴟𐴖𐴝 𐴀𐴞𐴔𐴟𐴅𐴞"
    }, 
    
    "Emoji": {
        "English": "Emoji",
        "Rohingyalish": "Emoji",
        "Bahasa Malaysia": "Emoji",
        "Burmese": "အီမိုဂျီ",
        "Hanifi": " 𐴀𐴞𐴔𐴟𐴅𐴞 "
    }, 
    
    "English": {
        "English": "English",
        "Rohingyalish": "Ingilíc",
        "Bahasa Malaysia": "Bahasa Inggeris",
        "Burmese": "အင်္ဂလိပ်",
        "Hanifi": "𐴀𐴞𐴚𐴓𐴞𐴐𐴢"
    }, 
    
    "Rohingyalish": {
        "English": "Rohingyalish",
        "Rohingyalish": "Rohingyalish",
        "Bahasa Malaysia": "Rohingyalish",
        "Burmese": "ရိုဟင်ဂျာ",
        "Hanifi": "𐴌𐴟𐴖𐴝𐴙𐴚𐴒𐴝𐴓𐴞𐴐"
    }, 
    
    "Bahasa Malaysia": {
        "English": "Bahasa Malaysia",
        "Rohingyalish": "Molay Zoban",
        "Bahasa Malaysia": "Bahasa Malaysia",
        "Burmese": "မလေး",
        "Hanifi": "𐴔𐴡𐴓𐴝𐴘𐴢 𐴎𐴟𐴃𐴝𐴕"
    }, 
    
    "Burmese": {
        "English": "Burmese",
        "Rohingyalish": "Bormis",
        "Bahasa Malaysia": "Burma",
        "Burmese": "ဗမာ",
        "Hanifi": "𐴁𐴡𐴌𐴔𐴝"
    },
    
    "Malay": {
        "English": "Malay",
        "Rohingyalish": "Molai",
        "Bahasa Malaysia": "Bahasa Melayu",
        "Burmese": "မလေး",
        "Hanifi": "𐴔𐴡𐴓𐴝𐴘𐴢"
    },
    
    "Hanifi": {
        "English": "Hanifi",
        "Rohingyalish": "Hanifi",
        "Bahasa Malaysia": "Hanifi",
        "Burmese": "ဟာနာဖီ",
        "Hanifi": "𐴇𐴝𐴕𐴞𐴉𐴞"
    }, 
    
    "Don't show this again": {
        "English": "Don't show this again",
        "Rohingyalish": "No daha ein abbar",
        "Bahasa Malaysia": "Jangan tunjukkan ini lagi",
        "Burmese": "ဒီဟာကိုနောက်တစ်ကြိမ်မပြပါနှင့်",
        "Hanifi": "𐴕𐴡𐴊𐴠𐴇𐴝 𐴀𐴠𐴕 𐴀𐴝𐴌𐴀𐴠𐴑𐴁𐴝𐴌"
    }, 
    
    "Remember to stay safe": {
        "English": "Remember to stay safe",
        "Rohingyalish": "Yadgor ikka táki basi",
        "Bahasa Malaysia": "Ingatlah untuk terus selamat",
        "Burmese": "လုံခြုံစွာနေရန်သတိရပါ",
        "Hanifi": "𐴘𐴝𐴊𐴒𐴡𐴌 𐴀𐴞𐴑𐴧𐴝 𐴃𐴝𐴑𐴞 𐴁𐴝𐴏𐴞"
    }, 
    
    "Don't show your face": {
        "English": "Don't show your face",
        "Rohingyalish": "No daha tuáñr cokól",
        "Bahasa Malaysia": "Jangan tunjukkan wajah anda",
        "Burmese": "သင်၏မျက်နှာကိုမပြပါနှင့်",
        "Hanifi": "𐴕𐴡𐴊𐴝𐴇𐴝 𐴃𐴟𐴣𐴖𐴝𐴌 𐴐𐴡𐴑𐴡𐴓𐴢"
    }, 
    
    "Don't show your location": {
        "English": "Don't show your location",
        "Rohingyalish": "No daha tuáñr ga",
        "Bahasa Malaysia": "Jangan tunjukkan lokasi anda",
        "Burmese": "သင့်တည်နေရာကိုမပြပါနှင့်",
        "Hanifi": " 𐴕𐴡𐴊𐴝𐴇𐴝 𐴃𐴟𐴣𐴖𐴝𐴌 𐴎𐴝𐴒𐴝 "
    }, 
    
    "Oh dear, you appear to be lost": {
        "English": "Oh dear, you appear to be lost",
        "Rohingyalish": "Oh adojja, tuni  daháza ikka haji giyos goy",
        "Bahasa Malaysia": "Oh sayang, anda kelihatan hilang",
        "Burmese": "အိုး, သင်ဆုံးရှုံးခဲ့ရပုံရသည်",
        "Hanifi": "𐴀𐴡 𐴀𐴡𐴊𐴡𐴅𐴧𐴝 𐴃𐴟𐴣𐴘 𐴊𐴝𐴈𐴝𐴎𐴝𐴊𐴧𐴠 𐴇𐴝𐴣𐴎𐴞 𐴒𐴞𐴘𐴡𐴏 𐴒𐴡𐴙"
    }, 
    
    "Go back": {
        "English": "Go back",
        "Rohingyalish": "Ja waapes",
        "Bahasa Malaysia": "Pergi balik",
        "Burmese": "ပြန်သွားသည်",
        "Hanifi": "𐴎𐴝 𐴖𐴝𐴂𐴠𐴏𐴢"
    }, 
    
    "Sorry, something has gone wrong": {
        "English": "Sorry, something has gone wrong",
        "Rohingyalish": "Afsús,kessú gorá giyégói golot",
        "Bahasa Malaysia": "Maaf, ada yang tidak kena",
        "Burmese": "စိတ်မရှိပါနဲ့တစ်ခုခုမှားသွားပါတယ်",
        "Hanifi": "𐴀𐴝𐴉𐴏𐴟𐴏𐴢 𐴑𐴞𐴏𐴧𐴟 𐴒𐴡𐴌𐴝𐴒𐴞𐴘𐴡𐴘𐴢 𐴒𐴡𐴓𐴡𐴃𐴢"
    }, 
    
    "Reload": {
        "English": "Reload",
        "Rohingyalish": "Abbar boro",
        "Bahasa Malaysia": "Tambah nilai",
        "Burmese": "ပြန်တင်ပါ",
        "Hanifi": "𐴀𐴝𐴁𐴧𐴝𐴌 𐴁𐴡𐴌𐴡"
    }, 
    
    "Check your internet connection": {
        "English": "Check your internet connection",
        "Rohingyalish": "Sek tuáñr intharneth taaluk",
        "Bahasa Malaysia": "Periksa sambungan internet anda",
        "Burmese": "သင်၏အင်တာနက်ဆက်သွယ်မှုကိုစစ်ဆေးပါ",
        "Hanifi": "𐴃𐴟𐴣𐴖𐴝𐴌 𐴀𐴞𐴕𐴄𐴝𐴌𐴕𐴠𐴄𐴢 𐴏𐴠𐴑 𐴒𐴡𐴌𐴡"
    }, 
    
    "Are you sure you want to delete your skill? Any endorsements will be lost too": {
        "English": "Are you sure you want to delete your skill? Any endorsements will be lost too",
        "Rohingyalish": "Tuáñr maheri ki soyi soyi buzaiféla to sodde hiyan soyi ne? Tuanr tosdiknama beggin hari jaibogoy",
        "Bahasa Malaysia": "Adakah anda pasti mahu memadamkan kemahiran anda? Sebarang sokongan juga akan hilang",
        "Burmese": "မင်းရဲ့ကျွမ်းကျင်မှုကိုဖျက်ချင်တာသေချာလား။ မည်သည့်ထောက်ခံချက်များလည်းဆုံးရှုံးလိမ့်မည်",
        "Hanifi": "𐴃𐴟𐴣𐴖𐴝𐴌 𐴔𐴝𐴇𐴠𐴌𐴞 𐴑𐴞 𐴏𐴡𐴘𐴞𐴏𐴡𐴘𐴞 𐴁𐴟𐴎𐴝𐴘𐴉𐴠𐴓𐴝𐴘𐴃𐴡 𐴏𐴡𐴊𐴧𐴠𐴘𐴝𐴕  𐴏𐴡𐴘𐴞 𐴕𐴠"
    }, 
    
    "Edit": {
        "English": "Edit",
        "Rohingyalish": "Bodol",
        "Bahasa Malaysia": "Edit",
        "Burmese": "တည်းဖြတ်ပါ",
        "Hanifi": "𐴇𐴡𐴌𐴒𐴡𐴌"
    }, 
    
    "Save": {
        "English": "Save",
        "Rohingyalish": "Basa",
        "Bahasa Malaysia": "Jimat",
        "Burmese": "သိမ်းဆည်းပါ",
        "Hanifi": "𐴁𐴝𐴏𐴡"
    }, 
    
    "This website uses cookies": {
        "English": "This website uses cookies",
        "Rohingyalish": "Yián wébsáith estemalgor kukies",
        "Bahasa Malaysia": "Laman web ini menggunakan kuki",
        "Burmese": "ဒီဝက်ဘ်ဆိုက်ကကွတ်ကီးများများကိုအသုံးပြုသည်",
        "Hanifi": "𐴖𐴠𐴁𐴏𐴝𐴘𐴄 𐴀𐴞𐴁𐴝𐴘𐴠 𐴑𐴟𐴑𐴞 𐴀𐴠𐴏𐴃𐴝𐴔𐴝𐴓 𐴒𐴡𐴌𐴠"
    }, 
    
    "Learn more": {
        "English": "Learn more",
        "Rohingyalish": "Cík bici",
        "Bahasa Malaysia": "Ketahui lebih lanjut",
        "Burmese": "ပိုမိုသိရှိရန်",
        "Hanifi": "𐴀𐴝𐴌𐴡𐴎𐴝𐴕𐴡"
    }, 
    
    "Install TalentEd": {
        "English": "Install TalentEd",
        "Rohingyalish": "Fíthgoró TalentEd",
        "Bahasa Malaysia": "Pasang TalentEd",
        "Burmese": "ထည့်သွင်းပါ TalentEd",
        "Hanifi": " TalentEd 𐴒𐴡𐴓𐴡"
    }, 
    
    "Install this application on your home screen for quick and easy access": {
        "English": "Install this application on your home screen for quick and easy access",
        "Rohingyalish": "Fíthgoró yián doshás uore tuáñr gór foroda ollá joldi ar asán rasta",
        "Bahasa Malaysia": "Pasang aplikasi ini di skrin utama anda untuk akses cepat dan mudah",
        "Burmese": "ဤအက်ပလီကေးရှင်းကိုမြန်မြန်ဆန်ဆန်နှင့်လွယ်ကူစွာရရှိနိုင်ရန်အတွက်သင်၏မူလမြင်ကွင်းတွင်တပ်ဆင်ပါ",
        "Hanifi": "𐴉𐴞𐴄𐴒𐴡𐴌𐴡 𐴘𐴝𐴕 𐴊𐴡𐴐𐴝𐴏 𐴀𐴡𐴌𐴠 𐴃𐴟𐴣𐴖𐴝𐴌 𐴒𐴡𐴌𐴉𐴡𐴌𐴝𐴊𐴠 𐴀𐴡𐴓𐴧𐴝 𐴅𐴡𐴓𐴊𐴞𐴀𐴝𐴌 𐴀𐴦𐴝𐴏𐴦𐴝𐴕 𐴌𐴝𐴏𐴃𐴝"
    }, 
    
    "Install": {
        "English": "Install",
        "Rohingyalish": "Fíthgoró",
        "Bahasa Malaysia": "Pasang",
        "Burmese": "ထည့်သွင်းပါ",
        "Hanifi": "𐴉𐴞𐴄𐴢 𐴒𐴡𐴌𐴡"
    }, 
    
    "Just tap": {
        "English": "Just tap",
        "Rohingyalish": "Úde sibo",
        "Bahasa Malaysia": "Ketik sahaja",
        "Burmese": "ထိပုတ်ပါ",
        "Hanifi": "𐴀𐴟𐴊𐴠 𐴏𐴞𐴁𐴡"
    }, 
    
    "then 'Install'": {
        "English": "then 'Install'",
        "Rohingyalish": "tói 'Fíthgoró'",
        "Bahasa Malaysia": "kemudian 'Pasang'",
        "Burmese": "ထို့နောက် 'ထည့်သွင်းပါ' ကိုနှိပ်ပါ",
        "Hanifi": "𐴃𐴡𐴘𐴢 𐴉𐴞𐴄𐴢 𐴒𐴡𐴌𐴡"
    }, 
    
    "then 'Add to Home Screen'": {
        "English": "then 'Add to Home Screen'",
        "Rohingyalish": "tói 'Zomade olla gór foroda'",
        "Bahasa Malaysia": "kemudian 'Tambah ke Skrin Utama'",
        "Burmese": "ထို့နောက် 'မူလမြင်ကွင်းသို့ထည့်ပါ'",
        "Hanifi": "𐴃𐴡𐴘𐴢 𐴎𐴡𐴔𐴝𐴊𐴠 𐴀𐴡𐴓𐴧𐴝 𐴒𐴡𐴌 𐴉𐴡𐴌𐴡𐴊𐴝"
    }, 
    
    "Cookie Policy": {
        "English": "Cookie Policy",
        "Rohingyalish": "Kukis folósi",
        "Bahasa Malaysia": "Dasar Kuki",
        "Burmese": "ကွတ်ကီးမူဝါဒ",
        "Hanifi": "𐴑𐴟𐴑𐴞 𐴂𐴡𐴓𐴡𐴏𐴞"
    }, 
    
    "User Agreement": {
        "English": "User Agreement",
        "Rohingyalish": "Estemal goróya razinoma",
        "Bahasa Malaysia": "Perjanjian Pengguna",
        "Burmese": "အသုံးပြုသူသဘောတူညီချက်",
        "Hanifi": "𐴀𐴠𐴏𐴃𐴠𐴔𐴝𐴓 𐴒𐴡𐴌𐴡𐴘𐴧𐴝𐴌 𐴌𐴝𐴎𐴞𐴕𐴝𐴔𐴝"
    }, 
    
    "Privacy Policy": {
        "English": "Privacy Policy",
        "Rohingyalish": "Gufoniyot folósi",
        "Bahasa Malaysia": "Dasar Privasi",
        "Burmese": "ကိုယ်ရေးအချက်အလက်မူဝါဒ",
        "Hanifi": "𐴒𐴟𐴉𐴡𐴕𐴞𐴘𐴡𐴃𐴢 𐴂𐴡𐴓𐴡𐴏𐴞"
    }, 
    
    "Add skill": {
        "English": "Add skill",
        "Rohingyalish": "Zomade mahéri",
        "Bahasa Malaysia": "Menambah kemahiran",
        "Burmese": "ကျွမ်းကျင်မှုထည့်ပါ",
        "Hanifi": "𐴎𐴡𐴝𐴊𐴠 𐴔𐴝𐴇𐴠𐴌𐴞"
    }, 
    
    "This user doesn't have any skills yet": {
        "English": "This user doesn't have any skills yet",
        "Rohingyalish": "Yián estemal goróya nu asé honó mahéri ájjo",
        "Bahasa Malaysia": "Pengguna ini belum mempunyai kemahiran",
        "Burmese": "ဤအသုံးပြုသူတွင်ကျွမ်းကျင်မှုမရှိပါ",
        "Hanifi": "𐴀𐴠𐴏𐴃𐴝𐴔𐴝𐴓 𐴒𐴡𐴌𐴡𐴘𐴧𐴝𐴃𐴧𐴟 𐴀𐴝𐴅𐴡 𐴇𐴡𐴕𐴡 𐴔𐴝𐴇𐴠𐴌𐴞 𐴕𐴝𐴙"
    }, 
    
    "Minimum 8 characters": {
        "English": "Minimum 8 characters",
        "Rohingyalish": "Ebbre homot 8 hórof",
        "Bahasa Malaysia": "Minimum 8 aksara",
        "Burmese": "အနည်းဆုံးစာလုံး ၈ လုံး",
        "Hanifi": "𐴀𐴠𐴁𐴧𐴝𐴌𐴠 𐴇𐴡𐴔𐴡𐴃𐴢 𐴸 𐴇𐴡𐴌𐴡𐴉𐴢"
    }, 
    
    "I agree to the": {
        "English": "I agree to the",
        "Rohingyalish": "Aññí man ollawá",
        "Bahasa Malaysia": "Saya setuju dengan",
        "Burmese": "ငါသဘောတူတယ်",
        "Hanifi": "𐴀𐴝𐴀𐴛 𐴔𐴝𐴕𐴞"
    }, 
    
    "and": {
        "English": "and",
        "Rohingyalish": "ar",
        "Bahasa Malaysia": "dan",
        "Burmese": "နှင့်",
        "Hanifi": "𐴀𐴝𐴌"
    }, 
    
    "Sorry, there were no results found for": {
        "English": "Sorry, there were no results found for",
        "Rohingyalish": "Afsús, éçe óoil no notiza tuaifaiyé ollá",
        "Bahasa Malaysia": "Maaf, tidak ada hasil untuk",
        "Burmese": "စိတ်မရှိပါနဲ့ရှာမတွေ့ပါ",
        "Hanifi": "𐴀𐴝𐴏𐴟𐴏𐴢 𐴀𐴠𐴍𐴠 𐴀𐴡𐴘𐴓 𐴕𐴡𐴃𐴞𐴎𐴝 𐴃𐴟𐴖𐴝𐴘𐴢𐴉𐴝𐴘𐴧𐴡𐴓𐴧𐴝"
    }, 
    
    "Navigation": {
        "English": "Navigation",
        "Rohingyalish": "Duadui",
        "Bahasa Malaysia": "Navigasi",
        "Burmese": "လမ်းကြောင်း",
        "Hanifi": "𐴊𐴟𐴖𐴝𐴊𐴟𐴙"
    },
    
    "Like, save and comment on a video. Report videos.": {
        "English": "Like, save and comment on a video. Report videos.",
        "Rohingyalish": "Fosóngoró, basa ar nozoriya uore ekkán poigam. repouth poigam",
        "Bahasa Malaysia": "Suka, simpan dan komen pada video. Laporkan video.",
        "Burmese": "လိုပဲသိမ်းဆည်းပြီးဗွီဒီယိုတစ်ခုကိုမှတ်ချက်ပေးပါ ဗွီဒီယိုများကိုသတင်းပို့ပါ။",
        "Hanifi": "𐴉𐴡𐴏𐴡𐴕 𐴒𐴥𐴡𐴌𐴡، 𐴁𐴝𐴆𐴝 𐴀𐴦𐴝𐴌 𐴕𐴡𐴎𐴡𐴌𐴞𐴘𐴝𐴌 𐴀𐴡𐴌𐴠 𐴀𐴠𐴑𐴧𐴝𐴕 𐴂𐴡𐴘𐴒𐴝𐴔 𐴌𐴞𐴂𐴡𐴄𐴢 𐴂𐴡𐴘𐴒𐴝𐴔"
    },
    
    "Save videos and access saved videos.": {
        "English": "Save videos and access saved videos.",
        "Rohingyalish": "Basa vidu ar rasta basaiyé vidu.",
        "Bahasa Malaysia": "Simpan video dan akses video yang disimpan.",
        "Burmese": "ဗွီဒီယိုများကိုသိမ်းဆည်းပြီးသိမ်းဆည်းထားသောဗွီဒီယိုများကိုရယူပါ။",
        "Hanifi": "𐴃𐴝𐴏𐴝 𐴁𐴞𐴋𐴟 𐴀𐴦𐴝𐴌 𐴌𐴝𐴏𐴃𐴝 𐴁𐴝𐴏𐴝𐴘𐴧𐴠 𐴁𐴞𐴋𐴟"
    },
    
    "What notifications show and how to access associated videos.": {
        "English": "What notifications show and how to access associated videos.",
        "Rohingyalish": "Ki ettála dahá ar kengóri olla rasta.",
        "Bahasa Malaysia": "Apa pemberitahuan yang ditunjukkan dan cara mengakses video yang berkaitan.",
        "Burmese": "အဘယ်အရာကိုအကြောင်းကြားစာများပြသနှင့်ဆက်စပ်ဗီဒီယိုများကိုဝင်ရောက်ဖို့ဘယ်လို။",
        "Hanifi": "𐴑𐴞 𐴀𐴠𐴃𐴧𐴝𐴓𐴧𐴝 𐴊𐴝𐴇𐴝 𐴀𐴦𐴝𐴌 𐴑𐴠𐴕𐴒𐴡𐴌𐴞 𐴀𐴡𐴓𐴧𐴝 𐴌𐴝𐴏𐴃𐴝"
    },
    
    "View and alter your details, add new skills, delete skills and view and report endorsements. Publish, unpublish, edit and delete videos.": {
        "English": "View and alter your details, add new skills, delete skills and view and report endorsements. Publish, unpublish, edit and delete videos.",
        "Rohingyalish": "Nozoriya ar bodol tuáñr tofsil, zomade noya mahéri, buzaiféla mahéri ar nozoriya ar repouth tosdik. sába, luwairakó, bodol ar buzaiféla vidu.",
        "Bahasa Malaysia": "View and alter your details, add new skills, delete skills and view and report endorsements. Publish, unpublish, edit and delete videos.",
        "Burmese": "သင်၏အသေးစိတ်အချက်အလက်များအားကြည့်ရှု ပြောင်းလဲ၍ ကျွမ်းကျင်မှုအသစ်များထည့်သွင်းခြင်း၊ ကျွမ်းကျင်မှုများကိုဖျက်ပစ်ခြင်းနှင့်ထောက်ခံချက်များကိုကြည့်ရှုခြင်းနှင့်အစီရင်ခံခြင်း ဗွီဒီယိုများကိုထုတ်ဝြေခင်း၊ ထုတ်ဝေခြင်း၊ တည်းဖြတ်ခြင်းနှင့်ဖျက်ခြင်း။",
        "Hanifi": "𐴕𐴡𐴎𐴡𐴌𐴞𐴘𐴝 𐴀𐴦𐴝𐴌 𐴁𐴡𐴊𐴡𐴓 𐴃𐴟𐴣𐴀𐴦𐴝𐴌 𐴃𐴡𐴉𐴏𐴞𐴓، 𐴎𐴡𐴔𐴝𐴊𐴠𐴕𐴡𐴘𐴧𐴝 𐴔𐴝𐴇𐴠𐴌𐴞، 𐴃𐴟𐴎𐴝𐴘𐴉𐴠𐴓𐴝 𐴔𐴝𐴇𐴠𐴌𐴞 𐴀𐴦𐴝𐴌 𐴕𐴡𐴎𐴡𐴌𐴞𐴘𐴝 𐴀𐴦𐴝𐴌 𐴌𐴞𐴂𐴟𐴄 𐴃𐴡𐴏𐴊𐴞𐴑، 𐴏𐴝𐴁𐴝، 𐴓𐴟𐴖𐴝𐴘𐴢 𐴌𐴝𐴑𐴡، 𐴁𐴡𐴊𐴡𐴓 𐴀𐴦𐴝𐴌 𐴁𐴟𐴎𐴝𐴘𐴉𐴠𐴓𐴝 𐴁𐴞𐴊𐴟"
    },
    
    "How to use the help page and how to find help for a specific page.": {
        "English": "How to use the help page and how to find help for a specific page.",
        "Rohingyalish": "Kengóri olla estemalgor wá modot sófa ar kengóri olla tuailo ollá ekkan háas sófa.",
        "Bahasa Malaysia": "Cara menggunakan halaman bantuan dan cara mencari bantuan untuk halaman tertentu.",
        "Burmese": "အကူအညီစာမျက်နှာကိုဘယ်လိုအသုံးပြုမလဲ။",
        "Hanifi": "𐴑𐴠𐴕𐴒𐴡𐴌𐴞 𐴀𐴡𐴓𐴧𐴝 𐴀𐴠𐴏𐴃𐴝𐴔𐴝𐴓𐴒𐴡𐴌𐴖𐴝  𐴔𐴡𐴊𐴡𐴃𐴢 𐴏𐴡𐴉𐴝 𐴀𐴦𐴌 𐴑𐴠𐴕𐴒𐴡𐴌𐴞 𐴀𐴡𐴓𐴧𐴝 𐴃𐴟𐴀𐴝𐴌𐴓𐴡𐴘𐴢𐴀𐴠𐴑𐴧𐴝𐴕 𐴇𐴦𐴝𐴏 𐴏𐴡𐴉𐴝"
    },
    
    "Change language and audio preferences, hide or show audio icons and delete your account.": {
        "English": "Change language and audio preferences, hide or show audio icons and delete your account.",
        "Rohingyalish": "Bodola zuban ar abas torji, luwa yáto dahá abas aikon ar buzaiféla tuáñr hisab.",
        "Bahasa Malaysia": "Ubah pilihan bahasa dan audio, sembunyikan atau tunjukkan ikon audio dan hapus akaun anda.",
        "Burmese": "ဘာသာစကားနှင့်အသံကြိုက်နှစ်သက်မှုများကိုပြောင်းပါ၊ အသံဖိုင်များကိုဝှက်ရန်သို့မဟုတ်ပြသပြီးသင်၏အကောင့်ကိုဖျက်ပါ။",
        "Hanifi": "𐴁𐴡𐴊𐴡𐴓𐴝 𐴎𐴟𐴁𐴝𐴕 𐴀𐴦𐴝𐴌 𐴀𐴦𐴝𐴁𐴦𐴝𐴏 𐴃𐴡𐴌𐴆𐴞 𐴓𐴟𐴖𐴝 𐴘𐴝𐴃𐴡 𐴊𐴝𐴇𐴝𐴀𐴦𐴝𐴃𐴦𐴝𐴏 𐴀𐴠𐴑𐴡𐴕 𐴀𐴦𐴝𐴌 𐴁𐴟𐴎𐴝𐴘 𐴉𐴠𐴓𐴝 𐴃𐴟𐴣𐴀𐴦𐴝𐴌 𐴇𐴞𐴏𐴝𐴁𐴢"
    },
    
    "Upload a new video with different languages. How to remain safe while doing so.": {
        "English": "Upload a new video with different languages. How to remain safe while doing so.",
        "Rohingyalish": "Uoretuló ekkan noya vidu loi forók zuban. kengóri olla baki táke héfazotase zecómot gorér tóile.",
        "Bahasa Malaysia": "Muat naik video baru dengan pelbagai bahasa. Bagaimana untuk tetap selamat semasa melakukannya.",
        "Burmese": "ကွဲပြားသောဘာသာစကားများဖြင့်ဗွီဒီယိုအသစ်တစ်ခုတင်ပါ။ အဲဒီလိုလုပ်နေစဉ်မှာဘယ်လိုဆက်ပြီးလုံခြုံစိတ်ချရမလဲ။",
        "Hanifi": "𐴀𐴡𐴌𐴠𐴃𐴟𐴓𐴡 𐴀𐴠𐴑𐴧𐴝𐴕 𐴕𐴡𐴘𐴝 𐴁𐴞𐴊𐴟𐴓𐴡𐴘 𐴉𐴡𐴌𐴡𐴑 𐴎𐴟𐴁𐴝𐴕، 𐴑𐴠𐴕𐴒𐴡𐴌𐴞𐴀𐴡𐴓𐴧𐴝𐴁𐴝𐴑𐴞 𐴃𐴝𐴑𐴠 𐴇𐴠𐴉𐴝𐴎𐴡𐴃𐴡𐴌𐴏𐴝𐴣𐴃𐴠 𐴎𐴠𐴐𐴡𐴔𐴡𐴃𐴢 𐴒𐴡𐴌𐴠𐴌 𐴃𐴡𐴘𐴓𐴠"
    },
    
    "Search for users and content, and filter your search.": {
        "English": "Search for users and content, and filter your search.",
        "Rohingyalish": "Tua ollá estemal goróya ar mila, yáto salo tuáñr tua.",
        "Bahasa Malaysia": "Cari pengguna dan kandungan, dan tapis carian anda.",
        "Burmese": "အသုံးပြုသူများနှင့်အကြောင်းအရာများကိုရှာဖွေပါ၊ သင်၏ရှာဖွေမှုကိုစစ်ပါ။",
        "Hanifi": "𐴃𐴟𐴀𐴝𐴓𐴧𐴝 𐴀𐴠𐴏𐴃𐴝𐴔𐴝𐴓 𐴒𐴡𐴌𐴡𐴘𐴧𐴝 𐴀𐴦𐴝𐴌 𐴔𐴞𐴓𐴝، 𐴘𐴝𐴃𐴡 𐴏𐴝𐴓𐴡 𐴃𐴟𐴣𐴀𐴦𐴝𐴌 𐴃𐴟𐴖𐴝"
    },
    
    "Navigate between different pages, access the app's menu, logout and view policies.": {
        "English": "Navigate between different pages, access the app's menu, logout and view policies.",
        "Rohingyalish": "Duadu bútore forók sófa, rasta wá app'or minu, baárkule neeiló ar nozoriya folósi.",
        "Bahasa Malaysia": "Navigasi di antara halaman yang berbeza, akses menu aplikasi, log keluar dan lihat.",
        "Burmese": "ကွဲပြားခြားနားသောစာမျက်နှာများအကြားသွားလာ, app ရဲ့ menu ကိုဝင်ရောက်, logout နှင့်မူဝါဒများကြည့်ရှုပါ။",
        "Hanifi": "𐴊𐴟𐴖𐴝𐴊𐴞 𐴁𐴟𐴃𐴡𐴌𐴠 𐴉𐴡𐴌𐴡𐴑 𐴏𐴡𐴉𐴝، 𐴌𐴝𐴏𐴃𐴝𐴖𐴝 𐴀𐴠𐴂𐴢𐴀𐴡𐴌 𐴔𐴞𐴕𐴟، 𐴁𐴦𐴝𐴌𐴑𐴟𐴓𐴠𐴕𐴠𐴓𐴡 𐴀𐴦𐴝𐴌 𐴕𐴡𐴎𐴡𐴌𐴞𐴘𐴝 𐴉𐴡𐴓𐴡𐴏𐴝𐴘𐴢"
    },
    
    "Videos": {
        "English": "Videos",
        "Rohingyalish": "Vidu",
        "Bahasa Malaysia": "Video",
        "Burmese": "ဗွီဒီယိုများ",
        "Hanifi": "𐴁𐴞𐴊𐴟"
    }, 
    
    "Your videos": {
        "English": "Your videos",
        "Rohingyalish": "Tuanr vidu",
        "Bahasa Malaysia": "Video anda",
        "Burmese": "သင်၏ဗီဒီယိုများ",
        "Hanifi": "𐴃𐴟𐴣𐴖𐴝𐴌 𐴁𐴞𐴊𐴟"
    }, 
    
    "New video": {
        "English": "New video",
        "Rohingyalish": "Noya vidu",
        "Bahasa Malaysia": "Video baru",
        "Burmese": "ဗွီဒီယိုအသစ်",
        "Hanifi": "𐴕𐴡𐴘𐴝 𐴁𐴞𐴊𐴟"
    }, 
    
    "Hide audio icons": {
        "English": "Hide audio icons",
        "Rohingyalish": "Luwa abas aikon",
        "Bahasa Malaysia": "Sembunyikan ikon audio",
        "Burmese": "အသံသင်္ကေတများကိုဖျောက်ထားပါ",
        "Hanifi": "𐴀𐴡𐴊𐴞𐴘𐴟𐴌 𐴀𐴝𐴘𐴑𐴡𐴕 𐴓𐴟𐴖𐴝𐴙𐴌𐴝𐴑𐴡"
    }
    
};

export default dict;