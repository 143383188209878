import { API, graphqlOperation, Storage } from "aws-amplify";
import { deletePost as deletePostMutation } from "../../graphql/mutations";
import { getPost } from "../../graphql/queries";
import deleteComment from "./deleteComment";
import deleteLike from "./deleteLike";
import deleteReport from "./deleteReport";
import deleteSave from "./deleteSave";

export default async function deletePost(id) {
    let res = await API.graphql({ query: getPost, variables: { id: id }});
    await deleteLikes(res);
    await deleteComments(res);
    await deleteReports(res);
    await deleteSaves(res);
    await API.graphql(graphqlOperation(deletePostMutation, { input: { id } }));
    if(res.data.getPost.src !== null) {
        await Storage.remove(res.data.getPost.src);
    }
}

async function deleteLikes(res) {
    let likes = res.data.getPost.likes?.items;
    for(let i = 0, size = likes?.length; i < size; i++) {
        await deleteLike(likes[i].id);
    }
}

async function deleteComments(res) {
    let comments = res.data.getPost.comments?.items;
    for(let i = 0, size = comments?.length; i < size; i++) {
        await deleteComment(comments[i].id);
    }
}

async function deleteReports(res) {
    let reports = res.data.getPost.reports?.items;
    for(let i = 0, size = reports?.length; i < size; i++) {
        await deleteReport(reports[i].id);
    }
}

async function deleteSaves(res) {
    let saves = res.data.getPost.saves?.items;
    for(let i = 0, size = saves?.length; i < size; i++) {
        await deleteSave(saves[i].id);
    }
}