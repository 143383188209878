import React from "react";
import * as Icon from "react-bootstrap-icons";
import dict from "../misc/translations";
require("datejs");

class NOTIFICATIONS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: this.props.posts,
            followed_posts: [],
            following: this.props.following,
            following_arr: [],
            notifications: [],
        }
    }

    componentDidMount() {
        let posts = this.props.posts;

        let following = this.state.following;
        let arr = [];
        if (following) {
            for (let i = 0, size = following.length; i < size; i++) {
                arr.push(following[i].follows);
            }
        }

        this.setState({
            following_arr: arr
        }, () => {
            posts = posts.filter(e => {
                return this.state.following_arr.includes(e.user) || e.user === this.props.currentUser;
            });

            posts = posts.sort((a, b) => {
                let date_a = a.updatedAt;
                if (a.user === this.props.currentUser) {
                    let likes = a.likes.items.sort((a, b) => {
                        return (a.updatedAt > b.updatedAt) ? -1 : ((a.updatedAt < b.updatedAt) ? 1 : 0);
                    });

                    let comments = a.comments.items.sort((a, b) => {
                        return (a.updatedAt > b.updatedAt) ? -1 : ((a.updatedAt < b.updatedAt) ? 1 : 0);
                    });

                    let last_like = Date.parse(likes[0]?.updatedAt);
                    if (!last_like) {
                        last_like = Date.parse("1 Jan 0001");
                    }
                    let last_comment = Date.parse(comments[0]?.updatedAt);
                    if (!last_comment) {
                        last_comment = Date.parse("1 Jan 0001");
                    }

                    if (Date.compare(last_like, last_comment) === 1) {
                        date_a = likes[0]?.updatedAt;
                    } else {
                        date_a = comments[0]?.updatedAt;
                    }
                }

                let date_b = b.updatedAt;
                if (b.user === this.props.currentUser) {
                    let likes = b.likes.items.sort((a, b) => {
                        return (a.updatedAt > b.updatedAt) ? -1 : ((a.updatedAt < b.updatedAt) ? 1 : 0);
                    });

                    let comments = b.comments.items.sort((a, b) => {
                        return (a.updatedAt > b.updatedAt) ? -1 : ((a.updatedAt < b.updatedAt) ? 1 : 0);
                    });

                    let last_like = Date.parse(likes[0]?.updatedAt);
                    if (!last_like) {
                        last_like = Date.parse("1 Jan 0001");
                    }
                    let last_comment = Date.parse(comments[0]?.updatedAt);
                    if (!last_comment) {
                        last_comment = Date.parse("1 Jan 0001");
                    }

                    if (Date.compare(last_like, last_comment) === 1) {
                        date_b = likes[0]?.updatedAt;
                    } else {
                        date_b = comments[0]?.updatedAt;
                    }
                }

                return (date_a > date_b) ? -1 : ((date_a < date_b) ? 1 : -1);
            });

            this.setState({
                followed_posts: posts
            }, () => this.generateNotifications());
        });
    }

    generateNotifications() {
        let posts = this.state.followed_posts;
        let time = localStorage.getItem("time");
        if(!time) {
            time = new Date(2020, 8);
        }
        let note_arr = [];
        for (let i = 0, size = posts.length; i < size; i++) {
            let language = this.props.language;
            let l = this.props.language;
            if (l === "Bahasa Malaysia") {
                l = "Malay";
            }
            l = l.toLowerCase();
            let d = "";

            if (posts[i][l] !== null) {
                d = posts[i][l];
            } else if (posts[i].english !== null) {
                d = posts[i].english;
            } else if (posts[i].rohingyalish !== null) {
                d = posts[i].rohingyalish;
            } else if (posts[i].malay !== null) {
                d = posts[i].malay;
            }

            let display = "block";
            let text = posts[i].user + " " + dict["has uploaded a new video"][this.props.language] + "!";
            let icon = <Icon.PersonCircle />;
            let dif = Date.compare(Date.parse(posts[i].updatedAt), Date.parse(time));
            let date = posts[i].updatedAt;
            if (posts[i].user === this.props.currentUser && (posts[i].likes?.items.length === 0 && posts[i].comments?.items.length === 0)) {
                display = "none"
            } else if (posts[i].user === this.props.currentUser && (posts[i].likes?.items.length >= 1 || posts[i].comments?.items.length >= 1)) {
                let last_like = Date.parse(posts[i].likes.items[0]?.updatedAt);
                if (!last_like) {
                    last_like = Date.parse("1 Jan 0001");
                }
                let last_comment = Date.parse(posts[i].comments.items[0]?.updatedAt);
                if (!last_comment) {
                    last_comment = Date.parse("1 Jan 0001");
                }

                if (Date.compare(last_like, last_comment) === 1) {
                    dif = Date.compare(Date.parse(last_like), Date.parse(time));
                    icon = <Icon.HandThumbsUp />;
                    date = last_like;
                    text = posts[i].likes.items?.length > 1 ? posts[i].likes.items?.length + " " + dict["people have liked your video"][language] + "!" : posts[i].likes.items?.length + " " + dict["person has liked your video"][language] + "!";
                } else {
                    dif = Date.compare(Date.parse(last_comment), Date.parse(time));
                    icon = <Icon.ChatLeftText />;
                    date = last_comment;
                    text = posts[i].comments.items?.length > 1 ? posts[i].comments.items?.length + " " + dict["people have commented on your video"][language] + "!" : posts[i].comments.items?.length + " " + dict["person has commented on your video"][language] + "!";
                }
            }

            let note = <div onClick={() => this.props.visitPost("video/" + posts[i].id)} className="notification" style={{ display: display, backgroundColor: dif === 1 ? "#cba42d" : "#5a5a5a60" }}>
                <div className="note_icon">
                    {icon}
                </div>
                <div className="note_content">
                    <div className="note">
                        {text}
                    </div>
                    <div className="date">
                        {Date.parse(date).toString("HH:mm, MMM dS yyyy")}
                    </div>
                    <div className="description" style={{ display: d === "" ? "none" : "" }}>
                        "{d}"
                </div>
                </div>
            </div>

            note_arr.push(note);
        }

        this.setState({
            notifications: note_arr
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.posts !== state.data
            || props.following !== state.following) {
            return {
                posts: props.posts,
                following: props.following
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.posts !== this.props.posts
            || props.following !== this.props.following) {
            this.setState({
                posts: this.props.posts,
                following: this.props.following
            }, () => {
                this.componentDidMount();
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.notifications.length > 0 ? this.state.notifications : <i className="no_notifications">{dict["You don't have any notifications"][this.props.language]}</i>}
            </div>
        );
    }

    componentWillUnmount() {
        localStorage.setItem("time", Date.parse(new Date()).toISOString());
    }
}

export default NOTIFICATIONS;