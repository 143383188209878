import React from "react";
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import deletePost from "../../constants/delete_post/deletePost";
import updatePost from "../../constants/posts/updatePost";
import LOADING from "../loading/loading";
import dict from "../misc/translations";
import MODAL from "../modal/modal";
import MODAL_BUTTON from "../modal/modal_button";
import { options } from "../video/options";
import Videojs from "../video/video_player";
import * as Icon from "react-bootstrap-icons";
import toggle_modal from "../modal/toggle_modal";
import updateDescriptions from "../../constants/posts/updateDescriptions";
import uk from "../../img/uk-icon.png";
import malaysia from "../../img/malaysia-icon.png";
import rohingya from "../../img/rohingyalish-icon.png";
import burmese from "../../img/burma-icon.png";

class POST_TILE extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            published: this.props.published,
            loading: false,
            updateEnglish: this.props.english,
            updateRohingyalish: this.props.rohingyalish,
            updateMalay: this.props.malay,
            updateBurmese: this.props.burmese
        }
    }

    publish = async () => {
        this.setState({
            published: !this.state.published
        }, async () => {
            let res = await updatePost(this.props.id, this.state.published);
            res.data.updatePost.src = this.props.src;
            res.data.updatePost.linked = true;
            this.props.refreshPosts(res.data.updatePost);
        });
    }

    async delete() {
        this.setState({
            loading: true
        });
        await deletePost(this.props.id);
        this.props.removePost(this.props.id);
        let modal = document.getElementById(this.props.id + "_modal");
        if (modal) {
            modal.style.display = "none";
        }
        this.setState({
            loading: false
        });
    }

    startUpdate() {
        this.setState({
            updateEnglish: this.props.english,
            updateRohingyalish: this.props.rohingyalish,
            updateMalay: this.props.malay,
            updateBurmese: this.state.updateBurmese
        });

        toggle_modal(this.props.id + "_modal");
        toggle_modal(this.props.id + "_update");
    }

    async pushUpdate() {
        try {
            let res = await updateDescriptions(this.props.id, this.state.updateEnglish, this.state.updateRohingyalish, this.state.updateMalay, this.state.updateBurmese);
            res.data.updatePost.linked = true;
            res.data.updatePost.src = this.props.src;
            this.props.updatePost(res.data.updatePost);
            toggle_modal(this.props.id + "_update");
        } catch (err) {
            window.location.href = "/error";
        }
    }

    render() {
        return (
            <div className="tile_wrap" key={this.props.i}>
                <LOADING show={this.state.loading} />
                <MODAL_BUTTON id={this.props.id + "_modal"}>
                    <div className="tile" style={{ borderBottom: this.state.published ? "10px solid green" : "10px solid #970000" }}>
                        <video autoPlay loop muted style={{ width: "100%", height: "auto" }}>
                            <source src={this.props.src} type="video/mp4" />
                        </video>
                        <div style={{ display: this.props.description !== "" ? "block" : "none" }} className="tile_description">
                            {this.props.description}
                        </div>
                    </div>
                </MODAL_BUTTON>
                <MODAL id={this.props.id + "_modal"}>
                    <div className="video_wrapper">
                        <Videojs {...options} sources={[{ src: this.props.src, type: 'video/mp4' }]} /><br />
                    </div>

                    <div className="toggle">
                        <div className="toggle-label">{dict["Publish"][this.props.language]}:</div>
                        <Toggle
                            defaultChecked={this.state.published}
                            onChange={this.publish} />
                    </div>

                    <button style={{ marginTop: "20px" }} className="primary" onClick={() => this.startUpdate()}><div className="button_icon"><Icon.ArrowRepeat /></div>{dict["Update"][this.props.language]}</button>
                    <br />
                    <button style={{ marginTop: "20px" }} className="secondary" onClick={() => this.delete()}><div className="button_icon"><Icon.FolderX /></div>{dict["Delete"][this.props.language]}</button>
                    {/* <Post id={this.props.id} date={this.props.createdAt} src={this.props.src} description={this.props.description} user={this.props.user} /> */}
                </MODAL>

                <MODAL id={this.props.id + "_update"}>
                    <div className="update_description">
                        <img className="country-icon" src={uk} alt="English" /><div className="country-description">{dict["English"][this.props.language]}:</div>
                        <br/>
                        <textarea value={this.state.updateEnglish} onChange={(e) => this.setState({ updateEnglish: e.currentTarget.value })}></textarea>
                    </div><br /><br/>
                    <div className="update_description">
                        <img className="country-icon" src={malaysia} alt="Bahasa Malaysia" /><div className="country-description">{dict["Bahasa Malaysia"][this.props.language]}:</div>
                        <br/>
                        <textarea value={this.state.updateMalay} onChange={(e) => this.setState({ updateMalay: e.currentTarget.value })}></textarea>
                    </div><br /><br />
                    <div className="update_description">
                        <img className="country-icon" src={rohingya} alt="Rohingyalish" /><div className="country-description">{dict["Rohingyalish"][this.props.language]}:</div>
                        <br/>
                        <textarea value={this.state.updateRohingyalish} onChange={(e) => this.setState({ updateRohingyalish: e.currentTarget.value })}></textarea>
                    </div><br /><br />
                    <div className="update_description">
                        <img className="country-icon" src={burmese} alt="Burmese" /><div className="country-description">{dict["Burmese"][this.props.language]}:</div>
                        <br/>
                        <textarea value={this.state.updateBurmese} onChange={(e) => this.setState({ updateBurmese: e.currentTarget.value })}></textarea>
                    </div><br /><br />
                    <button className="primary" onClick={() => this.pushUpdate()}><div className="button_icon"><Icon.ArrowRepeat /></div>{dict["Update"][this.props.language]}</button>
                    <hr />
                    <button className="secondary" onClick={() => this.startUpdate()}><div className="button_icon"><Icon.XCircle /></div>{dict["Cancel"][this.props.language]}</button>
                </MODAL>
            </div>
        );
    }
}

export default POST_TILE;