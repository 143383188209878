import { API, graphqlOperation } from "aws-amplify";
import { getPostsWithLikes } from "../../graphql/custom";

export default async function getOtherUserPosts(username) {
    const postData = await API.graphql(graphqlOperation(getPostsWithLikes, {
        filter: {
            user: {
                eq: username
            },
            published: {
                eq: true
            }
        }
    }));

    return postData.data.listPosts.items;
}