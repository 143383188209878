import { Analytics, Auth } from "aws-amplify";

export default async function updateUser(name, number, bio, language) {
    let user = await Auth.currentAuthenticatedUser();
    await Auth.currentCredentials();  // See https://github.com/aws/aws-amplify/issues/592
    let params = {
        name: name,
        phone_number: number,
        "custom:bio": bio,
        "custom:language_preference": language
    };
    let result = await Auth.updateUserAttributes(user, params);
    Analytics.record({name: "update_account"});
    console.log(result);
}