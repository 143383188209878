import remember from "../audio/upload/remember.m4a";
import face from "../audio/upload/face.m4a";
import location from "../audio/upload/location.m4a";
import upload from "../audio/upload/uploadVideo.m4a";
import english from "../audio/upload/addEnglish.m4a";
import rohingyalish from "../audio/upload/addRohingyalish.m4a";
import malay from "../audio/upload/addMalay.m4a";
import burmese from "../audio/upload/addBurmese.m4a";
import thanks from "../audio/upload/thankyou.m4a";

const upload_audio = {
    "remember": {
        "English": new Audio(remember),
        "Rohingyalish": new Audio(),
        "Malay": new Audio(),
        "Burmese": new Audio()
    },

    "face": {
        "English": new Audio(face),
        "Rohingyalish": new Audio(),
        "Malay": new Audio(),
        "Burmese": new Audio()
    },

    "location": {
        "English": new Audio(location),
        "Rohingyalish": new Audio(),
        "Malay": new Audio(),
        "Burmese": new Audio()
    },

    "upload": {
        "English": new Audio(upload),
        "Rohingyalish": new Audio(),
        "Malay": new Audio(),
        "Burmese": new Audio()
    },

    "english": {
        "English": new Audio(english),
        "Rohingyalish": new Audio(),
        "Malay": new Audio(),
        "Burmese": new Audio()
    },

    "rohingyalish": {
        "English": new Audio(rohingyalish),
        "Rohingyalish": new Audio(),
        "Malay": new Audio(),
        "Burmese": new Audio()
    },

    "malay": {
        "English": new Audio(malay),
        "Rohingyalish": new Audio(),
        "Malay": new Audio(),
        "Burmese": new Audio()
    },

    "burmese": {
        "English": new Audio(burmese),
        "Rohingyalish": new Audio(),
        "Malay": new Audio(),
        "Burmese": new Audio()
    },

    "thanks": {
        "English": new Audio(thanks),
        "Rohingyalish": new Audio(),
        "Malay": new Audio(),
        "Burmese": new Audio()
    },
};

export default upload_audio;