import React from "react";
import dict from "../misc/translations";
import MODAL_BUTTON from "../modal/modal_button";
import ENDORSEMENTS from "./endorsements";
import * as Icon from "react-bootstrap-icons";
import MODAL from "../modal/modal";
import { API } from "aws-amplify";
import { createEndorsement } from "../../graphql/mutations";
import delete_skill from "../../constants/delete_skill/deleteSkill";
import LOADING from "../loading/loading";
import toggle_modal from "../modal/toggle_modal";
import emoji from "../misc/emoji";
import play_audio from "../misc/play_audio";
import hide_audio from "../settings/hide_audio";

class SKILLS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            endorsements: [],
            data: this.props.data,
            loading: false
        };
    }

    componentDidMount() {
        this.createEndorsements(this.state.data.endorsements.items);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data !== state.data) {
            return {
                data: props.data
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.data !== this.props.data) {
            this.setState({
                data: this.props.data
            });
            this.componentDidMount();
        }
    }

    async createEndorsements(e) {
        let e_arr = [];

        e = e.sort((a, b) => {
            return (a.updatedAt > b.updatedAt) ? -1 : ((a.updatedAt < b.updatedAt) ? 1 : 0);
        });

        for (let i = 0, size = e.length; i < size; i++) {
            let en = <ENDORSEMENTS build={() => this.props.build()} audio={this.props.audio} refresh={this.props.refresh} currentUser={this.props.currentUser} language={this.props.language} visitUser={this.props.visitUser} e={e[i]} />;
            e_arr.push(en);
        }

        this.setState({
            endorsements: e_arr
        });
    }

    async endorse() {
        let param = {
            endorsementSkillId: this.props.data.id,
            user: this.props.currentUser,
            about: this.props.user,
            review: this.state.review,
            report: false
        }

        await API.graphql({ query: createEndorsement, variables: { input: param } });
        document.getElementById(this.props.data.id + "_endorsement").style.display = "none";
        this.props.refresh();
    }

    removeSkill() {
        this.setState({
            loading: true
        }, async () => {
            await delete_skill(this.props.currentUser, this.props.data.id);
            toggle_modal(this.props.data.id + "_delete");
            this.props.refresh();
            this.setState({
                loading: false
            });
        });
    }

    render() {
        let spoken = localStorage.getItem("preferred_spoken_language") || "English";
        
        let delete_skill = this.props?.audio["delete_skill"][spoken];

        hide_audio();

        return (
            <div className="skill">
                <LOADING show={this.state.loading} />
                <div className="skill_heading">
                    {emoji[this.props.data.skill]} {dict[this.props.data.skill[0].toUpperCase() + this.props.data.skill.slice(1)][this.props.language]}
                </div>

                {window.location.pathname !== "/account" && window.location.pathname !== "/" + this.props.currentUser
                    ?
                    <div style={{ display: "inline-block" }}>
                        <MODAL_BUTTON id={this.props.data.id + "_endorsement"}>
                            <button className="primary">
                                <div className="button_icon"><Icon.HandThumbsUp /></div>{dict["Endorse"][this.props.language]}
                            </button>
                        </MODAL_BUTTON>
                        <MODAL id={this.props.data.id + "_endorsement"}>
                            {/* {dict["What do you think of"][this.props.language]} {this.props.user.username}{dict["'s talent"][this.props.language]}? */}
                            <br/>
                            <textarea onChange={e => {
                                this.setState({ review: e.currentTarget.value });
                            }} placeholder={dict["Leave a review"][this.props.language] + "..."}></textarea>
                            <br/>
                            <button className="primary" onClick={() => this.endorse()}><div className="button_icon"><Icon.HandThumbsUp /></div>{dict["Endorse"][this.props.language]}</button>
                            <hr />
                            <button className="secondary" onClick={() => toggle_modal(this.props.data.id + "_endorsement")}><div className="button_icon"><Icon.XCircle /></div>{dict["Cancel"][this.props.language]}</button>
                        </MODAL>
                    </div>
                    :
                    <div style={{ display: "inline-block" }}>
                        <MODAL_BUTTON id={this.props.data.id + "_delete"}>
                            <button className="primary">
                                <div className="button_icon"><Icon.DashCircle /></div>{dict["Delete"][this.props.language]}
                            </button>
                        </MODAL_BUTTON>
                        <MODAL id={this.props.data.id + "_delete"}>
                            <br /><div>{dict["Are you sure you want to delete your skill? Any endorsements will be lost too"][this.props.language]}.</div><br />
                            <div className="audio" onClick={() => play_audio(delete_skill)}><Icon.VolumeUp /></div>
                            <div className="audio_br"></div>
                            <button onClick={() => this.removeSkill()} className="primary">
                                <div className="button_icon"><Icon.DashCircle /></div>{dict["Delete"][this.props.language]}
                            </button>
                            <hr />
                            <button className="secondary" onClick={() => toggle_modal(this.props.data.id + "_delete")}><div className="button_icon"><Icon.XCircle /></div>{dict["Cancel"][this.props.language]}</button>
                        </MODAL>
                    </div>
                }

                <div style={{ display: this.state.endorsements.length > 0 ? "block" : "none", marginTop: "10px" }}>
                    {this.state.endorsements}
                </div>
            </div>
        );
    }
}

export default SKILLS;