import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AUTH from "./components/auth/auth";
import Main from "./components/main";

import Lost from "./components/lost/lost";

import { Offline, Online } from "react-detect-offline";
import NO_WIFI from './components/no_wifi/no_wifi';
import Error from './components/error/error';
import COOKIE_POLICY from './components/t_and_c/cookie_policy';
import USER_AGREEMENT from './components/t_and_c/user_agreement';
import PRIVACY_POLICY from './components/t_and_c/privacy_policy';
import Chrome from "./components/install/chrome";
import IOS from './components/install/ios';
import FireFox from './components/install/firefox';
import COOKIE_POPUP from './components/t_and_c/cookie_popup';
import tc_audio from './components/t_and_c/audio';
import Current_User from './components/auth/current_user';

import "./root.css";
import "./index.sass";

const { detect } = require('detect-browser');
const browser = detect();

function getInstalled() {
	return window.matchMedia('(display-mode: standalone)').matches;
}

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			prompt: null,
			tc_audio: null,
			language: "English",
			file: null
		};
	}

	async componentDidMount() {
		await Current_User((user) => this.setState({ language: user?.attributes ? user?.attributes["custom:language_preference"] : "English" }, () => this.setFont(this.state.language)), "/");

		this.getPrompt();
		if (process.env.NODE_ENV === "production") {
			console.log = function () { };
		}

		let audio = await tc_audio();
		this.setState({
			tc_audio: audio
		});
	}

	setFont(language) {
		let root = document.documentElement;
		root.style.setProperty('--sans', language === "Hanifi" ? 'hanifi' : "'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif");
		root.style.setProperty('--name', language === "Hanifi" ? 'hanifi' : "'Quicksand', sans-serif");
	}

	getPrompt() {
		if (!getInstalled()) {
			if (browser.name === "chrome") {
				window.addEventListener('beforeinstallprompt', (e) => {
					e.preventDefault();
					this.setState({ prompt: <Chrome deferredPrompt={e} /> });
				});
			} else if (browser.name === "firefox") {
				this.setState({ prompt: <FireFox /> });
			} else if (browser.name === "safari") {
				this.setState({ prompt: <IOS /> });
			}
		}
	}

	render() {
		return (
			<div className="App">
				{/* <div ><Snake width={window.innerWidth}/></div> */}
				<Online>
					<Switch>
						<Route exact path="/" component={AUTH}></Route>

						<Route path={"/login"}><AUTH display="Login" /></Route>
						<Route path={"/register"}><AUTH display="Register" /></Route>
						<Route path={"/verify"}><AUTH display="Verify" /></Route>
						<Route path={"/forgot_password"}><AUTH display="Forgot_password" /></Route>
						<Route path={"/password_reset"}><AUTH display="Password_reset" /></Route>

						<Route path={"/videos"}><Main display="videos" rerender={() => this.forceUpdate()} /></Route>
						<Route path={"/account"}><Main display="account" rerender={() => this.forceUpdate()} /></Route>
						<Route path={"/settings"}><Main display="settings" changeFont={(e) => this.setFont(e)} rerender={() => this.forceUpdate()} /></Route>
						<Route path={"/new_video"}><Main display="new_video" rerender={() => this.forceUpdate()} /></Route>
						<Route path={"/upload_position"}><Main display="upload_position" rerender={() => this.forceUpdate()} /></Route>
						<Route path={"/user/:id"} render={(matchProps => <Main display="user" {...matchProps} rerender={() => this.forceUpdate()} />)}></Route>
						{/* <Route path={"/user"}><Main display="user" rerender={() => this.forceUpdate()} /></Route> */}
						<Route path={"/video/:id"} render={(matchProps => <Main display="video" {...matchProps} rerender={() => this.forceUpdate()} />)}></Route>
						<Route path={"/notifications"}><Main display="notifications" rerender={() => this.forceUpdate()} /></Route>
						<Route path={"/saved"}><Main display="saved" rerender={() => this.forceUpdate()} /></Route>
						<Route path={"/help"}><Main display="help" rerender={() => this.forceUpdate()} /></Route>

						<Route path="/error" component={Error}></Route>
						<Route path={"/:id"} render={(matchProps => <Main display="user" {...matchProps} rerender={() => this.forceUpdate()} />)}></Route>

						<Route path="/lost" component={Lost}></Route>
					</Switch>

					{this.state.prompt}

					<COOKIE_POPUP />

					{this.state.tc_audio !== null ?
						<div>
							<COOKIE_POLICY audio={this.state.tc_audio} />
							<USER_AGREEMENT audio={this.state.tc_audio} />
							<PRIVACY_POLICY audio={this.state.tc_audio} />
						</div>
						: null}
				</Online>
				<Offline>
					<NO_WIFI refresh={() => this.render()} />
				</Offline>
			</div>
		);
	}
}

export default App;
