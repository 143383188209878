import React from "react";
import MODAL from "../modal/modal";
import * as Icon from "react-bootstrap-icons";
import toggle_modal from "../modal/toggle_modal";
import dict from "../misc/translations";
import uk from "../../img/uk-icon.png";
import malaysia from "../../img/malaysia-icon.png";
import rohingya from "../../img/rohingyalish-icon.png";
import burma from "../../img/burma-icon.png";

class FireFox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            language: "English"
        };
    }

    showAgain() {
        if (this.state.show) {
            localStorage.setItem("install_do_not_show", true);
        }
        toggle_modal("firefox_modal");
    }

    render() {
        let language = this.state.language;
        return (
            <MODAL id="firefox_modal" onModalClose={() => this.showAgain()} auto>
                <div className="auth_languages">
                    <img alt="English" onClick={() => this.setState({ language: "English" })} style={{ border: this.state.language === "English" ? "2px solid #cba42d" : "" }} className="language_icon" src={uk} />
                    <img alt="Bahasa Malaysia" onClick={() => this.setState({ language: "Bahasa Malaysia" })} style={{ border: this.state.language === "Bahasa Malaysia" ? "2px solid #cba42d" : "" }} className="language_icon" src={malaysia} />
                    <img alt="Rohingyalish" onClick={() => this.setState({ language: "Rohingyalish" })} style={{ border: this.state.language === "Rohingyalish" ? "2px solid #cba42d" : "" }} className="language_icon" src={rohingya} />
                    <img alt="Burmese" onClick={() => this.setState({ language: "Burmese" })} style={{ border: this.state.language === "Burmese" ? "2px solid #cba42d" : "" }} className="language_icon" src={burma} />
                </div>
                <div className="install_icon"><Icon.BoxArrowInDown /></div>
                <div className="heading">{dict["Install TalentEd"][language]}?</div>
                <div className="install_reason">
                    {dict["Install this application on your home screen for quick and easy access"][language]}
                </div>
                <input onChange={() => this.setState({ show: !this.state.show })} className="checkbox" type="checkbox"></input>
                <label>{dict["Don't show this again"][language]}</label>
                <br /><br />
                <div className="install_banner">
                    {dict["Just tap"][language]} <div className="firefox_share"><Icon.ThreeDotsVertical /></div> {dict["then 'Install'"][language]}
                </div>
            </MODAL>
        )
    }
}

export default FireFox;