import { Storage } from "aws-amplify";
import React from "react";
import POST from "../feed/post";
import LOADING from "../loading/loading";
import POSITION from "../positions/post";
import USER_ICON from "./user_icon";
import * as Icon from "react-bootstrap-icons";
import dict from "../misc/translations";
import play_audio from "../misc/play_audio";

class SEARCH extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            positions: [],
            postData: null,
            posts: [],
            loading: true,
            search: null,
            filter: []
        };
    }

    componentDidMount() {
        let users = this.props.users;
        let positions = this.props.positions;
        let posts = this.props.posts;
        let search = this.props.search;

        let returnedUsers = [];
        let returnedPositions = [];
        let returnedPosts = [];

        let condition = new RegExp(search.toLowerCase());

        returnedUsers = this.filterAndSort(users, "Username", condition, search);

        returnedPositions = returnedPositions.concat(this.filterAndSort(positions, "title", condition, search));
        returnedPositions = returnedPositions.concat(this.filterAndSort(positions, "location", condition, search));
        returnedPositions = returnedPositions.concat(this.filterAndSort(positions, "english", condition, search));
        returnedPositions = returnedPositions.concat(this.filterAndSort(positions, "rohingyalish", condition, search));
        returnedPositions = returnedPositions.concat(this.filterAndSort(positions, "malay", condition, search));

        returnedPosts = returnedPosts.concat(this.filterAndSort(posts, "english", condition, search));
        returnedPosts = returnedPosts.concat(this.filterAndSort(posts, "rohingyalish", condition, search));
        returnedPosts = returnedPosts.concat(this.filterAndSort(posts, "malay", condition, search));

        // Remove all duplicates by converting to sets and then back
        returnedUsers = new Set(returnedUsers);
        returnedUsers = [...returnedUsers];
        returnedPositions = new Set(returnedPositions);
        returnedPositions = [...returnedPositions];
        returnedPosts = new Set(returnedPosts);
        returnedPosts = [...returnedPosts];

        this.handle(returnedUsers, returnedPositions, returnedPosts, search);
    }

    filterAndSort(arr, path, condition, search) {
        return arr.filter(function (el) {
            return condition.test(el[path]?.toLowerCase());
        }).sort(function (a, b) {
            if (a[path]?.toLowerCase() && b[path]?.toLowerCase()) {
                return this.levDist(a[path]?.toLowerCase(), search) - this.levDist(b[path]?.toLowerCase(), search)
            }
        }.bind(this));
    }

    // Damerau-Levenshtein sorting algorithm
    levDist = (s, t) => {
        let d = []; //2d matrix

        // Step 1
        let n = s.length;
        let m = t.length;

        if (n === 0) return m;
        if (m === 0) return n;

        //Create an array of arrays in javascript (a descending loop is quicker)
        for (let i = n; i >= 0; i--) d[i] = [];

        // Step 2
        for (let i = n; i >= 0; i--) d[i][0] = i;
        for (let j = m; j >= 0; j--) d[0][j] = j;

        // Step 3
        for (let i = 1; i <= n; i++) {
            let s_i = s.charAt(i - 1);

            // Step 4
            for (let j = 1; j <= m; j++) {

                //Check the jagged ld total so far
                if (i === j && d[i][j] > 4) return n;

                let t_j = t.charAt(j - 1);
                let cost = (s_i === t_j) ? 0 : 1; // Step 5

                //Calculate the minimum
                let mi = d[i - 1][j] + 1;
                let b = d[i][j - 1] + 1;
                let c = d[i - 1][j - 1] + cost;

                if (b < mi) mi = b;
                if (c < mi) mi = c;

                d[i][j] = mi; // Step 6

                //Damerau transposition
                if (i > 2 && j > 2 && s_i === t.charAt(j - 2) && s.charAt(i - 2) === t_j) {
                    d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
                }
            }
        }

        // Step 7
        return d[n][m];
    }

    static getDerivedStateFromProps(props, state) {
        if (props.search !== state.search || props.posts !== state.posts || props.audio !== state.audio) {
            return {
                search: props.search,
                postData: props.posts,
                audio: props.audio
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.search !== this.props.search || props.posts !== this.props.posts || props.audio !== this.props.audio) {
            this.setState({
                search: this.props.search,
                postData: this.props.posts,
                audio: this.props.audio
                // posts: [],
                // positions: [],
                // users: []
            });
            this.componentDidMount();
        }
    }

    async handle(users, positions, posts, search) {
        users = await this.userIcon(users);
        positions = await this.generate(positions, "positions");
        posts = await this.generate(posts, "posts");

        this.setState({
            users: users,
            positions: positions,
            posts: posts,
            loading: false,
            search: search
        });
    }

    async userIcon(users) {
        let user_arr = [];
        for (let i = 0, size = users.length; i < size; i++) {
            let user = <USER_ICON link={() => {
                this.props.link(users[i].Username);
                this.props.clearSearch();
            }} user={users[i].Username} />;
            user_arr.push(user);
        }

        return user_arr;
    }

    async generate(data, type) {
        if (this.state.audio !== null) {
            let pos_arr = [];

            for (let i = 0, size = data.length; i < size; i++) {
                let n = data[i];
                let language = this.props.language;
                if (language === "Bahasa Malaysia") {
                    language = "Malay";
                }
                language = language.toLowerCase();
                let d = "";

                if (n[language] !== null) {
                    d = n[language];
                } else if (n.english !== null) {
                    d = n.english;
                } else if (n.rohingyalish !== null) {
                    d = n.rohingyalish;
                } else if (n.malay !== null) {
                    d = n.malay;
                }

                let src = null;
                if (n.src !== null) {
                    src = n.src;
                    if (!n.linked) {
                        src = await Storage.get(n.src);
                    }
                }

                let pos = null;
                if (type === "positions") {
                    pos = <POSITION language={this.props.language} src={src} id={n.id} date={n.createdAt} user={n.user} title={n.title} location={n.location} description={d} />;
                } else {
                    pos = <POST
                        language={this.props.language}
                        saves={n.saves}
                        comments={n.comments}
                        currentUser={this.props.currentUser}
                        visitUser={this.props.visitUser}
                        visitPost={this.props.visitPost}
                        refreshPosts={this.props.refreshPosts}
                        post={n}
                        likes={n.likes.items}
                        id={n.id}
                        date={n.createdAt}
                        user={n.user}
                        src={src}
                        file={n.src}
                        description={d}
                        audio={this.props.feed_audio} />;
                }

                pos_arr.push(pos);
            }

            return pos_arr;
        } else {
            return false;
        }
    }

    filter(filter) {
        let filter_arr = this.state.filter;
        if (filter_arr.includes(filter)) {
            filter_arr.splice(filter_arr.indexOf(filter), 1);
        } else {
            filter_arr.push(filter);
        }

        this.setState({
            filter: filter_arr
        });
    }

    render() {
        if (this.state.audio !== null) {
            let spoken = localStorage.getItem("preferred_spoken_language") || "English";
            let filter = this.state.audio["filter"][spoken];
            let no_results = this.state.audio["no_results"][spoken];

            return (
                <div>
                    <div className="filter_btns">
                        {dict["Filter your search"][this.props.language]}:<div style={{ color: "#e9e9e9", transform: "scale(0.6)", marginLeft: "0px" }} className="audio" onClick={() => play_audio(filter)}><Icon.VolumeUp /></div><br />
                        <div className="filter_btn" onClick={() => this.filter("users")} style={{ backgroundColor: this.state.filter.includes("users") ? "#cba42d" : "", color: this.state.filter.includes("users") ? "black" : "" }}>
                            {dict["Users"][this.props.language]}
                            <div className="plus">{this.state.filter.includes("users") ? <Icon.X /> : <Icon.Plus />}</div>
                        </div>
                        <div className="filter_btn" onClick={() => this.filter("posts")} style={{ backgroundColor: this.state.filter.includes("posts") ? "#cba42d" : "", color: this.state.filter.includes("posts") ? "black" : "" }}>
                            {dict["Videos"][this.props.language]}
                            <div className="plus">{this.state.filter.includes("posts") ? <Icon.X /> : <Icon.Plus />}</div>
                        </div>
                        {/* <div className="filter_btn" onClick={() => this.filter("positions")} style={{ backgroundColor: this.state.filter.includes("positions") ? "#cba42d" : "", color: this.state.filter.includes("positions") ? "black" : "" }}>
                        {dict["Positions"][this.props.language]}
                        <div className="plus">{this.state.filter.includes("positions") ? <Icon.X /> : <Icon.Plus />}</div>
                    </div> */}
                    </div>
                    <LOADING show={this.state.loading} />
                    <div style={{ display: this.state.filter.length === 0 || this.state.filter.includes("users") ? "block" : "none" }}>{this.state.users}</div>
                    <div style={{ display: this.state.filter.length === 0 || this.state.filter.includes("posts") ? "block" : "none" }}>{this.state.posts}</div>
                    <div style={{ display: this.state.filter.length === 0 || this.state.filter.includes("positions") ? "block" : "none" }}>{this.state.positions}</div>
                    <div className="no_results" style={{ display: this.state.users?.length === 0 && this.state.positions?.length === 0 && this.state.posts?.length === 0 ? "block" : "none" }}>
                        <div style={{ color: "#e9e9e9" }} className="audio" onClick={() => play_audio(no_results)}><Icon.VolumeUp /></div><br />
                        {dict["Sorry, there were no results found for"][this.props.language]} "{this.state.search}"
                </div>
                </div>
            );
        } else {
            return <LOADING />
        }
    }
}

export default SEARCH;