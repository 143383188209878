import React from "react";
import * as Icon from "react-bootstrap-icons";

class USER_ICON extends React.Component {
    render() {
        return (
            <div className="user_icon" onClick={this.props.link}>
                <div className="icon"><Icon.PersonCircle /></div> {this.props.user}
            </div>
        );
    }
}

export default USER_ICON;