import React from "react";
import DELETE_MODAL from "../account/delete_modal";
import LANGUAGE_RADIO from "../misc/language_radio";
import AUDIO_RADIO from "../misc/audio_radio";
import dict from "../misc/translations";
import * as Icon from 'react-bootstrap-icons';
import play_audio from "../misc/play_audio";
import tick from "../../img/icon-tick.svg";
import hide_audio from "./hide_audio";
import LOADING from "../loading/loading";

class SETTINGS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hide: false,
            audio: null
        };
    }

    componentDidMount() {
        let n = JSON.parse(localStorage.getItem("hide_audio_icons"));
        if (!n) {
            n = false;
        }

        this.setState({
            hide: n
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.audio !== state.audio) {
            return {
                audio: props.audio
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.audio !== this.props.audio) {
            this.setState({
                audio: this.props.audio
            });
        }
    }

    hideIcons() {
        let n = false;
        if (this.state.hide) {
            n = false;
        } else {
            n = true;
        }

        localStorage.setItem("hide_audio_icons", n);

        this.setState({
            hide: n
        }, () => {
            this.props.rerender();
        });
    }

    render() {
        if (this.state.audio !== null) {
            let audio = localStorage.getItem("preferred_spoken_language") || "English";
            let language_settings = this.state.audio["language_settings"][audio];
            let audio_settings = this.state.audio["audio_settings"][audio];
            let account_settings = this.state.audio["account_settings"][audio];
            let hide_audio_audio = this.state.audio["hide_audio"][audio];

            hide_audio();

            return (
                <div className="settings">
                    <div className="section">
                        <div className="heading">
                            <div className="audio_text" style={{ width: JSON.parse(localStorage.getItem("hide_audio_icons")) ? "100%" : "" }}><div className="section_icon"><Icon.Spellcheck /></div>{dict["Language settings"][this.props.language]}</div>
                            <div className="audio" onClick={() => play_audio(language_settings)}><Icon.VolumeUp /></div>
                        </div>
                        <LANGUAGE_RADIO changeFont={(e) => this.props.changeFont(e)} rerender={() => this.props.rerender()} refresh={this.props.refresh} number={this.props.number} name={this.props.name} bio={this.props.bio} language={this.props.language} />
                    </div>

                    <div className="section">
                        <div className="heading">
                            <div className="audio_text" style={{ width: JSON.parse(localStorage.getItem("hide_audio_icons")) ? "100%" : "" }}><div className="section_icon"><Icon.VolumeUp /></div>{dict["Audio settings"][this.props.language]}</div>
                            <div className="audio" onClick={() => play_audio(audio_settings)}><Icon.VolumeUp /></div>
                        </div>
                        <div style={{ textAlign: "Left", marginBottom: "20px" }}>
                            <div onClick={() => this.hideIcons()} className="settings_check">
                                <img style={{ display: this.state.hide === true ? "block" : "none" }} className="settings_tick" src={tick} alt="Tick" />
                            </div>
                            <div className="check_label">{dict["Hide audio icons"][this.props.language]}</div>
                            <div style={{ color: "#5a5a5a", borderColor: "#5a5a5a" }} className="audio" onClick={() => play_audio(hide_audio_audio)}><Icon.VolumeUp /></div>
                        </div>
                        <AUDIO_RADIO rerender={() => this.props.rerender()} refresh={this.props.refresh} number={this.props.number} name={this.props.name} bio={this.props.bio} language={this.props.language} />
                    </div>

                    <div className="section">
                        <div className="heading">
                            <div className="audio_text" style={{ width: JSON.parse(localStorage.getItem("hide_audio_icons")) ? "100%" : "" }}><div className="section_icon"><Icon.Person /></div>{dict["Account settings"][this.props.language]}</div>
                            <div className="audio" onClick={() => play_audio(account_settings)}><Icon.VolumeUp /></div>
                        </div>
                        <DELETE_MODAL audio={this.state.audio} id="user_delete" username={this.props.username} language={this.props.language} />
                    </div>
                </div>
            );
        } else {
            return <LOADING />;
        }
    }
}

export default SETTINGS;