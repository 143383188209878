import Storage from "@aws-amplify/storage";

export default async function settings_audio() {
    return ({
        "language_settings": {
            "English": new Audio(await Storage.get("audio/settings/English/languageSettings.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "audio_settings": {
            "English": new Audio(await Storage.get("audio/settings/English/audioSettings.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "account_settings": {
            "English": new Audio(await Storage.get("audio/settings/English/accountSettings.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "delete_account": {
            "English": new Audio(await Storage.get("audio/settings/English/deleteAccount.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "hide_audio": {
            "English": new Audio(await Storage.get("audio/settings/English/hideIcons.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },
    })
}