import React from "react";
import { elastic as M } from 'react-burger-menu';
import logo from "../../img/logo-new.svg";
import LOGOUT from "../auth/logout";
import history from "../../history";
import dict from "../misc/translations";
import * as Icon from 'react-bootstrap-icons';
import MODAL_BUTTON from "../modal/modal_button";
import TE from "../../img/TE.svg";
import TE2 from "../../img/TE2.svg";

class Menu extends React.Component {
    render() {
        let language = this.props.language;

        return (
            <M right pageWrapId={this.props.pageWrapId} outerContainerId={this.props.outerContainerId}>
                <div className="slidemenu">
                    <div className="x" onClick={() => {
                        this.props.feed();
                        document.querySelector('.bm-cross-button button').click();
                    }}>
                        <Icon.X />
                    </div>

                    <img alt="Logo" className="logo" src={logo} />
                    <div className="heading">TalentEd</div>
                    <hr />

                    <div onClick={() => {
                        this.props.feed();
                        document.querySelector('.bm-cross-button button').click();
                        history.push("/videos");
                    }}
                        className="item"
                        style={{ color: this.props.current === "news_feed" ? "#cba42d" : "#292929" }}><div className="menu_icon">
                            {this.props.current === "news_feed" ? <img className="te_icon" src={TE2} alt="TalentEd" /> : <img className="te_icon" src={TE} alt="TalentEd" /> }
                        </div>{dict["Videos"][language]}</div>

                    {/* <div onClick={() => {
                        this.props.positions();
                        document.querySelector('.bm-cross-button button').click();
                        history.push("/positions");
                    }}
                        className="item"
                        style={{ color: this.props.current === "positions" ? "#cba42d" : "#292929" }}><div className="menu_icon"><Icon.Briefcase /></div>{dict["Positions"][language]}</div> */}

                    {/* <hr /> */}

                    <div onClick={() => {
                        this.props.saved();
                        document.querySelector('.bm-cross-button button').click();
                        history.push("/saved");
                    }}
                        className="item"
                        style={{ color: this.props.current === "saved" ? "#cba42d" : "#292929" }}><div className="menu_icon"><Icon.Star /></div>{dict["Saved"][language]}</div>

                    <div onClick={() => {
                        this.props.notifications();
                        document.querySelector('.bm-cross-button button').click();
                        history.push("/notifications");
                    }}
                        className="item"
                        style={{ color: this.props.current === "notifications" ? "#cba42d" : "#292929" }}><div className="menu_icon"><Icon.Bell /></div>{dict["Notifications"][language]}</div>

                    <div onClick={() => {
                        this.props.account();
                        document.querySelector('.bm-cross-button button').click();
                        history.push("/account");
                    }}
                        className="item"
                        style={{ color: this.props.current === "account" ? "#cba42d" : "#292929" }}><div className="menu_icon"><Icon.Person /></div>{dict["Account"][language]}</div>

                    <hr />

                    <div onClick={() => {
                        this.props.help();
                        document.querySelector('.bm-cross-button button').click();
                        history.push("/help");
                    }}
                        className="item"
                        style={{ color: this.props.current === "help" ? "#cba42d" : "#292929" }}><div className="menu_icon"><Icon.QuestionCircle /></div>{dict["Help"][language]}</div>

                    <div onClick={() => {
                        this.props.settings();
                        document.querySelector('.bm-cross-button button').click();
                        history.push("/settings");
                    }}
                        className="item"
                        style={{ color: this.props.current === "settings" ? "#cba42d" : "#292929" }}><div className="menu_icon"><Icon.Gear /></div>{dict["Settings"][language]}</div>

                    <hr />

                    <div className="item"><LOGOUT language={language} /></div>

                    <hr />

                    <div onClick={() => document.querySelector('.bm-cross-button button').click()}>
                        <MODAL_BUTTON id="cookie_policy"><div className="item small">{dict["Cookie Policy"][language]}</div></MODAL_BUTTON>
                    </div>

                    <div onClick={() => document.querySelector('.bm-cross-button button').click()}>
                        <MODAL_BUTTON id="user_agreement"><div className="item small">{dict["User Agreement"][language]}</div></MODAL_BUTTON>
                    </div>

                    <div onClick={() => document.querySelector('.bm-cross-button button').click()}>
                        <MODAL_BUTTON id="privacy_policy"><div className="item small">{dict["Privacy Policy"][language]}</div></MODAL_BUTTON>
                    </div>
                </div>
            </M>
        );
    }
}

export default Menu;