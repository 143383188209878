import React from "react";
import * as Icon from "react-bootstrap-icons";

class Error extends React.Component {
    render() {
        return (
            <div className="no_wifi_wrap">
                <div className="encompass">
                    <div className="body_wrap">
                        <div className="body">
                            <div className="no_wifi">
                                <div className="no_wifi_heading">
                                    Sorry, something has gone wrong
                                </div>
                                <br/>
                                <div className="no_wifi_icon">
                                    <Icon.ExclamationCircle />
                                </div>
                                <button className="primary" onClick={() => window.location.href = "/"}>
                                    Reload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Error;