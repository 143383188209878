import Storage from "@aws-amplify/storage";

export default async function search_audio() {
    return ({
        "filter": {
            "English": new Audio(await Storage.get("audio/search/English/filter.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "no_results": {
            "English": new Audio(await Storage.get("audio/search/English/noResults.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },
    })
}