import React from "react";
import ukicon from "../../img/uk-icon.png";
// import rohingyaicon from "../../img/rohingyalish-icon.png";
// import malaysiaicon from "../../img/malaysia-icon.png";
// import burmaicon from "../../img/burma-icon.png";
import tick from "../../img/icon-tick.svg";
import dict from "./translations";

class AUDIO_RADIO extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spoken: localStorage.getItem("preferred_spoken_language") || "English",
        }
    }

    selectLanguage(language) {
        localStorage.setItem("preferred_spoken_language", language);
        this.setState({
            spoken: language
        }, () => {
            this.props.rerender();
        });
    }

    render() {
        let language = this.props.language;
        return (
            <div>
                <div className="language_radio">
                    <div onClick={() => this.selectLanguage("English")} className="item"><img alt="English" className="item-icon" src={ukicon} /> {dict["English"][language]} <img alt="Tick" src={tick} className="tick" style={{ display: this.state.spoken === "English" ? "inline-block" : "none" }} /></div>
                    {/* <hr /> */}
                    {/* <div onClick={() => this.selectLanguage("Malay")} className="item"><img alt="Malay" className="item-icon" src={malaysiaicon} /> {dict["Malay"][language]} <img alt="Tick" src={tick} className="tick" style={{ display: this.state.spoken === "Malay" ? "inline-block" : "none" }} /></div>
                    <hr /> */}
                    {/* <div onClick={() => this.selectLanguage("Rohingyalish")} className="item"><img alt="Rohingyalish" className="item-icon" src={rohingyaicon} /> {dict["Rohingyalish"][language]} <img alt="Tick" src={tick} className="tick" style={{ display: this.state.spoken === "Rohingyalish" ? "inline-block" : "none" }} /></div> */}
                    {/* <hr />
                    <div onClick={() => this.selectLanguage("Burmese")} className="item"><img alt="Burmese" className="item-icon" src={burmaicon} /> {dict["Burmese"][language]} <img alt="Tick" src={tick} className="tick" style={{ display: this.state.spoken === "Burmese" ? "inline-block" : "none" }} /></div> */}
                </div>
            </div>
        );
    }
}

export default AUDIO_RADIO;