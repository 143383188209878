import React from "react";
import * as Icon from "react-bootstrap-icons";
import { API, Storage } from "aws-amplify";
import Position from "../positions/post";
import Post from "../feed/post";
import getOtherUser from "../../constants/user/getOtherUser";
import LOADING from "../loading/loading";
import { createFollow, deleteFollow } from "../../graphql/mutations";
import MODAL from "../modal/modal";
import Followers from "./followers";
import MODAL_BUTTON from "../modal/modal_button";
import Following from "./following";
import dict from "../misc/translations";
import Skills from "./skills";
import play_audio from "../misc/play_audio";

class USER extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.user,
            user: null,
            build: null,
            loading: true,
            followID: null,
            review: "",
            audio: this.props.audio,
            feed_audio: this.props.feed_audio,
            account_audio: this.props.account_audio
        };
    }

    async componentDidMount() {
        if (this.props.audio !== null) {
            await getOtherUser(this.props.user, e => {
                this.setState({
                    user: e,
                    build: null
                }, () => {
                    this.build();
                    console.log(this.state.user)
                });
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.user !== state.user || props.audio !== state.audio || props.feed_audio !== state.feed_audio || props.account_audio !== state.account_audio) {
            return {
                // user: props.user
                audio: props.audio,
                feed_audio: props.feed_audio,
                account_audio: props.account_audio
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.user !== this.props.user || props.audio !== this.props.audio || props.feed_audio !== this.props.feed_audio || props.account_audio !== this.props.account_audio) {
            this.setState({
                // username: this.props.user
                audio: this.props.audio,
                feed_audio: this.props.feed_audio,
                account_audio: this.props.account_audio
            });
            if (this.props.audio !== null && this.props.feed_audio !== null && this.props.accuont_audio !== null) this.componentDidMount();
        }
    }

    async follow() {
        let param = {
            user: this.props.currentUser,
            follows: this.state.user.username
        };

        try {
            await API.graphql({ query: createFollow, variables: { input: param } });
            this.componentDidMount();
            this.props.updateFollowing();
        } catch (err) {
            window.location.href = "/error";
        }
    }

    async unfollow() {
        let param = {
            id: this.state.followID
        };

        try {
            await API.graphql({ query: deleteFollow, variables: { input: param } });
            this.componentDidMount();
            this.props.updateFollowing();
        } catch (err) {
            window.location.href = "/error";
        }
    }

    closeMODAL(id) {
        let modal = document.getElementById(id);
        if (modal) {
            modal.style.display = "none";
        }
    }

    async build() {
        if (this.state.audio !== null) {
            let user = this.state.user;
            console.log(user);
            let follow = user.followers.find(e => e.user === this.props.currentUser);
            let following = false;
            if (follow !== undefined) {
                following = true;
            }

            // let positions = await this.generate(user.positions, "positions");
            let posts = await this.generate(user.posts, "posts");
            let skills = await this.createSkills(user.skills);

            let spoken = localStorage.getItem("preferred_spoken_language") || "English";
            let follow_audio = this.state.audio["follow"][spoken];
            let unfollow_audio = this.state.audio["unfollow"][spoken];
            let skills_audio = this.state.audio["skills"][spoken];
            let posts_audio = this.state.audio["posts"][spoken];
            let no_skills_audio = this.state.audio["no_skills"][spoken];

            console.log(user.username, this.props.currentUser)

            let build = <div className="account user_interface">
                <div className="user_pic">
                    <Icon.PersonCircle />
                </div>

                <div className="section" id="username">
                    <div className="heading">
                        {user.username[0].toUpperCase() + user.username.slice(1)}
                    </div>
                </div>

                <div className="follower_wrap">
                    <div style={{ marginBottom: "20px" }}>
                        <MODAL_BUTTON id={user + "_followers"}>
                            <div className="follower_set left"><div style={{ display: "inline-block", verticalAlign: "middle", fontSize: "27px", marginRight: "10px" }}><Icon.People /></div>{dict["Followers"][this.props.language]}</div>
                        </MODAL_BUTTON>
                        <MODAL_BUTTON id={user + "_following"}>
                            <div className="follower_set right">{dict["Following"][this.props.language]}<div style={{ display: "inline-block", verticalAlign: "middle", fontSize: "27px", marginLeft: "10px" }}><Icon.PersonCheck /></div></div>
                        </MODAL_BUTTON>
                        <MODAL_BUTTON id={user + "_followers"}>
                            <div className="follower_set left">{user.followers.length}</div>
                        </MODAL_BUTTON>
                        <MODAL_BUTTON id={user + "_following"}>
                            <div className="follower_set right">{user.follows.length}</div>
                        </MODAL_BUTTON>
                    </div>

                    <MODAL id={user + "_followers"}>
                        <Followers build={() => {
                            this.setState({
                                build: null,
                                loading: true
                            }, () => {
                                this.componentDidMount();
                            })
                        }} audio={this.state.account_audio} language={this.props.language} id={user + "_followers"} link={this.props.visitUser} followers={user.followers} />
                    </MODAL>

                    <MODAL id={user + "_following"}>
                        <Following build={() => {
                            this.setState({
                                build: null,
                                loading: true
                            }, () => {
                                this.componentDidMount();
                            })
                        }} audio={this.state.account_audio} language={this.props.language} id={user + "_following"} link={this.props.visitUser} following={user.follows} />
                    </MODAL>
                </div>

                <div className="section" id="bio">
                    <div className="content">
                        {user.biography}
                    </div>
                </div>

                {user.username !== this.props.currentUser ?
                    <div style={{ marginBottom: "20px" }}>
                        <button style={{ width: JSON.parse(localStorage.getItem("hide_audio_icons")) ? "100%" : "calc(100% - 70px)" }} className="secondary full audio_text" onClick={following ? () => this.unfollow() : () => this.follow()}>
                            {following ? <div><div className="button_icon"><Icon.PersonX /></div>{dict["Unfollow"][this.props.language]}</div> : <div><div className="button_icon"><Icon.PersonPlus /></div>{dict["Follow"][this.props.language]}</div>}
                        </button>
                        <div style={{ color: "#e9e9e9" }} className="audio" onClick={() => play_audio(following ? unfollow_audio : follow_audio)}><Icon.VolumeUp /></div>
                    </div>
                    : null}

                <div className="section" id="skills">
                    <div className="content audio_text">{dict["Skills"][this.props.language]}</div>
                    <div style={{ color: "#5a5a5a", borderColor: "#5a5a5a" }} className="audio" onClick={() => play_audio(skills_audio)}><Icon.VolumeUp /></div>

                    <div className="skill_wrap">
                        {skills.length > 0 ? skills : <div className="no_skills"><i>{dict["This user doesn't have any skills yet"][this.props.language]}</i><div style={{ color: "#5a5a5a", borderColor: "#5a5a5a", transform: "scale(0.6)" }} className="audio" onClick={() => play_audio(no_skills_audio)}><Icon.VolumeUp /></div></div>}
                    </div>
                </div>

                <div className="tiles" style={{ display: user.posts.length > 0 ? "block" : "none", margin: "0px" }}>
                    <div className="tiles_heading audio_text">{dict["Videos"][this.props.language]}</div>
                    <div style={{ color: "#5a5a5a", borderColor: "#5a5a5a" }} className="audio" onClick={() => play_audio(posts_audio)}><Icon.VolumeUp /></div>
                    {posts}
                </div>

                {/* <div className="tiles" style={{ display: user.positions.length > 0 ? "block" : "none", margin: "0px" }}>
                <div className="tiles_heading">Positions</div>
                {positions}
            </div> */}
            </div>

            this.setState({
                build: build,
                loading: false,
                followID: follow?.id
            });
        }
    }

    async createSkills(e) {
        let e_arr = [];

        for (let i = 0, size = e.length; i < size; i++) {
            let en = <Skills build={() => {
                this.setState({
                    build: null,
                    loading: true
                }, () => {
                    this.componentDidMount();
                })
            }} audio={this.state.account_audio} visitUser={this.props.visitUser} refresh={() => this.componentDidMount()} data={e[i]} language={this.props.language} user={this.props.user} currentUser={this.props.currentUser} />;
            e_arr.push(en);
        }

        return e_arr;
    }

    async generate(data, type) {
        let pos_arr = [];

        for (let i = 0, size = data.length; i < size; i++) {
            let n = data[i];
            let language = this.props.language;
            if (language === "Bahasa Malaysia") {
                language = "Malay";
            }
            language = language.toLowerCase();
            let d = "";

            if (n[language] !== null) {
                d = n[language];
            } else if (n.english !== null) {
                d = n.english;
            } else if (n.rohingyalish !== null) {
                d = n.rohingyalish;
            } else if (n.malay !== null) {
                d = n.malay;
            }

            let src = null;
            if (n.src !== null) {
                try {
                    src = await Storage.get(n.src);
                } catch (err) {
                    window.location.href = "/error";
                }
            }

            let pos = null;
            if (type === "positions") {
                pos = <Position src={src} id={n.id} date={n.createdAt} user={n.user} title={n.title} location={n.location} description={d} />;
            } else {
                pos = <Post audio={this.state.feed_audio} visitUser={this.props.visitUser} language={this.props.language} saves={n.saves} currentUser={this.props.currentUser} comments={n.comments} refreshPosts={() => this.componentDidMount()} post={n} likes={n.likes.items} id={n.id} date={n.createdAt} user={n.user} src={src} file={n.src} description={d} />
            }

            pos_arr.push(pos);
        }

        return pos_arr;
    }

    render() {
        if (this.state.audio !== null) {
            return (
                <div>
                    <LOADING show={this.state.loading} />
                    {this.state.build}
                </div>
            );
        } else {
            return <LOADING />;
        }
    }
}

export default USER;