import React from "react";
import { Analytics, Auth } from "aws-amplify";
import LOADING from "../loading/loading";
import * as Icon from "react-bootstrap-icons";
import dict from "../misc/translations";
import history from "../../history";

class VERIFY extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            loading: false
        }
    }

    updateState(e, state) {
        this.setState({
            [state]: e.currentTarget.value
        });
    }

    verify = () => {
        Auth.confirmSignUp(this.props.username, this.state.code, {
            // Optional. Force user confirmation irrespective of existing alias. By default set to True.
            forceAliasCreation: true
        })
            .then(data => console.log(data))
            .then(() => {
                Analytics.record({ name: "verify", attributes: { result: "success" } });
                this.autoLogin();
            })
            .catch(err => {
                Analytics.record({ name: "verify", attributes: { result: "failed" } });
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    autoLogin() {
        Auth.signIn(this.props.username, this.props.password)
            .then(user => console.log(user))
            .then(() => {
                // this.props.storePassword("");
                window.location.href = "/videos";
            })
            .catch(err => {
                let error = err.message;

                Analytics.record({ name: "login", attributes: { result: "failed" } });
                history.push("/videos");

                this.setState({
                    loading: false,
                    error: error
                });
            });
    }

    async resendConfirmationCode() {
        try {
            await Auth.resendSignUp(this.props.username);
            console.log('code resent successfully');
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    render() {
        if (this.state.loading) {
            return <LOADING />;
        } else {
            return (
                <div className="login-form">
                    {/* <div style={{display: this.props.number ? "block" : "none"}} className="small_text">{dict["Code sent to"][this.props.language]} {this.props.number}<br /><div className="small_text link" onClick={this.props.register}>{dict["Wrong number?"][this.props.language]}</div></div><br style={{display: this.props.number ? "block" : "none"}} /> */}

                    <input type="text" value={this.state.code} placeholder={dict["Code"][this.props.language]} onChange={(e) => this.updateState(e, "code")}></input><br />
                    <button onClick={this.verify} className="primary"><div className="button_icon"><Icon.Check2Square /></div>{dict["Verify"][this.props.language]}</button><br />

                    <div className="small_text">{dict["Haven't received a code?"][this.props.language]}&nbsp;</div>
                    <div className="small_text link" onClick={() => this.resendConfirmationCode()}>{dict["Resend"][this.props.language]}</div>
                </div>
            );
        }
    }
}

// export default withAuthenticator(Login);
export default VERIFY;