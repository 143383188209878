import Storage from "@aws-amplify/storage";

export default async function main_audio() {
    return ({
        "news_feed": {
            "English": new Audio(await Storage.get("audio/news_feed/English/videos.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "account": {
            "English": new Audio(await Storage.get("audio/account/English/account.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "saved": {
            "English": new Audio(await Storage.get("audio/saved/English/saved.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "notifications": {
            "English": new Audio(await Storage.get("audio/notifications/English/notifications.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "help": {
            "English": new Audio(await Storage.get("audio/help/English/help.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "settings": {
            "English": new Audio(await Storage.get("audio/settings/English/settings.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "upload_post": {
            "English": new Audio(await Storage.get("audio/upload/English/uploadVideo.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "search": {
            "English": new Audio(await Storage.get("audio/search/English/search.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },

        "navigation": {
            "English": new Audio(await Storage.get("audio/help/English/navigation.m4a")),
            "Rohingyalish": new Audio(),
            "Malay": new Audio(),
            "Burmese": new Audio()
        },
    })
}