import { API, graphqlOperation } from "aws-amplify";
import { listEndorsements } from "../../graphql/queries";

export default async function getUserEndorsements(username) {
    const postData = await API.graphql(graphqlOperation(listEndorsements, {
        filter: {
            about: {
                eq: username
            }
        }
    }));

    return postData.data.listEndorsements.items.sort(function(a, b) {
        return (a.createdAt > b.createdAt) ? -1 : ((a.createdAt < b.createdAt) ? 1 : 0);
    });;
}