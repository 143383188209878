import React from "react";

import LOGIN from "./login";
import REGISTER from "./register";
import VERIFY from "./verify";
import ERROR from "../error/error";

import logo from "../../img/logo-new.svg";
import Current_User from "./current_user";
import LOADING from "../loading/loading";
import REQUEST_RESET from "./request_reset";
import CHANGE_PASSWORD from "./change_password";
import history from "../../history";
import print from "../../img/print.svg";
import uk from "../../img/uk-icon.png";
import malaysia from "../../img/malaysia-icon.png";
import rohingya from "../../img/rohingyalish-icon.png";
import burma from "../../img/burma-icon.png";
import hanifi from "../../img/hanifi-icon.png";

class AUTH extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: this.props.display,
            username: "",
            user: null,
            loading: true,
            language: "English",
            number: "",
            password: ""
        };
    }

    componentDidMount() {
        // history.listen(({ action }) => {
        //     if (action === "POP") {
        //         this.refreshPage();
        //     }
        // });

        Current_User((e) => this.SSO(e), this.state.display);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.display !== state.display) {
            return {
                display: props.display
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.display !== this.props.display) {
            this.setState({
                likes: this.props.display
            });
            this.componentDidMount();
        }
    }

    refreshPage() {
        let path = window.location.pathname.replace(/\//g, '');
        if (path) {
            path = path[0].toUpperCase() + path.slice(1);

            this.setState({
                display: path
            });
        } else {
            this.setState({
                display: this.props.display
            });
        }
    }

    SSO(user) {
        if (user?.username) {
            window.location.href = "/videos";
        } else {
            this.setState({
                loading: false
            });
        }
    }

    updateState(page) {
        history.push("/" + page.toLowerCase());

        // this.setState({
        //     display: page
        // });
    }

    updateUsername(e) {
        this.setState({
            username: e
        });
    }

    updateNumber(e) {
        this.setState({
            number: e
        });
    }

    updatePassword(e) {
        this.setState({
            password: e
        });
    }

    changeFont() {
        let root = document.documentElement;
		root.style.setProperty('--sans', this.state.language === "Hanifi" ? 'hanifi' : "'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif");
		root.style.setProperty('--name', this.state.language === "Hanifi" ? 'hanifi' : "'Quicksand', sans-serif");
    }

    render() {
        let display = <ERROR />;
        switch (this.state.display) {
            case "Login":
                display = <LOGIN verify={() => this.updateState("Verify")} updateNumber={(e) => this.updateNumber(e)} storePassword={(e) => this.updatePassword(e)} language={this.state.language} success={() => this.updateState("Success")} register={() => this.updateState("Register")} request_reset={() => this.updateState("Forgot_password")} username={(e) => this.updateUsername(e)} />;
                break;

            case "Register":
                display = <REGISTER storePassword={(e) => this.updatePassword(e)} updateNumber={(e) => this.updateNumber(e)} language={this.state.language} login={() => this.updateState("Login")} verify={() => this.updateState("Verify")} username={(e) => this.updateUsername(e)} />;
                break;

            case "Forgot_password":
                display = <REQUEST_RESET language={this.state.language} login={() => this.updateState("Login")} change_password={() => this.updateState("Password_reset")} username={(e) => this.updateUsername(e)} />;
                break;

            case "Password_reset":
                display = <CHANGE_PASSWORD language={this.state.language} login={() => this.updateState("Login")} username={this.state.username} />;
                break;

            case "Verify":
                display = <VERIFY password={this.state.password} number={this.state.number} language={this.state.language} register={() => this.updateState("Register")} success={() => this.updateState("Login")} username={this.state.username} />;
                break;

            case "Success":
                display = null;
                this.setState({
                    loading: true
                });
                window.location.href = "/videos";
                break;

            default:
                display = <LOGIN language={this.state.language} success={() => this.updateState("Success")} register={() => this.updateState("Register")} request_reset={() => this.updateState("Forgot_password")} />;
                break;
        }

        if (display !== null) {
            localStorage.removeItem("next_page");
            return (
                <div className="auth-body">
                    <img className="print" src={print} alt="Print"></img>
                    <div className="auth">
                        <LOADING show={this.state.loading} />
                        <img className="logo" src={logo} alt="Logo"></img>
                        <div className="auth_languages">
                            <img alt="English" onClick={() => this.setState({language: "English"}, () => this.changeFont())} style={{border: this.state.language === "English" ? "2px solid #cba42d" : ""}} className="language_icon" src={uk} />
                            <img alt="Bahasa Malaysia" onClick={() => this.setState({language: "Bahasa Malaysia"}, () => this.changeFont())} style={{border: this.state.language === "Bahasa Malaysia" ? "2px solid #cba42d" : ""}} className="language_icon" src={malaysia} />
                            <img alt="Rohingyalish" onClick={() => this.setState({language: "Rohingyalish"}, () => this.changeFont())} style={{border: this.state.language === "Rohingyalish" ? "2px solid #cba42d" : ""}} className="language_icon" src={rohingya} />
                            <img alt="Burmese" onClick={() => this.setState({ language: "Burmese" }, () => this.changeFont())} style={{ border: this.state.language === "Burmese" ? "2px solid #cba42d" : "" }} className="language_icon" src={burma} />
                            <img alt="Hanifi" onClick={() => this.setState({language: "Hanifi"}, () => this.changeFont())} style={{border: this.state.language === "Hanifi" ? "2px solid #cba42d" : ""}} className="language_icon" src={hanifi} />
                        </div>
                        {display}
                    </div>
                </div>
            );
        } else {
            return <LOADING show={this.state.loading} />;
        }
    }
}

export default AUTH;